import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Skeleton from '@material-ui/lab/Skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { get, orderBy, groupBy, isEmpty } from 'lodash'
import {
  TableBody,
  TableRow,
  Checkbox,
  TableCell,
  IconButton,
} from '@material-ui/core'
import { CustomTableContext } from './index'
import defaultThumbnail from '../../images/defaultThumbnail.jpg'
import ImageNA from '../../images/ImageNA.svg'
import {
  openZoomedImage,
  closeZoomedImage,
} from '../../store/TcinInfo/actionCreator'
import ImageDialog from '../ImageDialog'
import { MultiTcinReusedFlyout } from '../MultiTcinReusedFlyout'
import {
  appendAHKeyParamToUrl,
  appendQueryParams,
} from '../../helpers/UrlHelper'
import { dispPackagingThumbnails } from '../../containers/Dashboard/constants'

const useStyles = makeStyles((theme) => ({
  pinned: {
    left: 74,
    zIndex: 4,
    position: 'sticky',
    backgroundColor: '#ffffff',
  },
  checkboxPinned: {
    left: 0,
    zIndex: 4,
    paddingTop: 8,
    position: 'sticky',
    backgroundColor: 'inherit',
    verticalAlign: 'top',
    textAlign: 'left',
  },
  moreInfoPinned: {
    right: 0,
    zIndex: 4,
    position: 'sticky',
    backgroundColor: '#ffffff',
    verticalAlign: 'top',
    textAlign: 'left',
  },
}))

export default function CustomTableBody(props) {
  const { tState, tMethods } = React.useContext(CustomTableContext)

  const {
    isLoading = true,
    hasMoreInfo = true,
    userColumns = {},
    uniqueIdName = '',
    tableName = '',
    tabName = '',
    onImageArchive = () => {},
    uploadsInProgress = {},
  } = props

  const numberOfSelectedColumns = Object.values(userColumns)?.filter(
    (c) => c?.selected
  ).length

  const classes = useStyles()

  const dispatch = useDispatch()

  const enlargeImage = useSelector(
    (state) => state.tcinPhotoInfoReducer.enlargeImage
  )

  const onTableRowClick = (isChecked, row) => {
    if (tableName === 'workflow') {
      let newSelected = tState.workflowSelected[tabName] || []
      if (isChecked) {
        newSelected.push(row)
      } else {
        const selectedRow = newSelected.findIndex(
          (e) => e[uniqueIdName] === row[uniqueIdName]
        )
        newSelected.splice(selectedRow, 1)
      }
      tMethods.workFlowCheckboxHandler(newSelected, tabName)
    } else {
      let newSelected = tState.selected
      if (isChecked) {
        newSelected.push(row)
      } else {
        const selectedRow = tState.selected.findIndex(
          (e) => e[uniqueIdName] === row[uniqueIdName]
        )
        newSelected.splice(selectedRow, 1)
      }
      tMethods.checkboxHandler(newSelected)
    }
  }

  const nestedData = (row) => {
    let nestedRows = []
    let altColMap = groupBy(
      (tState.colMap || []).filter(
        (o) => typeof o.is_nested_field !== 'undefined'
      ),
      'is_nested_field'
    )
    for (let i = 0; i < (row?.image_information?.alt?.length || 0); i++) {
      nestedRows.push(
        <TableRow key={'alt_' + i}>
          {altColMap[i]?.map((col, index) => {
            const isPickupImage =
              tableName === 'projects' &&
              col.metaDataInfo &&
              get(row, col.metaDataInfo[4], '') === 'Pickup'
            return (
              (typeof col.showNestedRow === 'function'
                ? col.showNestedRow(tState.customizeCol)
                : tState.customizeCol[col.id].selected) && (
                <TableCell
                  style={{ verticalAlign: 'top', textAlign: 'left' }}
                  key={i + index}
                >
                  {typeof col.renderCell === 'function' ? (
                    col.isImage ? (
                      <div
                        style={{
                          position: 'relative',
                          height: '72px',
                        }}
                      >
                        {col
                          .renderCell(row, tState.data.length)
                          .map((url, i) => (
                            <img
                              onClick={() => {
                                dispatch(
                                  openZoomedImage(
                                    `${row.tcin} - ${col.headerName}`,
                                    url
                                  )
                                )
                              }}
                              key={i}
                              style={{ height: 72, cursor: 'pointer' }}
                              src={url}
                              alt="-"
                              onError={(e) => {
                                e.target.src = defaultThumbnail
                              }}
                            />
                          ))}
                        {get(row, 'isMultiTcin') ||
                        get(row, 'isPickupImage') ||
                        (col.metaDataInfo &&
                          (get(row, col.metaDataInfo[0]) || isPickupImage)) ? (
                          <MultiTcinReusedFlyout
                            isPickupImage={
                              get(row, 'isPickupImage') || isPickupImage
                            }
                            tcin={
                              tableName === 'workflow'
                                ? get(row, 'fileName')
                                : get(row, col.metaDataInfo[3])
                            }
                            subjectCodes={
                              tableName === 'workflow'
                                ? get(row, 'subjectCodes')
                                : get(row, col.metaDataInfo[2])
                            }
                            originalImage={
                              tableName === 'workflow'
                                ? get(row, 'originalImage')
                                : get(row, col.metaDataInfo[1])
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : isEmpty(col.renderCell(row, tState.data.length)) ? (
                      '-'
                    ) : (
                      col.renderCell(row, tState.data.length)
                    )
                  ) : col.isImage ? (
                    get(row, col.field[0]) ? (
                      <div
                        style={{
                          position: 'relative',
                          height: '72px',
                        }}
                      >
                        <img
                          onError={(e) => {
                            e.target.src = defaultThumbnail
                          }}
                          onClick={() => {
                            dispatch(
                              openZoomedImage(
                                `${row.tcin} - ${col.headerName}`,
                                get(row, col.field[1])
                                  ? get(row, col.field[1])
                                  : get(row, col.field[0])
                              )
                            )
                          }}
                          style={{
                            height: 60,
                            cursor: 'pointer',
                            margin: '0 5px',
                          }}
                          src={get(row, col.field[0])}
                          alt="-"
                        />
                        {get(row, 'isMultiTcin') ||
                        get(row, 'isPickupImage') ||
                        (col.metaDataInfo &&
                          (get(row, col.metaDataInfo[0]) || isPickupImage)) ? (
                          <MultiTcinReusedFlyout
                            isPickupImage={
                              get(row, 'isPickupImage') || isPickupImage
                            }
                            tcin={
                              tableName === 'workflow'
                                ? get(row, 'fileName')
                                : get(row, col.metaDataInfo[3])
                            }
                            subjectCodes={
                              tableName === 'workflow'
                                ? get(row, 'subjectCodes')
                                : get(row, col.metaDataInfo[2])
                            }
                            originalImage={
                              tableName === 'workflow'
                                ? get(row, 'originalImage')
                                : get(row, col.metaDataInfo[1])
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <ImageNA
                        title="Image Not Available"
                        style={{ width: '72px' }}
                        onClick={() => {
                          dispatch(
                            openZoomedImage(
                              `${row.tcin} - ${col.headerName}`,
                              get(row, col.field[1])
                                ? get(row, col.field[1])
                                : get(row, col.field[0])
                            )
                          )
                        }}
                      />
                    )
                  ) : isEmpty(get(row, col.field)) ? (
                    '-'
                  ) : (
                    get(row, col.field)
                  )}
                </TableCell>
              )
            )
          })}
        </TableRow>
      )
    }
    return nestedRows
  }

  const getTableRows = () => {
    if (tableName === 'workflow') {
      return tState.data || []
    } else {
      return orderBy(tState.data || [], tState.orderBy, tState.order)
    }
  }
  return (
    <React.Fragment>
      {enlargeImage.open && (
        <ImageDialog
          open={enlargeImage.open}
          src={enlargeImage.src}
          handleClose={() => dispatch(closeZoomedImage())}
          title={enlargeImage.title}
          tableName={tableName}
          imageStatus={enlargeImage.imageStatus}
          orginalVersion={enlargeImage.originalVersion}
          uploadedVersion={enlargeImage.uploadedVersion}
          version={enlargeImage.imageVersion}
          onImageArchive={(isArchived) =>
            onImageArchive(
              {
                file_name: enlargeImage.fileName,
                version: enlargeImage.originalVersion,
              },
              isArchived
            )
          }
          isLoading={isLoading}
        />
      )}
      <TableBody>
        {!isLoading ? (
          tState.data.length > 0 ? (
            getTableRows().map((row, j) => {
              const isChecked =
                tableName === 'workflow'
                  ? (tState.workflowSelected[tabName] || []).findIndex(
                      (e) => e[uniqueIdName] === row[uniqueIdName]
                    ) >= 0
                  : tState.selected.findIndex(
                      (e) => e[uniqueIdName] === row[uniqueIdName]
                    ) >= 0
              return (
                <React.Fragment key={`${j}_${row[uniqueIdName]}`}>
                  <TableRow
                    key={`${j}_${row[uniqueIdName]}`}
                    style={{
                      backgroundColor:
                        get(row, 'isMultiTcin') &&
                        get(row, 'isMultiTcin') !== get(row, 'isOriginal')
                          ? '#F6F6F6'
                          : '#ffffff',
                    }}
                  >
                    {props.checkBox && (
                      <TableCell
                        rowSpan={
                          tState.customizeCol.nested_layout?.selected
                            ? row?.image_information?.alt?.length + 1
                            : undefined
                        }
                        className={classes.checkboxPinned}
                      >
                        <Checkbox
                          onClick={(event) => onTableRowClick(!isChecked, row)}
                          checked={isChecked}
                          inputProps={{ 'aria-labelledby': j }}
                          disabled={
                            get(row, 'imageStatus') === 'Archived' ||
                            uploadsInProgress[get(row, 'fileName')]
                          }
                        />
                      </TableCell>
                    )}
                    {(tState.colMap || []).map((col, i) => {
                      const isPickupImage =
                        tableName === 'projects' &&
                        col.metaDataInfo &&
                        get(row, col.metaDataInfo[4], '') === 'Pickup'
                      return (
                        (typeof col.showColumn === 'function'
                          ? col.showColumn(tState.customizeCol)
                          : tState.customizeCol[col.id]?.selected) && (
                          <TableCell
                            style={{
                              left: 0,
                              paddingTop: 8,
                              verticalAlign: 'top',
                              textAlign: 'left',
                            }}
                            key={`${i}-${j}`}
                            className={col.pinned && classes.pinned}
                            rowSpan={
                              tState.customizeCol.nested_layout?.selected &&
                              col.id !== col.group_id
                                ? row?.image_information?.alt?.length + 1
                                : undefined
                            }
                          >
                            {typeof col.renderCell === 'function' ? (
                              col.isThumbnail ? (
                                dispPackagingThumbnails(
                                  col.renderCell(row, tState.data.length) || [],
                                  props,
                                  j,
                                  'packaging assets'
                                )
                              ) : col.isImage ? (
                                <div style={{ display: 'inline-flex' }}>
                                  {col
                                    .renderCell(row, tState.data.length)
                                    .map((url, i) => {
                                      const previewUrl =
                                        typeof url === 'object'
                                          ? appendAHKeyParamToUrl(
                                              url?.preview_asset_url || ''
                                            )
                                          : appendQueryParams(url, {
                                              scl: Number(1),
                                            })
                                      const thumbnailUrl =
                                        typeof url === 'object'
                                          ? appendAHKeyParamToUrl(
                                              url?.thumbnail_asset_url || ''
                                            )
                                          : url
                                      return (
                                        <div
                                          style={{
                                            position: 'relative',
                                            height: '72px',
                                          }}
                                        >
                                          <img
                                            onClick={() => {
                                              dispatch(
                                                openZoomedImage(
                                                  `${
                                                    get(row, 'fileName') ||
                                                    get(row, 'tcin') ||
                                                    'Unknown'
                                                  } | ${
                                                    get(row, 'version') ||
                                                    col.headerName
                                                  }`,
                                                  previewUrl,
                                                  tableName,
                                                  get(row, 'fileName', ''),
                                                  tableName === 'workflow' &&
                                                    get(row, col.field[2], ''),
                                                  get(row, 'originalVersion'),
                                                  get(row, 'imageStatus'),
                                                  get(row, 'version'),
                                                  get(row, 'uploaded_version')
                                                )
                                              )
                                            }}
                                            key={i}
                                            style={{
                                              height: 72,
                                              cursor: 'pointer',
                                              marginRight: '5px',
                                            }}
                                            src={thumbnailUrl}
                                            alt="-"
                                            onError={(e) => {
                                              e.target.src = defaultThumbnail
                                            }}
                                          />
                                          {get(row, 'isMultiTcin') ||
                                          get(row, 'isPickupImage') ||
                                          (col.metaDataInfo &&
                                            (get(row, col.metaDataInfo[0]) ||
                                              isPickupImage)) ? (
                                            <MultiTcinReusedFlyout
                                              isPickupImage={
                                                get(row, 'isPickupImage') ||
                                                isPickupImage
                                              }
                                              tcin={
                                                tableName === 'workflow'
                                                  ? get(row, 'fileName')
                                                  : get(
                                                      row,
                                                      col.metaDataInfo[3]
                                                    )
                                              }
                                              subjectCodes={
                                                tableName === 'workflow'
                                                  ? get(row, 'subjectCodes')
                                                  : get(
                                                      row,
                                                      col.metaDataInfo[2]
                                                    )
                                              }
                                              originalImage={
                                                tableName === 'workflow'
                                                  ? get(row, 'originalImage')
                                                  : get(
                                                      row,
                                                      col.metaDataInfo[1]
                                                    )
                                              }
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      )
                                    })}
                                </div>
                              ) : isEmpty(
                                  col.renderCell(row, tState.data.length)
                                ) ? (
                                '-'
                              ) : (
                                col.renderCell(row, tState.data.length)
                              )
                            ) : col.isImage ? (
                              get(row, col.field[0]) ? (
                                <div
                                  style={{
                                    position: 'relative',
                                    height: '72px',
                                  }}
                                >
                                  <img
                                    onError={(e) => {
                                      e.target.src = defaultThumbnail
                                    }}
                                    onClick={() => {
                                      dispatch(
                                        openZoomedImage(
                                          `${
                                            get(row, 'fileName') ||
                                            get(row, 'tcin') ||
                                            'Unknown'
                                          } | ${
                                            get(row, 'version') ||
                                            col.headerName
                                          }`,
                                          get(row, col.field[1])
                                            ? get(row, col.field[1])
                                            : get(row, col.field[0]),
                                          tableName,
                                          get(row, 'fileName', ''),
                                          tableName === 'workflow' &&
                                            get(row, col.field[2], ''),
                                          get(row, 'originalVersion'),
                                          get(row, 'imageStatus'),
                                          get(row, 'version'),
                                          get(row, 'uploaded_version')
                                        )
                                      )
                                    }}
                                    style={{
                                      height: 72,
                                      cursor: 'pointer',
                                      marginRight: '5px',
                                    }}
                                    src={get(row, col.field[0])}
                                    alt="-"
                                  />
                                  {get(row, 'isMultiTcin') ||
                                  get(row, 'isPickupImage') ||
                                  (col.metaDataInfo &&
                                    (get(row, col.metaDataInfo[0]) ||
                                      isPickupImage)) ? (
                                    <MultiTcinReusedFlyout
                                      isPickupImage={
                                        get(row, 'isPickupImage') ||
                                        isPickupImage
                                      }
                                      tcin={
                                        tableName === 'workflow'
                                          ? get(row, 'fileName')
                                          : get(row, col.metaDataInfo[3])
                                      }
                                      subjectCodes={
                                        tableName === 'workflow'
                                          ? get(row, 'subjectCodes')
                                          : get(row, col.metaDataInfo[2])
                                      }
                                      originalImage={
                                        tableName === 'workflow'
                                          ? get(row, 'originalImage')
                                          : get(row, col.metaDataInfo[1])
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : (
                                <ImageNA
                                  title="Image Not Available"
                                  style={{ width: '72px' }}
                                  onClick={() => {
                                    dispatch(
                                      openZoomedImage(
                                        `${
                                          get(row, 'fileName') ||
                                          get(row, 'tcin') ||
                                          'Unknown'
                                        } | ${
                                          get(row, 'version') || col.headerName
                                        }`,
                                        get(row, col.field[1])
                                          ? get(row, col.field[1])
                                          : get(row, col.field[0]),
                                        tableName,
                                        get(row, 'fileName', ''),
                                        tableName === 'workflow' &&
                                          get(row, col.field[2], ''),
                                        get(row, 'originalVersion'),
                                        get(row, 'imageStatus'),
                                        get(row, 'version'),
                                        get(row, 'uploaded_version')
                                      )
                                    )
                                  }}
                                />
                              )
                            ) : Array.isArray(get(row, col.field)) ? (
                              ''
                            ) : !get(row, col.field) ? (
                              '-'
                            ) : (
                              get(row, col.field)
                            )}
                          </TableCell>
                        )
                      )
                    })}
                    {hasMoreInfo && (
                      <TableCell
                        rowSpan={
                          tState.customizeCol.nested_layout?.selected
                            ? row?.image_information?.alt?.length + 1
                            : undefined
                        }
                        className={classes.moreInfoPinned}
                      >
                        <IconButton
                          aria-label="more-info"
                          onClick={(e) => props.moreInfoHandler(e, j, 'photo')}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  {tState.customizeCol.nested_layout?.selected &&
                    nestedData(row)}
                </React.Fragment>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={24} align="center">
                No Data found
              </TableCell>
            </TableRow>
          )
        ) : (
          [...Array(numberOfSelectedColumns)].map((k, i) => (
            <TableRow key={i}>
              <TableCell key={i} style={{ left: 0, paddingTop: 0 }}>
                <Skeleton />
              </TableCell>
              {[...Array(numberOfSelectedColumns)].map((i) => {
                return (
                  <TableCell key={i}>
                    <Skeleton />
                  </TableCell>
                )
              })}
            </TableRow>
          ))
        )}
      </TableBody>
    </React.Fragment>
  )
}
