import queryString from 'query-string'
import { camelCase } from 'lodash'
import apiConfig from '../apiConfig'

export const getParameterFromUrl = function (name, url, noDecode) {
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = url ? regex.exec(url) : regex.exec(window.location.search)

  let returnValue
  if (results == null) {
    returnValue = ''
  } else if (!noDecode) {
    returnValue = decodeURIComponent(results[1].replace(/\+/g, ' '))
  } else {
    returnValue = results[1]
  }

  return returnValue
}

export const getQueryParams = (location = {}) => {
  const { pathname = '' } = location
  const queryParamsIndex = pathname.indexOf('?')
  const params =
    queryParamsIndex !== -1
      ? queryString.parse(pathname.substr(queryParamsIndex))
      : {}
  return params
}

export const appendQueryParams = (url = '', params = {}) => {
  if (url === null) {
    return ''
  }
  const delimiter = url.indexOf('?') === -1 ? '?' : '&'
  return `${url}${delimiter}${queryString.stringify(params)}`
}

export const appendAHKeyParamToUrl = (url = '') => {
  const delimiter = url?.indexOf('?') === -1 ? '?' : '&'
  const apiKey = apiConfig?.ahApiKey || ''
  return `${url}${delimiter}key=${apiKey}`
}

export const isNonPhotobridgeAPI = (requestUrl) => {
  const nonPhotobridgeAPIRegex = new RegExp(
    '\\/locations\\/v3\\/public(:?\\/|\\?)'
  )
  return nonPhotobridgeAPIRegex.test(requestUrl)
}

export const formatPaginationQueryParams = (pagination) => {
  let params = ''
  params += `page=${pagination.page}&size=${pagination.size}`
  if (pagination.sort) {
    // spring paging reqires camelcase field name for sorting
    params += `&sort=${camelCase(pagination.sort.field)},${
      pagination.sort.direction
    }`
  }
  return params
}

export default function constructReportApiURL(params = {}) {
  let url = ''
  if (Object.entries(params).length > 0) {
    const queryString = Object.keys(params)
      // eslint-disable-next-line no-undef
      .filter((key) => params[key] > 0 || !_.isEmpty(params[key]))
      .map((key) => key + '=' + params[key])
      .join('&')
    if (queryString.length > 0) url = '?' + queryString
  }
  return url
}

export const encodeUriParams = (params = {}) => {
  return encodeURIComponent(
    Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
  )
}
