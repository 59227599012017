import axios from 'axios'
import apiConfig from '../apiConfig'

const {
  projectsApi = '',
  packagingApi = '',
  apiKey = '',
  getUserCustomColumns = '',
} = apiConfig

export function fetchProjectImages({
  projectId = '',
  page = 0,
  numberOfRows,
  accessToken = '',
  lanId = '',
}) {
  const projectUrl = `${projectsApi}/${projectId}`
  return axios.get(projectUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
      // 'x-tgt-lanid': lanId,
    },
    params: {
      key: apiKey,
      page: page,
      size: numberOfRows,
    },
  })
}

export function fetchProjectPackagingImages({
  projectId = '',
  page = 0,
  numberOfRows,
  accessToken = '',
  lanId = '',
}) {
  const projectUrl = `${packagingApi}/fetch_assets?projectId=${projectId}`
  return axios.get(projectUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
      // 'x-tgt-lanid': lanId,
    },
    params: {
      key: apiKey,
      page: page,
      size: numberOfRows,
    },
  })
}

export function repurposeAHImages({
  accessToken = '',
  params = {},
  lanId = '',
}) {
  const repurposeAssetsUrl = `${packagingApi}/repurpose_assets`
  return axios.post(
    repurposeAssetsUrl,
    {
      ...params,
    },
    {
      headers: {
        Authorization: accessToken,
        'x-tgt-lanid': lanId,
      },
      params: {
        key: apiKey,
      },
    }
  )
}

export function fetchCustomColumnsList({ userId = '', accessToken = '' }) {
  const customColumnsUrl = `${getUserCustomColumns}?user_id=${userId?.toUpperCase()}&view=project`
  return axios.get(customColumnsUrl, {
    headers: {
      Authorization: accessToken,
      // 'x-tgt-lanid': userId,
    },
    params: {
      key: apiKey,
    },
  })
}

export function updateCustomColumnsList({
  userId = '',
  accessToken = '',
  columnList = [],
}) {
  const customColumnsUrl = `${getUserCustomColumns}?view=project`
  const params = {
    user_id: userId.toUpperCase(),
    column_list: columnList,
  }
  return axios.post(
    customColumnsUrl,
    {
      ...params,
    },
    {
      headers: {
        Authorization: accessToken,
        // 'x-tgt-lanid': userId,
      },
      params: {
        key: apiKey,
      },
    }
  )
}

export function makeProjectsArchive({ accessToken = '', params = {} }) {
  const projectArchiveUrl = `${projectsApi}/archives`
  return axios.post(
    projectArchiveUrl,
    {
      ...params,
    },
    {
      headers: {
        Authorization: accessToken,
        // 'x-tgt-lanid': userId,
      },
      params: {
        key: apiKey,
      },
    }
  )
}
