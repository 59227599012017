import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  TextField,
  Typography,
  Divider,
  FormControl,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { convertShortDate } from '../../helpers/dateHelper'
import { useForm } from '../Dashboard/CreateProject'
import { convertToEventParameters } from '../Dashboard/CreateProject'
import axios from 'axios'
import Autocomplete from '@material-ui/lab/Autocomplete'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { compact } from 'lodash'
import apiConfig, { appEnv } from '../../apiConfig'
import { withAuth } from '@praxis/component-auth'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { producerADGroup } from '../../constants/common'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const { projectsApi, projectUsersApi } = apiConfig

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'block',
    height: 'calc(100% - 65px)',
    overflow: 'auto',
    '& .MuiFormControl-root': {
      width: '80%',
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(-1),
    },
  },
  buttons: {
    margin: '16px',
  },
  validateButton: {
    marginLeft: theme.spacing(2),
    color: '#366CD9',
    borderColor: '#366CD9',
  },
  ownerDetails: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
  },
  inputFindProject: {
    transform: 'translate(14px, 16px) scale(1)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#666666',
  },
  dropDownInput: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#666666',
  },
  adornedEnd: {
    paddingRight: 'unset',
    '& >button': {
      padding: '0',
      marginRight: '-5px',
    },
  },
  divider: {
    width: '100%',
    borderTop: '1px solid #DDDDDD',
    marginTop: '30px',
  },
  ccButton: {
    width: '114px',
    height: '36px',
    marginRight: '16px',
  },
  submitButton: {
    backgroundColor: '#366CD9',
    color: '#ffffff',
    '&:disabled': {
      color: '#000000',
    },
    '&:hover': {
      color: '#000000',
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: 25,
    marginBottom: 10,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alertMessage: {
    width: '100%',
  },
}))

const EditProject = (props) => {
  const {
    accessToken = '',
    fullName = '',
    isEditDisabled = true,
    memberOf,
    lanId = '',
  } = props
  const projectData = props.projectData
  const classes = useStyles()
  const theme = useTheme()
  const initialValues = {
    name: (projectData || {}).project_name,
    pid: (projectData || {}).sap_pid,
    projectType: (projectData || {}).project_type,

    shotCount:
      (projectData.project_stats || {}).estimated_images /
        (projectData.project_stats || {}).tcin_count || null,
    dueDate: projectData.due_date,
    assetDueDate: projectData.asset_due_date || null,
    owners: (projectData.users || []).map(
      (u) => u.first_name + ' ' + u.last_name
    ),
    tcins: '',
    viewers: (projectData.viewers || []).map(
      (u) => u.first_name + ' ' + u.last_name
    ),
  }

  const validate = (fieldValues = values) => {
    let messages = { ...errors }
    if ('name' in fieldValues) {
      messages.name =
        fieldValues.name && fieldValues.name.trim() !== ''
          ? projects
              .filter((p) => p !== initialValues.name)
              .includes(fieldValues.name.trim())
            ? 'The project name is already taken. Try with a Different One'
            : ''
          : 'Name cannot be empty'
    }
    if ('shotCount' in fieldValues) {
      messages.shotCount =
        fieldValues.shotCount && fieldValues.shotCount.trim() !== ''
          ? !isNaN(fieldValues.shotCount.trim()) &&
            fieldValues.shotCount.trim() > 0
            ? ''
            : 'Shot count should be 1 or more'
          : ''
    }
    setErrors({ ...messages })

    if (fieldValues === values) {
      return Object.values(messages).every((x) => x === '')
    }
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  React.useEffect(() => {
    const getProjects = async () => {
      const response = await fetch(
        projectsApi + `/project_names?key=${apiConfig.apiKey}`,
        {
          headers: { Authorization: accessToken },
        }
      )
      const body = await response.json()
      setProjects(Object.values(body))
    }
    const getOwners = async () => {
      const response = await fetch(
        projectUsersApi +
          '/ad_groups?ad_groups=APP-PTB-Producer,APP-PTB-ADMIN' +
          `?key=${apiConfig.apiKey}`,
        {
          headers: { Authorization: accessToken },
        }
      )
      const body = await response.json()
      setOwners(body)
    }
    getProjects()
    getOwners()

    const getViewers = async () => {
      const response = await fetch(
        projectUsersApi +
          '/ad_groups?ad_groups=APP-PTB-GeneralUser,APP-PTB-Photographer,APP-PTB-ReToucher,APP-PTB-ReTouching-QA,APP-PTB-ReTouching-Lead,APP-PTB-ArtDirectors,APP-PTB-Producer,APP-PTB-ADMIN' +
          `?key=${apiConfig.apiKey}`,
        {
          headers: { Authorization: accessToken },
        }
      )
      const body = await response.json()
      setViewers(body)
    }
    getProjects()
    getViewers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [projects, setProjects] = React.useState([])
  const [owners, setOwners] = React.useState([])
  const [viewers, setViewers] = React.useState([])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!isEditDisabled) {
      const requestBody = {
        project_id: props.id,
        project_name: values.name,
        sap_pid: values.pid,
        project_type: values.projectType,
        due_date: values.dueDate,
        asset_due_date: values.assetDueDate,
        shot_count: values.shotCount,
        users: owners.filter((u) =>
          values.owners.includes(u.first_name + ' ' + u.last_name)
        ),
        viewers: viewers.filter((u) =>
          values.viewers.includes(u.first_name + ' ' + u.last_name)
        ),
        tcins: values.tcins !== '' ? formatTcinArray(values.tcins) : [],
      }

      const updateProjectApi = projectsApi + `/${props.id}`
      axios
        .put(updateProjectApi, requestBody, {
          params: { key: apiConfig.apiKey },
          headers: {
            Authorization: accessToken,
            ...(appEnv === 'dev' && { 'x-tgt-lanId': lanId }),
          },
        })
        .then(
          (response) => {
            props.closeDialog(true)
          },
          (error) => {
            window.alert(error)
          }
        )
    } else {
      alert(
        `You don't have permissions to Edit this Project. You should either be a Owner or a member of this Project`
      )
      props.closeDialog(true)
    }
  }

  const formatTcinArray = (tcins) => {
    const trimmedTcins = tcins.split(',').map((tcin) => tcin.trim())
    return trimmedTcins.filter((tcin) => tcin.length)
  }

  const [invalidTcins, setInvalidTcins] = React.useState([])
  const [duplicateTcins, setDuplicateTcins] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const validateTcins = () => {
    if (values.tcins !== '') {
      let requestBody = {
        tcin_list:
          values.tcins !== ''
            ? [...new Set(compact(values.tcins.split(/, |,/)))]
            : [],
      }
      axios
        .post(`${projectsApi}/tcin_validations`, requestBody, {
          params: {
            key: apiConfig.apiKey,
          },
          headers: { Authorization: accessToken },
        })
        .then(
          (response) => {
            setInvalidTcins(response.data.invalid_tcinlist)
            setDuplicateTcins(response.data.duplicate_tcinlist)
            setLoading(false)
          },
          (error) => {
            window.alert(error)
          }
        )
    } else {
      setLoading(false)
    }
  }

  const removeTcins = (tcins) => {
    setValues({
      ...values,
      tcins: values['tcins']
        .split(', ')
        .filter((t) => !tcins.includes(t))
        .join(', '),
    })
    setInvalidTcins(invalidTcins.filter((i) => !tcins.includes(i)))
    setDuplicateTcins(
      Object.keys(duplicateTcins)
        .filter((key) => !tcins.includes(key))
        .reduce((obj, key) => {
          obj[key] = duplicateTcins[key]
          return obj
        }, {})
    )
  }

  const onFocusIn = (e) => {
    setLoading(true)
    // if (e.target.value !== '') {
    //   setLoading(true)
    // }
  }

  const onFocusOut = (e) => {
    if (values.tcins === '') {
      setLoading(false)
    }
  }

  const onCancel = () => {
    setValues(initialValues)
    props.closeDialog(false)
  }

  return (
    <form className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} lg={6}>
          <Grid container style={{ margin: 0, padding: 0 }}>
            <Typography
              variant="body1"
              style={{ margin: theme.spacing(3), width: 'fit-Content' }}
              noWrap
            >
              {values.name} being created by {fullName || 'you'} on{' '}
              {convertShortDate({ format: 'MMM DD YYYY' })}
            </Typography>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5), marginLeft: '16px' }}
            >
              <TextField
                style={{
                  maxWidth: '360px',
                  margin: 0,
                }}
                InputProps={{ style: { maxHeight: '48px' } }}
                InputLabelProps={{
                  classes: {
                    outlined: classes.inputFindProject,
                  },
                }}
                {...(errors.name && { error: true, helperText: errors.name })}
                variant="outlined"
                label="Project Name"
                value={values.name}
                onChange={handleInputChange}
                name="name"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5), marginLeft: '16px' }}
            >
              <TextField
                style={{
                  maxWidth: '220px',
                  margin: 0,
                }}
                InputProps={{ style: { maxHeight: '48px' } }}
                InputLabelProps={{
                  classes: {
                    outlined: classes.inputFindProject,
                  },
                }}
                variant="outlined"
                label="PID"
                value={values.pid}
                onChange={handleInputChange}
                name="pid"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5), marginLeft: '16px' }}
            >
              {' '}
              <TextField
                {...(errors.shotCount && {
                  error: true,
                  helperText: errors.shotCount,
                })}
                style={{
                  maxWidth: '220px',
                  margin: 0,
                }}
                InputProps={{ style: { maxHeight: '48px' } }}
                InputLabelProps={{
                  classes: {
                    outlined: classes.inputFindProject,
                  },
                }}
                name="shotCount"
                variant="outlined"
                label="Estimated #shots per TCIN"
                value={values.shotCount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5), marginLeft: '16px' }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{
                    maxWidth: '220px',
                    margin: 0,
                  }}
                  InputProps={{
                    style: { maxHeight: '48px' },
                  }}
                  InputAdornmentProps={{
                    classes: { root: classes.adornedEnd },
                  }}
                  InputLabelProps={{
                    classes: {
                      outlined: classes.inputFindProject,
                    },
                  }}
                  {...(errors.dueDate && {
                    error: true,
                    helperText: 'Choose a date',
                  })}
                  label="Estimated Completion Date"
                  autoOk
                  format="MM/dd/yyyy"
                  variant="inline"
                  inputVariant="outlined"
                  onChange={(date) =>
                    handleInputChange(convertToEventParameters('dueDate', date))
                  }
                  value={values.dueDate}
                  name="dueDate"
                  disablePast
                ></KeyboardDatePicker>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5), marginLeft: '16px' }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{
                    maxWidth: '220px',
                    margin: 0,
                  }}
                  InputProps={{
                    style: { maxHeight: '48px' },
                  }}
                  InputAdornmentProps={{
                    classes: { root: classes.adornedEnd },
                  }}
                  InputLabelProps={{
                    classes: {
                      outlined: classes.inputFindProject,
                    },
                  }}
                  {...(errors.assetDueDate && {
                    error: true,
                    helperText: 'Choose a date',
                  })}
                  label="Asset Due Date"
                  autoOk
                  format="MM/dd/yyyy"
                  variant="inline"
                  inputVariant="outlined"
                  onChange={(date) =>
                    handleInputChange(
                      convertToEventParameters('assetDueDate', date)
                    )
                  }
                  value={values.assetDueDate}
                  name="assetDueDate"
                  disablePast
                ></KeyboardDatePicker>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5), marginLeft: '16px' }}
            >
              <Autocomplete
                defaultValue={values.owners}
                limitTags={2}
                multiple
                onChange={(e, newValue) =>
                  handleInputChange(e, newValue, 'owners')
                }
                options={owners.map((u) => u.first_name + ' ' + u.last_name)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(option) => (
                  <Typography
                    style={{
                      fontWeight:
                        values.owners.indexOf(option) > -1 ? 500 : 400,
                    }}
                  >
                    {option}
                  </Typography>
                )}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      width: '100%',
                      maxWidth: '480px',
                      margin: 0,
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      classes: {
                        outlined: classes.dropDownInput,
                      },
                    }}
                    variant="outlined"
                    label="Add Owners"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{ marginTop: theme.spacing(5), marginLeft: '16px' }}
            >
              <Autocomplete
                defaultValue={values.viewers}
                limitTags={2}
                multiple
                onChange={(e, newValue) =>
                  handleInputChange(e, newValue, 'viewers')
                }
                options={viewers.map((u) => u.first_name + ' ' + u.last_name)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(option) => (
                  <Typography
                    style={{
                      fontWeight:
                        values.viewers.indexOf(option) > -1 ? 500 : 400,
                    }}
                  >
                    {option}
                  </Typography>
                )}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      width: '100%',
                      maxWidth: '480px',
                      margin: 0,
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      classes: {
                        outlined: classes.dropDownInput,
                      },
                    }}
                    variant="outlined"
                    label="Add Followers"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: theme.spacing(10) }}
          item
          xs={12}
          sm={12}
          lg={6}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            style={{ marginTop: theme.spacing(4) }}
          >
            <Typography
              variant="body1"
              style={{ margin: '40px 0 0 24px mx-2' }}
              value={values.projectType}
            >
              Project Type:
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="projectType"
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="special"
                    control={<Radio />}
                    label="Special"
                  />
                  <FormControlLabel
                    value="item"
                    control={<Radio />}
                    label="Item"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Typography>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            style={{ marginTop: theme.spacing(3) }}
          >
            <TextField
              variant="outlined"
              style={{
                maxWidth: '480px',
                margin: 0,
              }}
              InputProps={{ style: { maxHeight: '48px' } }}
              InputLabelProps={{
                classes: {
                  outlined: classes.inputFindProject,
                },
              }}
              label="Add TCINs"
              value={values.tcins}
              onChange={handleInputChange}
              name="tcins"
              onBlur={onFocusOut}
              onFocus={onFocusIn}
            />

            <Button
              onClick={(e) => validateTcins()}
              className={classes.validateButton}
              variant="outlined"
            >
              Validate
            </Button>
          </Grid>
          {invalidTcins.length > 0 && (
            <Alert
              severity="error"
              classes={{
                message: classes.alertMessage,
              }}
              style={{ width: '75%', marginTop: '10px' }}
            >
              <AlertTitle>Error - Invalid TCIN</AlertTitle>
              <div className={classes.alert}>
                <Typography
                  variant="body1"
                  style={{ marginLeft: theme.spacing(3) }}
                >
                  You have entered {invalidTcins.length} invalid Tcins.
                </Typography>
                <CopyToClipboard text={invalidTcins.join('\n')}>
                  <Button color="primary" startIcon={<FileCopyOutlinedIcon />}>
                    COPY
                  </Button>
                </CopyToClipboard>
                <IconButton
                  onClick={() => removeTcins(invalidTcins)}
                  aria-label="delete"
                  className={classes.margin}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </Alert>
          )}
          {Object.keys(duplicateTcins).length > 0 && (
            <Alert
              severity="warning"
              classes={{
                message: classes.alertMessage,
              }}
              style={{ width: '80%', marginTop: '10px' }}
            >
              <AlertTitle>Warning - TCIN in multiple projects</AlertTitle>
              <div style={{ height: 400, overflow: 'auto' }}>
                {Object.keys(duplicateTcins).map((t) =>
                  duplicateTcins[t].map((value) => (
                    <div className={classes.alert}>
                      <Typography
                        variant="body1"
                        style={{ marginLeft: theme.spacing(3) }}
                      >
                        {t} is with {value.project_name} project
                      </Typography>
                      <IconButton
                        onClick={() => removeTcins([t])}
                        aria-label="delete"
                        className={classes.margin}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ))
                )}
              </div>
            </Alert>
          )}
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.buttons}>
          <Button
            className={classes.ccButton}
            variant="outlined"
            onClick={onCancel}
            size="medium"
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            className={classes.submitButton}
            disabled={
              !memberOf?.includes(producerADGroup.toUpperCase()) ||
              loading ||
              invalidTcins.length > 0 ||
              errors.name ||
              errors.shotDate
            }
            size="medium"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </Grid>
    </form>
  )
}

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    fullName: auth.session?.userInfo?.fullName,
    memberOf: auth.session?.userInfo?.memberOf,
    lanId: auth.session?.userInfo?.lanId,
  }
}

export default withAuth(mapAuthToProps)(EditProject)
