import * as React from 'react'
import WorkflowTable from './WorkflowTable'
import { Grid, IconButton, Button, TextField, Typography } from '@mui/material'

import SearchIcon from '@material-ui/icons/Search'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setBreadcrumbs } from '../../store/Layout/actionCreator'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AppBar from '@mui/material/AppBar'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { makeStyles } from '@material-ui/core/styles'

import axios from 'axios'
import {
  initUpload,
  uploadStartCallInit,
  uploadStartCallFail,
  uploadStartCallSuccess,
  chunkInit,
  chunkSuccess,
  chunkFail,
  chunkEndCallInit,
  chunkEndCallSuccess,
  chunkEndCallFail,
  uploadJobStatusInit,
  uploadJobStatusSuccess,
  uploadJobStatusFail,
  toggleUploadManager,
} from '../../store/uploads/actionCreator'
import { workflowTabs } from './constants'
import {
  getAsigneeList,
  getCustomColumnsAction,
  getQaAssigneeList,
  getTagsAction,
  updateTableThumbnail,
} from '../../store/Workflow/actionCreator'
import { useAuth } from '@praxis/component-auth'
import {
  adminADGroup,
  artDirectorADGroup,
  fileValidationTexts,
  producerADGroup,
  retoucherADGroup,
  retouchingLeadADGroup,
  retouchingQAADGroup,
} from '../../constants/common'
import { intersectionBy, toUpper, isEmpty } from 'lodash'
import { getChunkNumbers } from '../Upload/helper'
import { UPLOAD_FILE_TARGET_NAME } from '../../constants/uploads'
import apiConfig from '../../apiConfig'
import cloneDeep from 'lodash/cloneDeep'
import { withAuth } from '@praxis/component-auth'
import { getUploadStatus } from './utils'
import { appEnv } from '../../apiConfig'
import { isSafari } from '../../helpers/filter'
import { uniqBy } from 'lodash'
import UploadManger from '../UploadManager'

const CURR_PROJECT = 'PHOTOBRIDGE'
const CURR_PROJECT_UUID = 'PHOTOBRIDGE_WORKFLOW_UUID'
const CURR_PROJECT_UOID = 'PHOTOBRIDGE__WORKFLOW__UOID'

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    position: 'relative',
    height: 'auto',
    padding: 0,
    margin: '0 0 0 auto !important',
    width: 'auto !important',
  },
  searchButton: {
    border: '1px solid #C4C4C4 !important',
    borderRadius: '4px !important',
    padding: '6px !important',
    '&:disabled': {
      cursor: 'not-allowed !important',
    },
  },
  inputLabel: {
    fontSize: '14px !important',
    lineHeight: 'unset !important',
    transform: 'translate(14px, 8px) scale(1) !important',
  },
  labelShrink: {
    transform: 'translate(14px, -6px) scale(0.75) !important',
  },
  inputProps: {
    height: '32px !important',
    width: '300px !important',
    padding: '0 8px !important',
  },
  searchIconContainer: {
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '32px',
      border: '1px solid #1976d2',
      bottom: 0,
      right: '-2px',
    },
  },
  tagsButton: {
    padding: '35px 54px 0 24px !important',
    '&:hover': {
      outline: 'none',
      background: 'none !important',
    },
  },
  filter: {
    minWidth: 160,
  },
}))

const Workflow = (props) => {
  const auth = useAuth()
  const {
    session: { userInfo: { memberOf = [], accessToken = '' } = {} } = {},
  } = auth
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [currentTab, setCurrentTab] = React.useState('NEW')
  const [droppedFiles, setDroppedFiles] = React.useState({})
  const uploadFiles = React.useRef([])

  const [searchValue, setSearchValue] = React.useState('')
  const [searchKeywordValue, setKeywordSearchValue] = React.useState('')

  const [canUploadFiles, setCanUploadFiles] = React.useState([])
  const [newImageFiles, setNewImageFiles] = React.useState([])
  const [dropZoneActive, setDropZoneActive] = React.useState(false)
  const [searchType, setSearchType] = React.useState('Images')
  const [statusCounts] = useSelector((state) => {
    const { workflowReducer: { statusCounts = {} } = {} } = state
    return [statusCounts]
  })
  const { lanId = '' } = props

  const onSearch = () => {
    setCurrentTab('SEARCH')
    workflowTableRef.current.search()
  }
  const uploadsInProgress = useSelector((state) => {
    const {
      uploadsReducer: {
        tcins: { PHOTOBRIDGE_WORKFLOW_UUID: { uploadingFiles } = {} } = {},
      } = {},
    } = state
    return uploadingFiles || {}
  })

  const successFiles = useSelector((state) => {
    const {
      uploadsReducer: {
        tcins: { PHOTOBRIDGE_WORKFLOW_UUID: { successFiles } = {} } = {},
      } = {},
    } = state
    return successFiles || {}
  })

  const failedFiles = useSelector((state) => {
    const {
      uploadsReducer: {
        tcins: { PHOTOBRIDGE_WORKFLOW_UUID: { failedFiles } = {} } = {},
      } = {},
    } = state
    return failedFiles || {}
  })

  const dropzoneRef = React.useRef(null)
  const workflowTableRef = React.useRef(null)
  const classes = useStyles()
  let uploadCount = React.useRef(0)
  let pollingIntervalId = React.useRef(0)
  let lastFile = React.useRef(0)
  let partetag = React.useRef({})

  let uploadIdsForPolling = React.useRef({})
  let uploadSuccessFiles = React.useRef({})
  let uploadFilesProgress = React.useRef([])

  const startPolling = (file = {}, params = {}) => {
    pollingIntervalId.current = setInterval(async () => {
      try {
        if (!isEmpty(uploadIdsForPolling.current)) {
          const response = await getUploadStatus(
            accessToken,
            lanId,
            Object.keys(uploadIdsForPolling.current)
          )
          const { data: { upload_info_details_list = [] } = {} } =
            await response
          checkUploadStatus(upload_info_details_list, file, params)
        } else {
          clearInterval(pollingIntervalId.current)
        }
      } catch (err) {
        console.error(err)
      }
    }, 20000)
  }

  const checkUploadStatus = (data = []) => {
    data.forEach((data) => {
      const { uploaded_version_number = '-', upload_status = '' } = data
      if (upload_status === 'SUCCESS') {
        const {
          project_uuid = '',
          name = '',
          vault_upload_status = '',
        } = uploadSuccessFiles.current[data.file_name]

        dispatch(
          uploadJobStatusSuccess(
            project_uuid,
            name,
            vault_upload_status,
            uploaded_version_number
          )
        )
        uploadProgressCallBack(uploadIdsForPolling.current[data.upload_id])
        dispatch(updateTableThumbnail(data))
        workflowTableRef.current.updateSelection(data)
        delete uploadSuccessFiles.current[data.file_name]
        delete uploadIdsForPolling.current[data.upload_id]
      }
      if (upload_status === 'FAILURE') {
        const {
          project_uuid = '',
          name = '',
          vault_upload_status = '',
        } = uploadSuccessFiles.current[data.file_name]

        dispatch(uploadJobStatusFail(project_uuid, name))
        uploadProgressCallBack(uploadIdsForPolling.current[data.upload_id])
        workflowTableRef.current.updateSelection(data)
        delete uploadSuccessFiles.current[data.file_name]
        delete uploadIdsForPolling.current[data.upload_id]
      }
    })
  }

  const onDrop = async (files) => {
    setDropZoneActive(false)
    const filesTobeValidated = files.reduce((obj, item) => {
      if (uploadsInProgress[item.name]) {
        if (!obj['fileNameWithStatus']) {
          obj['fileNameWithStatus'] = {
            [item.name]: 'Upload is already in progress',
          }
        } else {
          obj['fileNameWithStatus'][item.name] = 'Upload is already in progress'
        }
      } else {
        if (!obj['fileNames'] && !uploadsInProgress[item.name]) {
          obj['fileNames'] = [item.name]
        } else {
          obj['fileNames'].push(item.name)
        }
        if (!obj['fileNameWithStatus']) {
          obj['fileNameWithStatus'] = {
            [item.name]: fileValidationTexts.validating,
          }
        } else {
          obj['fileNameWithStatus'][item.name] = fileValidationTexts.validating
        }
      }

      return obj
    }, {})
    const { fileNames = [], fileNameWithStatus = {} } = filesTobeValidated
    setDroppedFiles({ ...droppedFiles, ...fileNameWithStatus })
    if (fileNames.length) {
      axios
        .post(
          `${apiConfig.uploads.validatingUrl}`,
          { file_names: fileNames },
          {
            params: {
              key: apiConfig.apiKey,
            },
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((resp) => {
          const {
            data: {
              result: {
                error_files_list = [],
                existing_images_list = [],
                new_images_list = [],
                reused_image_list = [],
              } = {},
            } = {},
          } = resp
          const validNewImageFiles = []

          Object.keys(fileNameWithStatus).forEach((key, i) => {
            if (fileNameWithStatus[key] !== 'Upload is already in progress') {
              let err = error_files_list.find((elem) => elem.file_name === key)
              const isNew = new_images_list.find((elem) => elem === key)
              const isReused = reused_image_list.find((elem) => elem === key)
              if (err) {
                fileNameWithStatus[key] = err.reason
              } else if (isNew) {
                delete fileNameWithStatus[key]
                const filteredNewImage = files.find((item) => item.name === key)
                filteredNewImage.validationText = fileValidationTexts.new
                validNewImageFiles.push(filteredNewImage)
              } else if (isReused) {
                fileNameWithStatus[key] = fileValidationTexts.reusedImage
              } else {
                fileNameWithStatus[key] = fileValidationTexts.valid
              }
            }
          })
          setDroppedFiles({ ...droppedFiles, ...fileNameWithStatus })
          if (existing_images_list) {
            const canUpload = files.filter(
              (item) =>
                existing_images_list.includes(item.name) &&
                !canUploadFiles.some((dat) => dat.name === item.name)
            )
            let mergePrevCanUpload = cloneDeep(canUploadFiles).concat(canUpload)
            setCanUploadFiles(mergePrevCanUpload)
          }
          if (validNewImageFiles) {
            let mergePrevNewImages = uniqBy(
              cloneDeep(newImageFiles).concat(validNewImageFiles),
              'name'
            )
            setNewImageFiles(mergePrevNewImages)
          }
        })
        .catch((err) => {
          Object.keys(fileNameWithStatus).forEach((key, i) => {
            if (fileNameWithStatus[key] !== 'Upload is already in progress') {
              fileNameWithStatus[key] = fileValidationTexts.error
            }
          })

          setDroppedFiles({ ...droppedFiles, ...fileNameWithStatus })
        })
    }
  }

  const uploadProgressCallBack = (
    uploadProgressStatus = '',
    totalChunks = Number(0),
    completedChunks = Number(0),
    fileName = ''
  ) => {
    let files = cloneDeep(uploadFiles.current)
    let arrayObj = []
    const newDeleteList = files.filter(
      (obj) => obj.name !== fileName && obj.uploadStatus !== 'success'
    )
    arrayObj = [
      ...newDeleteList,
      {
        uploadStatus: uploadProgressStatus,
        totalChunks: totalChunks,
        completedChunks: completedChunks,
        name: fileName,
      },
    ]
    if (arrayObj) {
      uploadFilesProgress.current = [
        ...uploadFilesProgress.current,
        ...arrayObj,
      ]
      // uploadFiles.current = arrayObj
    }
  }

  const upload = (files) => {
    const newFiles = cloneDeep(files)

    let projectDetails = {
      project_name: CURR_PROJECT,
      project_uuid: CURR_PROJECT_UUID,
    }
    let filesObj = {}
    let chunkSize = 5 * 1024 * 1024
    let shotTypeUploads = {}
    newFiles.forEach(function (singleFile) {
      const totalNumberOfChunks = Math.ceil(singleFile.size / chunkSize)
      const singleFileObj = {
        completedChunks: 0,
        chunkSize: chunkSize,
        totalNumberOfChunks: totalNumberOfChunks,
        uploadStatus: 'Not Started',
        project_name: CURR_PROJECT,
        project_uuid: CURR_PROJECT_UUID,
        uoi_id: CURR_PROJECT_UOID,
        currentChunk: 0,
        remainingChunks: getChunkNumbers(totalNumberOfChunks),
      }
      const newObj = Object.assign(singleFile, singleFileObj)
      shotTypeUploads = {
        name: newObj.name,
        completedChunks: newObj.completedChunks,
        totalNumberOfChunks: newObj.totalNumberOfChunks,
        uploadStatus: newObj.uploadStatus,
      }
      filesObj[newObj.name] = Object.assign({}, shotTypeUploads)
    })
    dispatch(initUpload(filesObj, projectDetails))
    setDroppedFiles({})
    setCanUploadFiles([])
    setNewImageFiles([])
    uploadFiles.current = [...uploadFiles.current, ...newFiles]
    uploadManagerInit(newFiles)
    uploadStartCall()
  }

  const uploadManagerInit = (files = []) => {
    files.forEach(function (singleFile) {
      dispatch(uploadStartCallInit(singleFile.project_uuid, singleFile.name))
    })
  }

  const uploadStartCall = () => {
    let totalCall = uploadCount.current
    for (let i = lastFile.current; i < uploadFiles.current.length; i++) {
      totalCall = totalCall + uploadFiles.current[i].totalNumberOfChunks
      startLoop(uploadFiles.current[i])
      lastFile.current = lastFile.current + 1
      if (totalCall > 10) {
        break
      }
    }
  }

  const startLoop = (file) => {
    uploadCount.current = uploadCount.current + 1
    let data = {
      content_length: file.size,
      content_type: file.type,
      file_name: file.name,
      ...(file.studio && {
        studio: file.studio,
      }),
      targets: [UPLOAD_FILE_TARGET_NAME],
    }
    axios
      .post(
        `${
          apiConfig.uploads[
            file.studio ? 'newUploadsUrl' : 'existingUploadsUrl'
          ]
        }/start`,
        data,
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((response) => {
        dispatch(uploadStartCallSuccess(file.project_uuid, file.name))
        file.uploadId = response.data.upload_id
        uploadCount.current = uploadCount.current - 1
        if (uploadCount.current <= 10) {
          startChunking(file, 0)
        } else {
          lastFile.current = lastFile.current - 1
        }
      })
      .catch((e) => {
        uploadProgressCallBack(
          'ERROR',
          file.totalNumberOfChunks,
          file.completedChunks,
          file.name
        )
        uploadCount.current = uploadCount.current - 1
        dispatch(uploadStartCallFail(file.project_uuid, file.name))
      })
  }

  const startChunking = (file = {}, chunkNumber) => {
    const remainingChunks = file.remainingChunks
    if (remainingChunks.includes(chunkNumber)) {
      for (
        let requestedChunk = chunkNumber;
        requestedChunk < remainingChunks.length;
        requestedChunk++
      ) {
        if (
          uploadCount.current <= 10 &&
          file.completedChunks < file.totalNumberOfChunks
        ) {
          uploadCount.current = uploadCount.current + 1
          file.currentChunk = requestedChunk
          delete file.remainingChunks[requestedChunk]
          sendChunk(file)
        } else {
          break
        }
      }
    } else if (remainingChunks.length > 0) {
      file.currentChunk = remainingChunks[0]
      startChunking(file, file.currentChunk)
    }
  }

  const sendChunk = (file) => {
    dispatch(chunkInit(file.project_uuid, file.name))

    let chunkedFormData = new window.FormData()
    chunkedFormData.append(
      'chunkUploadRequest',
      new window.Blob(
        [
          JSON.stringify({
            upload_id: file.uploadId,
            file_name: file.name,
            ...(file.studio && {
              studio: file.studio,
            }),
            chunk_number: file.currentChunk,
            total_number_of_chunks: file.totalNumberOfChunks,
            chunk_size: file.chunkSize,
            total_file_size: file.size,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    chunkedFormData.append(
      'file',
      file.slice(
        file.currentChunk * file.chunkSize,
        (file.currentChunk + 1) * file.chunkSize
      )
    )

    axios
      .post(
        `${
          apiConfig.uploads[
            file.studio ? 'newUploadsUrl' : 'existingUploadsUrl'
          ]
        }`,
        chunkedFormData,
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((response) => {
        file.completedChunks = file.completedChunks + 1
        uploadCount.current = uploadCount.current - 1
        dispatch(
          chunkSuccess(
            file.project_uuid,
            file.name,
            file.completedChunks,
            file.totalNumberOfChunks
          )
        )
        const { parte_tag, upload_chunk_id } = response.data
        partetag.current[upload_chunk_id]
          ? (partetag.current[upload_chunk_id] = [
              ...partetag.current[upload_chunk_id],
              parte_tag,
            ])
          : (partetag.current[upload_chunk_id] = [parte_tag])
        if (file.completedChunks === file.totalNumberOfChunks) {
          sendChunkEndCall(file)
        } else {
          startChunking(file, Number(file.currentChunk) + 1)
        }
      })
      .catch((error) => {
        file.completedChunks = file.completedChunks + 1
        uploadCount.current = uploadCount.current - 1
        dispatch(chunkFail(file.project_uuid, file.name))
        uploadProgressCallBack(
          'ERROR',
          file.totalNumberOfChunks,
          file.completedChunks,
          file.name
        )
      })
  }

  const sendChunkEndCall = (file) => {
    dispatch(chunkEndCallInit(file.project_uuid, file.name))
    let params = {
      job_id: file.uploadId,
      vault_upload_status: '',
      original_asset_name: file.name,
      project_uuid: file.project_uuid,
      project_name: file.project_name,
      content_signature: '',
      asset_content_type: '',
      asset_size: file.size,
      metadata: '',
      sync_folder_id: file.uoi_id,
      version: file.version,
      asset_master_id: file.asset_master_id,
      asset_master_uoi_id: file.asset_master_uoi_id,
      creative_asset_status: file.creative_asset_status || 'WIP',
      content_type: 'image',
    }
    let url = `${
      apiConfig.uploads[file.studio ? 'newUploadsUrl' : 'existingUploadsUrl']
    }/end`
    let req = {
      upload_id: file.uploadId,
      file_name: file.name,
      ...(file.studio && {
        studio: file.studio,
      }),
      parte_tags: partetag.current[file.uploadId],
      file_size: file.size,
    }
    uploadCount.current = uploadCount.current + 1
    axios
      .post(url, req, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': lanId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        dispatch(chunkEndCallSuccess(file.project_uuid, file.name))
        const { data: res = {} } = response
        const {
          render_url = '',
          message = 'Success, new version created',
          file_name = '',
        } = res
        // uploadProgressCallBack(
        //   job_status,
        //   file.totalNumberOfChunks,
        //   file.completedChunks,
        //   file.name
        // )
        params = Object.assign(params, {
          render_url: render_url,
          vault_upload_status: message,
          job_id: file.uploadId,
        })
        uploadCount.current = uploadCount.current - 1
        if (uploadCount.current <= 10) {
          updateUploadStatus(file, params, true)
        }
        if (file.studio) {
          dispatch(
            uploadJobStatusSuccess(file.project_uuid, file_name, message, '')
          )
        } else {
          uploadIdsForPolling.current = {
            ...uploadIdsForPolling.current,
            [file.uploadId]: {
              status: message,
              totalChunks: file.totalNumberOfChunks,
              completedChunks: file.completedChunks,
              name: file.name,
            },
          }
          uploadSuccessFiles.current = {
            ...uploadSuccessFiles.current,
            [file.name]: {
              project_uuid: file.project_uuid,
              name: file.name,
              vault_upload_status: params.vault_upload_status,
            },
          }
          clearInterval(pollingIntervalId.current)
          startPolling(file, params)
        }
      })
      .catch((e) => {
        uploadCount.current = uploadCount.current - 1
        dispatch(chunkEndCallFail(file.project_uuid, file.name))
        params = Object.assign(params, {
          vault_upload_status: 'ERROR',
          render_url: '',
          job_id: file.uploadId,
        })
        if (uploadCount.current <= 10) {
          updateUploadStatus(file, params, false)
        }
        uploadProgressCallBack(
          'ERROR',
          file.totalNumberOfChunks,
          file.completedChunks,
          file.name
        )
      })
  }
  const updateUploadStatus = (file = {}, params = {}, isEndCallSuccess) => {
    uploadCount.current = uploadCount.current + 1

    if (isEndCallSuccess) {
      // dispatch(
      //   uploadJobStatusSuccess(
      //     file.project_uuid,
      //     file.name,
      //     params.vault_upload_status
      //   )
      // )
      uploadCount.current = uploadCount.current - 1
    } else {
      uploadCount.current = uploadCount.current - 1
      dispatch(uploadJobStatusFail(file.project_uuid, file.name))
    }
    if (uploadCount.current <= 10) {
      uploadStartCall()
    }
  }

  const searchImage = (event) => {
    let imageList = event.target.value.split(/[^\d\w.-]+/g)
    setSearchValue(imageList.join(', ').trim())
  }

  const searchKeywords = (event) => {
    setKeywordSearchValue(event.target.value)
  }

  const onDragEnter = () => {
    setDropZoneActive(true)
  }

  const onDragLeave = () => {
    setDropZoneActive(false)
  }

  const openSystemDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  const handleChange = (event, newValue) => {
    if (searchValue) {
      setSearchValue('')
    }
    setCurrentTab(newValue)
  }

  const handleStudioChange = (id, studio) => {
    const file = cloneDeep(newImageFiles)
    for (const singleFile of file) {
      if (singleFile.name === id) {
        singleFile.studio = studio
        break
      }
    }

    setNewImageFiles(file)
  }

  const checkUploadEnable = () => {
    if (
      canUploadFiles.length &&
      newImageFiles.length &&
      newImageFiles.every((dat) => dat.studio)
    ) {
      return true
    } else if (canUploadFiles.length && !newImageFiles.length) {
      return true
    } else if (
      !canUploadFiles.length &&
      newImageFiles.length &&
      newImageFiles.every((dat) => dat.studio)
    ) {
      return true
    } else {
      return false
    }
  }

  function a11yProps(tab) {
    return {
      id: `workflow-tab-${tab}`,
      'aria-controls': `workflow-tab-${tab}`,
    }
  }

  React.useEffect(() => {
    dispatch(setBreadcrumbs(props.customPath, pathname))
    dispatch(
      getAsigneeList(accessToken, [
        retoucherADGroup,
        retouchingQAADGroup,
        artDirectorADGroup,
      ])
    )
    dispatch(
      getQaAssigneeList(accessToken, [
        retouchingQAADGroup,
        retouchingLeadADGroup,
        adminADGroup,
      ])
    )
    dispatch(getTagsAction(accessToken))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  React.useEffect(() => {
    if (!isEmpty(uploadsInProgress)) {
      workflowTableRef.current.updateSelectionForUpload()
    }
  }, [uploadsInProgress])

  React.useEffect(() => {
    if (lanId) {
      dispatch(getCustomColumnsAction(accessToken, lanId, currentTab))
    }
    workflowTableRef.current.updateSelectionForUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lanId, currentTab])
  return (
    <div style={{ width: '100%', marginTop: isSafari() ? '105px' : '0px' }}>
      <AppBar
        position="fixed"
        sx={{
          ml: 2,
          mt: 8,
          borderBottom: 1,
          borderColor: '#BDBDBD',
          backgroundColor: 'white',
          boxShadow: 0,
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: isSafari() ? '100px' : '78px',
          paddingBlockStart: isSafari() ? '5px' : '0px',
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="workflow-tabs"
          sx={{
            pl: 9,
            pt: 1,
          }}
        >
          <Tab
            value={workflowTabs.new}
            label={workflowTabs.new + ` ${`(${statusCounts['NEW'] || 0})`}`}
            {...a11yProps(workflowTabs.new)}
          />
          <Tab
            value={workflowTabs.retouch}
            label={
              workflowTabs.retouch + ` ${`(${statusCounts['RETOUCH'] || 0})`}`
            }
            {...a11yProps(workflowTabs.retouch)}
          />
          <Tab
            value={workflowTabs.cc}
            label={workflowTabs.cc + ` ${`(${statusCounts['COLOR'] || 0})`}`}
            {...a11yProps(workflowTabs.cc)}
          />
          <Tab
            value={workflowTabs.hold}
            label={workflowTabs.hold + ` ${`(${statusCounts['HOLD'] || 0})`}`}
            {...a11yProps(workflowTabs.hold)}
          />
          <Tab
            value={workflowTabs.review}
            label={
              workflowTabs.review + ` ${`(${statusCounts['REVIEW'] || 0})`}`
            }
            {...a11yProps(workflowTabs.review)}
          />
          <Tab
            value={workflowTabs.approved}
            label={
              workflowTabs.approved + ` ${`(${statusCounts['APPROVED'] || 0})`}`
            }
            {...a11yProps(workflowTabs.approved)}
          />
        </Tabs>
        <Grid
          wrap={'nowrap'}
          container
          alignItems={'center'}
          spacing={2}
          className={classes.searchContainer}
        >
          <Grid container direction="column">
            <Grid item style={{ padding: 0 }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="ViewType"
                  onChange={(e) => setSearchType(e.target.value)}
                  value={searchType}
                >
                  <FormControlLabel
                    control={
                      <Typography variant="body1">Search By:</Typography>
                    }
                    sx={{ color: 'black', paddingLeft: '10px' }}
                  />

                  <FormControlLabel
                    value="Images"
                    control={<Radio />}
                    label="File Name"
                    sx={{ color: searchType === 'Images' ? 'black' : 'gray' }}
                  />
                  <FormControlLabel
                    value="KeyWords"
                    control={<Radio />}
                    label="KeyWords"
                    sx={{ color: searchType === 'KeyWords' ? 'black' : 'gray' }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item style={{ padding: 0 }}>
              <TextField
                onKeyPress={(e) => {
                  e.key === 'Enter' && onSearch()
                }}
                type="search"
                autoFocus
                value={
                  searchType === 'Images' ? searchValue : searchKeywordValue
                }
                onChange={
                  searchType === 'Images' ? searchImage : searchKeywords
                }
                label={
                  searchType === 'Images'
                    ? 'Find Images By Filename'
                    : 'Find Images By Keywords'
                }
                id="find-image"
                InputLabelProps={{
                  classes: {
                    outlined: classes.inputLabel,
                    shrink: classes.labelShrink,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.inputProps,
                  },
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              padding: '38px 0 0 8px',
            }}
            className={currentTab === 'SEARCH' && classes.searchIconContainer}
          >
            <IconButton
              disabled={!searchValue.length && !searchKeywordValue.length}
              onClick={onSearch}
              aria-label="search"
              className={classes.searchButton}
            >
              <SearchIcon style={{ fontSize: 18 }} />
            </IconButton>
          </Grid>
        </Grid>

        {intersectionBy(
          memberOf,
          [producerADGroup, adminADGroup, retouchingLeadADGroup],
          toUpper
        ).length === 0 ? (
          <div
            className={classes.tagsButton}
            style={{
              minWidth: '180px',
            }}
          ></div>
        ) : (
          <Button
            variant="text"
            className={classes.tagsButton}
            onClick={() => {
              workflowTableRef.current.showDrawer()
            }}
          >
            Manage Tags
          </Button>
        )}
      </AppBar>
      <WorkflowTable
        tableTab={currentTab}
        ref={workflowTableRef}
        searchedValue={searchValue}
        searchKeywordValue={searchKeywordValue}
        searchType={searchType}
        uploadsInProgress={uploadsInProgress}
      />
      <UploadManger
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        isDropzoneActive={dropZoneActive}
        onDrop={onDrop}
        checkIsUploadEnable={checkUploadEnable()}
        successTable={
          <UploadManger.UploadSuccessTable successFiles={successFiles} />
        }
        failedTable={
          <UploadManger.UploadFailedTable failedFiles={failedFiles} />
        }
        uploadProgressTable={
          <UploadManger.UploadProgressTable
            uploadsInProgress={uploadsInProgress}
          />
        }
        uploadsInProgress={uploadsInProgress}
        successFiles={successFiles}
        failedFiles={failedFiles}
        droppedFilesWorkflow={
          <UploadManger.DroppedFilesTableWorkflow
            droppedFiles={droppedFiles}
            onExistingDelete={(row) => {
              const updatedFiles = cloneDeep(droppedFiles)
              delete updatedFiles[row]
              const canUpload = canUploadFiles.filter(
                (item) => item.name !== row
              )
              setCanUploadFiles(canUpload)
              setDroppedFiles(updatedFiles)
            }}
          />
        }
        newImageFilesTable={
          <UploadManger.NewImageFilesTable
            handleStudioChange={handleStudioChange}
            newImageFiles={newImageFiles}
            onNewDelete={(row) => {
              const canUpload = newImageFiles.filter(
                (item) => item.name !== row.name
              )
              setNewImageFiles(canUpload)
            }}
          />
        }
        onDiscard={() => {
          setDroppedFiles([])
          setCanUploadFiles([])
        }}
        onUpload={() => upload([...canUploadFiles, ...newImageFiles])}
        page="workflow"
      />
    </div>
  )
}

const mapAuthToProps = (auth) => {
  return {
    lanId: auth.session?.userInfo?.lanId,
  }
}

export default withAuth(mapAuthToProps)(Workflow)
