import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { findIndex, first } from 'lodash'
import { Grid, Tabs, Typography, Box, Checkbox } from '@mui/material'
import ImageCard from '../../components/ImageCard'
import PreviewHeader from './PreviewHeader'
import { makeStyles } from '@material-ui/core/styles'
import WorkflowTable from '../Workflow/WorkflowTable'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 64,
  },
  outlinedButton: {
    color: '#ffffff !important',
    border: '1px solid #ffffff !important',
    background: 'transparent !important',
  },
  button: {
    height: '32px',
    minWidth: '90px',
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '500 !important',
  },
  containedButton: {
    background: '#ffffff !important',
    border: ' 1px solid #ffffff !important',
    color: '#CC0000 !important',
    '&:hover': {
      backgroundColor: '#ffffff !important',
      color: '#CC0000 !important',
      border: ' 1px solid #ffffff !important',
    },
    '&:focus': {
      backgroundColor: '#ffffff !important',
      color: '#CC0000 !important',
      border: ' 1px solid #ffffff !important',
    },
  },
}))

export default function AdvancedPreview() {
  const navigate = useNavigate()
  const workflowTableRef = React.useRef(null)
  const [data, setData] = useState([])
  const [finalSelectedData, setFinalSelectedData] = useState([])
  const [activeImage, setActiveImage] = useState({})
  const [workflowTableProps, setWorkflowTableProps] = useState({})
  const [tableTab, setTableTab] = useState('')

  const channel = new BroadcastChannel('selectionChannel')

  useEffect(() => {
    const cleanup = () => {
      localStorage.removeItem('selectionState')
      localStorage.removeItem('workflowTableProps')
      channel.close()
    }
    window.addEventListener('beforeunload', cleanup)
    const selectionState = localStorage.getItem('selectionState')
    const selectionStateData = selectionState ? JSON.parse(selectionState) : []
    const workflowTablePropsData = localStorage.getItem('workflowTableProps')
    const workflowTabData = workflowTablePropsData
      ? JSON.parse(workflowTablePropsData)
      : {}
    const activeSelImage = first(selectionStateData || []) || {}
    if (selectionState) {
      setData(selectionStateData)
      setFinalSelectedData(selectionStateData)
      setActiveImage(activeSelImage)
      setWorkflowTableProps(workflowTabData)
      workflowTableRef.current.showWorkflowDrawer(
        workflowTabData?.tableTab || '',
        selectionStateData,
        'advancedpreview'
      )
      setTableTab(workflowTabData?.tableTab || '')
    } else {
      navigate('/workflow')
    }
    channel.onmessage = (event) => {
      const data = event.data || {}
      const { workflowTableProps = {}, selection = {} } = data
      const workflowTabData =
        Object.entries(workflowTableProps)?.length > 0
          ? JSON.parse(workflowTableProps)
          : {}
      const selectionData =
        Object.entries(selection)?.length > 0 ? JSON.parse(selection) : {}
      setData(selectionData)
      setFinalSelectedData(selectionData)
      setWorkflowTableProps(workflowTabData)
      workflowTableRef.current.showWorkflowDrawer(
        workflowTabData?.tableTab || '',
        selectionData,
        'advancedpreview'
      )
      setTableTab(workflowTabData?.tableTab || '')
    }
    return () => {
      window.removeEventListener('beforeunload', cleanup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  const [pageSize, setPageSize] = useState(4)

  const showWorkflowFlyout = () => {
    workflowTableRef?.current?.showWorkflowDrawer(
      workflowTableProps?.tableTab,
      finalSelectedData,
      'advancedpreview'
    )
  }

  const onPreviewCheckChange = (asset) => {
    const selected = { ...asset }
    const { id = '' } = selected
    const selectedIndex = findIndex(finalSelectedData, function (obj = {}) {
      return obj.id === id
    })

    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(finalSelectedData, selected)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(finalSelectedData.slice(1))
    } else if (selectedIndex === finalSelectedData.length - 1) {
      newSelected = newSelected.concat(finalSelectedData.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        finalSelectedData.slice(0, selectedIndex),
        finalSelectedData.slice(selectedIndex + 1)
      )
    }
    setFinalSelectedData(newSelected)
  }
  return (
    <div>
      <PreviewHeader
        classes={classes}
        pageSize={pageSize}
        onButtonClick={setPageSize}
        showWorkflowFlyout={showWorkflowFlyout}
      />

      <div style={{ marginTop: '90px' }}>
        <Grid container>
          <Grid item xs={11}>
            <Typography
              style={{
                marginLeft: '40px',
                color: '#000',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
              }}
            >
              {' '}
              Showing {data.length} images
            </Typography>
            {pageSize === 12 ? (
              <>
                <Box display="flex" justifyContent="center" width="100%">
                  <Tabs
                    style={{ marginTop: '10px' }}
                    centered
                    variant="scrollable"
                    value={false}
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {data?.map((dat, i) => {
                      const isCardChecked =
                        (finalSelectedData || [])?.findIndex(
                          (obj) => obj?.id === dat?.id
                        ) !== -1
                      return (
                        <div>
                          <Checkbox
                            checked={isCardChecked}
                            onChange={(event) => {
                              onPreviewCheckChange(dat)
                            }}
                            id={dat?.id || ''}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <img
                            alt={dat.id}
                            style={{
                              height: '90px',
                              cursor: 'pointer',
                              width: '90px',
                              objectFit: 'contain',
                              marginRight: '12px',
                              border: `1px solid ${
                                activeImage.id === dat.id ? '#CC0000' : '#000'
                              }`,
                            }}
                            src={dat.previewImage}
                            onClick={() => {
                              setActiveImage(dat)
                            }}
                          />
                        </div>
                      )
                    })}
                  </Tabs>
                </Box>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  md={12}
                  style={{
                    height: '900px',
                    marginTop: '24px',
                    paddingTop: '10px',
                    borderTop: '1px solid #000',
                  }}
                >
                  <div
                    style={{ width: '80%', height: '100%', margin: '0 auto' }}
                  >
                    <ImageCard
                      title={`${activeImage.id}, ${activeImage.version}`}
                      imageSrc={activeImage.zoomImage}
                      zoomSrc={activeImage.zoomImage}
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <Grid
                wrap
                style={{ width: '100%', padding: '20px 40px' }}
                spacing={2}
                container
                className={classes.root}
              >
                {data?.map((dat, i) => {
                  const isCardChecked =
                    (finalSelectedData || [])?.findIndex(
                      (obj) => obj?.id === dat?.id
                    ) !== -1
                  return (
                    <Grid
                      key={dat?.id || ''}
                      container
                      item
                      lg={pageSize}
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                    >
                      <Checkbox
                        checked={isCardChecked}
                        onChange={(event) => {
                          onPreviewCheckChange(dat)
                        }}
                        id={dat?.id || ''}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <ImageCard
                        title={`${dat.id}, ${dat.version}`}
                        imageSrc={dat.zoomImage}
                        zoomSrc={dat.zoomImage}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )}
          </Grid>
          <Grid xs={1}>
            <WorkflowTable ref={workflowTableRef} tableTab={tableTab} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
