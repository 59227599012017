import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Grid, FormControl, Checkbox, Chip, TextField } from '@material-ui/core'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { toggleUploadManager } from '../../store/uploads/actionCreator'
import { useAuth } from '@praxis/component-auth'
import {
  uploadManagerAccess,
  ingestionUploadManagerAccess,
} from '../../constants/common'
import { isSafari } from '../../helpers/filter'
import { studioAndProcess } from '../../containers/Workflow/constants'
import { updateTopLevelStudio } from '../../store/Workflow/actionCreator'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import SudioFavLogo from '../../images/manageAccounts.svg'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DownloadManger from '../DownloadManger'
import { toggleDownloadManager } from '../../store/Downloads/actionCreator'

const useStylesLocal = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbarRoot: {
    height: '100% !important',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginLeft: 10,
    fontSize: 20,
    fontWeight: 500,
    color: '#000000',
  },
  logoAvatar: {
    width: '40px',
    maxHeight: '40px',
    padding: '0px 4px',
  },
  uploadButton: {
    marginRight: '20px',
    color: 'inherit',
  },
  uploadText: {
    marginLeft: '4px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
  },
  logoSize: {
    height: '50%',
  },
  bSeparator: {
    margin: 0,
  },
  studioListClass: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&>span': {
      marginLeft: '8px',
      color: '#666',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },
  },
}))

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const ButtonAppBar = ({
  isAuthorized = () => {},
  logout = () => {},
  fullName = '',
}) => {
  const dispatch = useDispatch()
  const classes = useStylesLocal()
  const breadcrumbs = useSelector((state) => state.layoutReducer.breadcrumbs)
  const [topStudioDropDown, setTopStudioDropDown] = React.useState(false)
  const [showStudioDropDown, setShowStudioDropDown] = React.useState(false)

  const { topStudioList, activeTab } = useSelector(
    ({ workflowReducer: { topStudioList, activeTab } }) => {
      return { topStudioList, activeTab }
    }
  )
  const location = useLocation()
  const auth = useAuth()
  const { session: { userInfo: { memberOf = [] } = {} } = {} } = auth
  const { pathname = '' } = location

  const handleChange = (event, newValue) => {
    dispatch(updateTopLevelStudio(newValue))
  }

  return (
    <>
      <AppBar
        position="fixed"
        style={{
          width: 'calc(100% - 64px)',
          height: pathname.includes('workflow') ? '84px' : '64px',
        }}
      >
        {isSafari() ? (
          <div
            style={{
              textAlign: 'center',
              color: '#CC0000',
              backgroundColor: '#FFCCCB',
              fontWeight: 'bold',
              fontSize: '21px',
              padding: '10px',
            }}
          >
            Chrome is Supported browser for Photobridge. Switch to{' '}
            <a href="https://www.google.com/chrome">Chrome</a> for better user
            experience.
          </div>
        ) : (
          <></>
        )}
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <Grid
            wrap={'nowrap'}
            container
            justify={'space-between'}
            alignItems={'center'}
          >
            <Grid
              wrap={'nowrap'}
              container
              item
              direction={'row'}
              justify={'flex-start'}
              alignItems={'center'}
              display={'flex'}
              flex={'auto'}
              xs={12}
              lg={12}
              md={12}
            >
              <Breadcrumbs
                separator={<NavigateNextIcon className={classes.title} />}
                classes={{
                  separator: classes.bSeparator,
                }}
                aria-label="breadcrumb"
              >
                {breadcrumbs.map((o, i) => {
                  return i !== breadcrumbs.length - 1 ? (
                    <Link
                      key={i}
                      color="inherit"
                      to={o.link}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography variant="h3" className={classes.title}>
                        {o.name}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography key={i} variant="h3" className={classes.title}>
                      {o.name}
                    </Typography>
                  )
                })}
              </Breadcrumbs>
              {pathname.includes('workflow') && activeTab !== 'SEARCH' ? (
                <FormControl
                  style={{
                    width: '400px',
                    height: '48px',
                    marginLeft: '24px',
                    zIndex: '99999',
                  }}
                  variant="outlined"
                >
                  {!showStudioDropDown && topStudioList.length ? (
                    <div className={classes.studioListClass}>
                      <SudioFavLogo />
                      <span>{topStudioList.join(', ')}</span>
                      <IconButton
                        edge="end"
                        style={{
                          padding: 0,
                        }}
                        onClick={() =>
                          setShowStudioDropDown(!showStudioDropDown)
                        }
                      >
                        <ArrowDropDownIcon fontSize="24" />
                      </IconButton>
                    </div>
                  ) : (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      disableClearable
                      limitTags={2}
                      open={topStudioDropDown}
                      onOpen={() => {
                        setTopStudioDropDown(!topStudioDropDown)
                      }}
                      onClose={() => {
                        setTopStudioDropDown(!topStudioDropDown)
                        setShowStudioDropDown(!showStudioDropDown)
                      }}
                      id="studio-filter"
                      onChange={(e, newValue) => handleChange(e, newValue)}
                      options={studioAndProcess.map((dat) => dat.val)}
                      getOptionLabel={(option) => {
                        return studioAndProcess.find(
                          (dat) => dat.val === option
                        ).label
                      }}
                      value={topStudioList || []}
                      renderOption={(option, { selected }) => {
                        return (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={topStudioList.indexOf(option) > -1}
                            />
                            {
                              studioAndProcess.find((dat) => dat.val === option)
                                .label
                            }
                          </React.Fragment>
                        )
                      }}
                      renderTags={(value, getTagProps) => {
                        const numTags = value.length
                        const limitTags = 2

                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                variant="outlined"
                                {...getTagProps({ index })}
                                key={index}
                                label={
                                  studioAndProcess.find(
                                    (dat) => dat.val === option
                                  ).label
                                }
                              />
                            ))}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Studio/Process"
                        />
                      )}
                    />
                  )}
                </FormControl>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid
            wrap={'nowrap'}
            container
            justify={'space-between'}
            alignItems={'center'}
            style={{ paddingLeft: '40px' }}
          >
            <Grid
              container
              item
              justify={'flex-end'}
              display={'flex'}
              xs={12}
              lg={8}
              md={8}
              alignItems={'center'}
            >
              {(pathname.includes('workflow') &&
                uploadManagerAccess.some((item) =>
                  memberOf.includes(item.toUpperCase())
                )) ||
              (pathname.includes('ingestion') &&
                ingestionUploadManagerAccess.some((item) =>
                  memberOf.includes(item.toUpperCase())
                )) ? (
                <IconButton
                  edge="end"
                  className={classes.uploadButton}
                  aria-label="upload"
                  onClick={() => dispatch(toggleUploadManager())}
                >
                  <CloudUploadOutlinedIcon fontSize="24" />
                  <span className={classes.uploadText}>Upload</span>
                </IconButton>
              ) : (
                <></>
              )}

              <IconButton
                edge="end"
                className={classes.uploadButton}
                aria-label="upload"
                onClick={() => dispatch(toggleDownloadManager())}
              >
                <CloudDownloadOutlinedIcon fontSize="24" />
                <span className={classes.uploadText}>Download</span>
              </IconButton>
            </Grid>
            <Grid
              container
              item
              justify={'flex-end'}
              xs={12}
              lg={4}
              md={4}
              alignItems={'center'}
            >
              {isAuthorized() && fullName}{' '}
              <IconButton
                edge="end"
                className={classes.menuButton}
                color="inherit"
                aria-label="logout"
                onClick={logout}
              >
                <ExitToAppIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <DownloadManger />
    </>
  )
}
export default ButtonAppBar
