import React from 'react'
import { findIndex, isEmpty } from 'lodash'
import { withAuth } from '@praxis/component-auth'
import { makeStyles } from '@material-ui/core/styles'
import ReactImageMagnify from 'react-image-magnify'
import NoThumbnail from '../../images/NoThumbnail.svg?url'
import AddToDriveIcon from '@mui/icons-material/AddToDrive'
import DoneIcon from '@mui/icons-material/Done'
import { GetApp } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  appendAHKeyParamToUrl,
  appendQueryParams,
} from '../../helpers/UrlHelper'
import { openZoomedImage } from '../../store/TcinInfo/actionCreator'
import { callRepurposeAHImages } from '../../store/Dashboard/actionCreator'
import { green } from '@material-ui/core/colors'
import { showNotification } from '../../store/Notification/actionCreator'

const PackagingAssetsList = (props) => {
  const classes = useStyles()
  const { data, accessToken = '', lanId = '' } = props
  const { packaging_info = {}, image_information = {} } = data || {}

  const { main: mainImageInfo = {}, alt: altImagesInfo = [] } =
    image_information || {}

  const { packaging_urls = [] } = packaging_info || {}
  const [repurposeList, setRepurposeList] = React.useState([])
  const [selectedData, setSelectedData] = React.useState([])
  const [failedFiles, setFailedFiles] = React.useState([])
  const [successFiles, setSuccessFiles] = React.useState([])

  const dispatch = useDispatch()

  const renderNoRecords = () => {
    return (
      <Typography variant="h5" gutterBottom>
        {'No Results Found'}
      </Typography>
    )
  }
  const renderFileName = (fileName = '', imgSrc = '') => {
    return (
      <Tooltip
        title={fileName}
        placement="top-start"
        disableHoverListener={fileName?.length <= 30 ? true : false}
      >
        <a
          data-cy="assetCardLink"
          alt={fileName}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (imgSrc) {
              dispatch(openZoomedImage(fileName, imgSrc))
            }
          }}
          className={classes.fileName}
          href={() => false}
        >
          {fileName}
        </a>
      </Tooltip>
    )
  }
  const onSelection = (event, asset) => {
    const selected = { ...asset }
    const { asset_id = '' } = selected
    const selectedIndex = findIndex(selectedData, function (obj = {}) {
      return obj.asset_id === asset_id
    })

    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedData, selected)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedData.slice(1))
    } else if (selectedIndex === selectedData.length - 1) {
      newSelected = newSelected.concat(selectedData.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      )
    }
    setSelectedData(newSelected)
  }
  const renderCardHeader = (asset = {}, imageFlag = '') => {
    const {
      preview_asset_url = '',
      file_name: fileName = '',
      asset_id = '',
    } = asset
    const srcUrl = preview_asset_url
      ? appendAHKeyParamToUrl(preview_asset_url)
      : ''
    const isCardChecked =
      (selectedData || [])?.findIndex((obj) => obj?.asset_id === asset_id) !==
      -1
    return (
      <Grid container alignItems={'baseline'}>
        <Grid item xs={2}>
          <Checkbox
            checked={isCardChecked}
            onChange={(event) => {
              onSelection(event, asset)
            }}
            id={asset_id}
            disabled={
              ['READYFORREPURPOSE', 'INWORKFLOW'].indexOf(imageFlag) === -1
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container direction={'column'}>
            <Grid item>{renderFileName(fileName, srcUrl)}</Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const callRepurposeAPICB = (resData = {}) => {
    const { failed_files = [] } = resData || {}
    const successList = (selectedData || [])?.filter(
      (asset) =>
        // failed_files?.findIndex(
        //   (obj) => obj?.file_name === asset?.file_name
        // ) === -1 //TODO - REQUIRED VALIDATION AFTER API CHANGES
        (failed_files || [])?.findIndex(
          (obj) => obj?.asset_id === asset?.asset_id
        ) === -1
    )
    const failedList = (selectedData || [])?.filter(
      (asset) =>
        // failed_files?.findIndex(
        //   (obj) => obj?.file_name === asset?.file_name
        // ) !== -1 //TODO - REQUIRED VALIDATION AFTER API CHANGES
        (failed_files || [])?.findIndex(
          (obj) => obj?.asset_id === asset?.asset_id
        ) !== -1
    )
    if (failed_files?.length > 0) {
      setFailedFiles(failedList || [])
    }

    if (successList?.length > 0) {
      setSuccessFiles(successList || [])
    }
    setSelectedData([])
    let notificationMsg = {}
    if (failed_files?.length > 0) {
      if (successList?.length > 0) {
        notificationMsg = {
          msg: 'Repurposing Partially Successful',
          type: 'error',
        }
      } else {
        notificationMsg = {
          msg: 'Repurposing Was Not Successful',
          type: 'error',
        }
      }
    } else if (successList?.length > 0) {
      notificationMsg = {
        msg: 'Repurposing Done Successfully',
        type: 'success',
      }
    }
    if (
      Object.entries(notificationMsg || {})?.length > 0 &&
      notificationMsg?.msg
    ) {
      dispatch(
        showNotification(
          true,
          notificationMsg?.msg,
          notificationMsg?.type,
          2000
        )
      )
    }
  }
  React.useEffect(() => {
    if (repurposeList?.length > 0) {
      callRepurposeAPI(repurposeList, callRepurposeAPICB)
    }
    return function cleanup() {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repurposeList])

  const onReuseClick = (asset = {}) => {
    const { asset_id = '', file_name = '' } = asset
    if (asset_id && file_name) {
      setRepurposeList([asset])
      setSelectedData([asset])
    }
  }
  const callRepurposeAPI = (assetList = [], callBack = () => {}) => {
    dispatch(callRepurposeAHImages({ assetList, callBack, accessToken, lanId }))
  }
  const renderImageFlagContent = (imageFlag) => {
    switch (imageFlag) {
      case 'PICKUP':
        return (
          <div className={classes.limitedUseTextDone}>
            <DoneIcon />
            {'REPURPOSED'}
          </div>
        )
      case 'READYFORREPURPOSE':
      case 'INWORKFLOW':
        return (
          <div className={classes.limitedUseText}>
            <AddToDriveIcon className={classes.useIcon} />
            {'REPURPOSE'}
          </div>
        )
      default:
        return <div className={classes.disabledButton}>{imageFlag}</div>
    }
  }

  const renderCardContent = (asset = {}, imageFlag = '') => {
    const {
      preview_asset_url = '',
      file_name: fileName = '',
      asset_id = '',
    } = asset
    const srcUrl = preview_asset_url
      ? appendAHKeyParamToUrl(preview_asset_url)
      : ''
    const downloadUrl = srcUrl
      ? appendQueryParams(srcUrl, { is_download: true })
      : ''
    return (
      <Grid container direction={'row'} spacing={2}>
        <Grid item xs={12} lg={12} md={12}>
          <ReactImageMagnify
            smallImage={{
              isFluidWidth: true,
              alt: fileName,
              src: srcUrl ? srcUrl : NoThumbnail,
              width: '100%',
              height: '285px !important',
            }}
            largeImage={{
              alt: fileName,
              src: srcUrl,
              width: 1000,
              height: 1000,
            }}
            enlargedImagePosition="over"
            imageClassName={classes.asset}
          />
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ borderTop: '1px solid #f4f0f0', padding: '0px 5px' }}
          >
            <Grid item>
              <Button
                data-id={`${'reuse_' + asset_id}`}
                onClick={() => {
                  onReuseClick(asset)
                }}
                className={classes.limitedUseButton}
                disabled={
                  ['READYFORREPURPOSE', 'INWORKFLOW'].indexOf(imageFlag) === -1
                }
                variant={
                  ['READYFORREPURPOSE', 'INWORKFLOW'].indexOf(imageFlag) === -1
                    ? 'contained'
                    : 'none'
                }
              >
                {renderImageFlagContent(imageFlag)}
              </Button>
            </Grid>
            <Grid item>
              <Button
                aria-haspopup="true"
                className={classes.dropdownButton}
                disabled={isEmpty(downloadUrl)}
              >
                <a
                  href={downloadUrl}
                  download
                  className={classes.dropdownButton}
                >
                  <GetApp />
                </a>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const getImageFlag = (cardType = '', imageData = {}) => {
    if (cardType === 'success') {
      return 'PICKUP'
    }

    const { metadata = {} } = imageData || {}
    const {
      multitcin: isMultiTcinImage = false,
      original: isOriginalImage = false,
      photo_studio: photoStudio = '',
    } = metadata || {}

    if (
      Object.entries(imageData).length > 0 &&
      Object.entries(metadata || {}).length > 0
    ) {
      if (isMultiTcinImage) {
        return isOriginalImage ? 'MULTITCIN' : 'REUSED'
      }

      if (photoStudio.toLowerCase() === 'pickup') {
        return 'PICKUP'
      }

      return 'INWORKFLOW'
    }

    return 'READYFORREPURPOSE'
  }
  const renderAssetCard = (asset = {}) => {
    const { file_name = '', asset_id = '' } = asset
    const errorFiles = failedFiles || []
    const cardClass =
      errorFiles?.findIndex(
        (obj) => obj?.file_name === file_name || obj?.asset_id === asset_id
      ) !== -1
        ? classes.errorCard
        : (successFiles || [])?.findIndex(
              (obj) =>
                obj?.file_name === file_name || obj?.asset_id === asset_id
            ) !== -1
          ? classes.successCard
          : classes.card
    const cardType =
      errorFiles?.findIndex(
        (obj) => obj?.file_name === file_name || obj?.asset_id === asset_id
      ) !== -1
        ? 'error'
        : (successFiles || [])?.findIndex(
              (obj) =>
                obj?.file_name === file_name || obj?.asset_id === asset_id
            ) !== -1
          ? 'success'
          : 'normal'
    const altImageData = (altImagesInfo || []).find(
      (img) => img.file_name === file_name || img.asset_id === asset_id
    )
    const mainImageData =
      (mainImageInfo || {})?.asset_id === asset_id ||
      (mainImageInfo || {})?.file_name === file_name
        ? mainImageInfo
        : {}
    const imageData =
      mainImageData && Object.entries(mainImageData || {})?.length > 0
        ? mainImageData
        : altImageData && Object.entries(altImageData || {})?.length > 0
          ? altImageData
          : {}
    const imageFlag = getImageFlag(cardType, imageData)
    return (
      <Card sx={{ maxWidth: 345 }} className={cardClass}>
        <CardActions style={{ borderBottom: '1px solid #f4f0f0' }}>
          {renderCardHeader(asset, imageFlag)}
        </CardActions>
        <>{renderCardContent(asset, imageFlag)}</>
      </Card>
    )
  }
  const renderAssetList = (assetsList = []) => {
    return assetsList?.length > 0
      ? (assetsList || [])?.map((asset) => {
          return (
            <Grid
              item
              style={{
                display: 'flex',
                gap: '20px',
                overflow: 'wrap',
                padding: '10px',
              }}
            >
              <div>{renderAssetCard(asset)}</div>
            </Grid>
          )
        })
      : ''
  }
  const renderAssets = () => {
    let packagingAssets = [...(packaging_urls || [])]
    if (failedFiles?.length > 0) {
      packagingAssets = packagingAssets?.filter((asset) => {
        return !(failedFiles || []).some((fasset) => {
          // return fasset?.asset_id === asset?.asset_id  // TODO - REQUIRED VALIDATION
          return fasset?.file_name === asset?.file_name
        })
      })
    }

    if (successFiles?.length > 0) {
      packagingAssets = packagingAssets?.filter((asset) => {
        return !(successFiles || []).some((fasset) => {
          // return fasset?.asset_id === asset?.asset_id  // TODO - REQUIRED VALIDATION
          return fasset?.file_name === asset?.file_name
        })
      })
    }

    return (
      <Grid
        container
        style={{
          maxHeight: 'calc(100vh - 175px)',
          overflowY: 'auto',
        }}
      >
        <Grid item container xs={12} justifyContent={'flex-end'}>
          <Button
            data-id={'reuseall'}
            onClick={() => {
              setRepurposeList(selectedData)
            }}
            variant={'contained'}
            color="primary"
            disabled={selectedData?.length === 0}
          >
            <div className={classes.limitedUseTextAll}>
              <AddToDriveIcon className={classes.useIcon}></AddToDriveIcon>
              {'REPURPOSE'}
            </div>
          </Button>
        </Grid>
        <Grid item container xs={12}>
          {failedFiles?.length > 0 ? renderAssetList(failedFiles) : ''}
          {successFiles?.length > 0 ? renderAssetList(successFiles) : ''}
          {renderAssetList(packagingAssets)}
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid
      container
      direction={'column'}
      style={{ padding: '10px' }}
      justifyContent={'flex-start'}
    >
      {packaging_urls?.length ? (
        <Grid item>{renderAssets()}</Grid>
      ) : (
        <Grid item>{renderNoRecords()}</Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  table: {
    width: '98%',
  },
  tableBody: {
    verticalAlign: 'top',
    textAlign: 'left',
  },
  header: {
    verticalAlign: 'top',
    textAlign: 'left',
  },
  container: {
    height: 'calc(100vh - 168px)',
    maxHeight: 'fit-content',
  },
  noSamples: {
    align: 'center',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
    color: 'gray',
    fontSize: '25px',
  },
  limitedUseButton: {
    width: '110px',
    height: '22px',
    fontSize: '12px',
    backgroundColor: '#FAFAFA',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  limitedUseText: {
    color: 'rgba(223, 139, 72, 1)',
    display: 'flex',
    alignItems: 'center',
  },
  disabledButton: {
    display: 'flex',
    alignItems: 'center',
  },
  limitedUseTextDone: {
    color: green[500],
    display: 'flex',
    alignItems: 'center',
  },
  limitedUseTextAll: {
    display: 'flex',
    alignItems: 'center',
  },
  useIcon: {
    width: '20px',
    height: '20px',
    paddingRight: '3px',
    paddingLeft: '1px',
  },
  asset: {
    height: '285px !important',
  },
  fileName: {
    paddingBottom: '4px',
    paddingTop: '4px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    hyphens: 'auto',
    fontSize: 14,
    color: '#0D46A0',
    lineHeight: '16px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
  },
  card: {
    width: '285px',
    height: '410px',
    borderRadius: '8px',
    position: 'relative',
    boxShadow: '#ececec 0px 0px 5px',
    border: '1px solid #f4f0f0',
    marginRight: 10,
  },
  errorCard: {
    width: '285px',
    height: '410px',
    borderRadius: '8px',
    position: 'relative',
    boxShadow: '#ececec 0px 0px 5px',
    border: '2px solid #B85300',
    marginRight: 10,
  },
  successCard: {
    width: '285px',
    height: '410px',
    borderRadius: '8px',
    position: 'relative',
    boxShadow: '#ececec 0px 0px 5px',
    border: '2px solid green',
    marginRight: 10,
  },
  dropdownButton: {
    textTransform: 'none!important',
    fontSize: '15px',
    color: 'black !important',
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  },
})
const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    company: auth.session?.userInfo?.company,
    memberOf: auth.session?.userInfo?.memberOf,
    lanId: auth.session?.userInfo?.lanId,
  }
}

export default withAuth(mapAuthToProps)(React.memo(PackagingAssetsList))
