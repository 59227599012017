import { pageSizes } from './constants'
import { Stack, Button, AppBar, Toolbar, Grid } from '@mui/material'
import AppLogo from '../../images/PhotoBridge_Logo_white-on-red.svg'

export default function PreviewHeader(props) {
  const {
    classes = {},
    onButtonClick = () => {},
    pageSize = '',
    showWorkflowFlyout = () => {},
  } = props
  return (
    <AppBar position="fixed" style={{ width: '100%', background: '#CC0000' }}>
      <Toolbar>
        <Grid
          wrap={'nowrap'}
          container
          justify={'space-between'}
          alignItems={'center'}
        >
          <Grid
            lg="6"
            wrap={'nowrap'}
            container
            item
            direction={'row'}
            alignItems={'center'}
          >
            <Grid lg={6} md={6}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AppLogo title="Application Logo" />
                <span
                  style={{
                    color: '#FFF',
                    fontFamily: 'Roboto',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    marginLeft: 24,
                  }}
                >
                  PhotoBridge - Advanced Preview
                </span>
              </div>
            </Grid>
            <Grid
              lg={6}
              md={6}
              style={{
                borderLeft: '2px solid #ffffff',
                borderRight: '2px solid #ffffff',
              }}
            >
              <Stack direction="row" justifyContent={'space-evenly'}>
                {pageSizes.map((dat, i) => {
                  return (
                    <Button
                      classes={{
                        root: classes.button,
                        outlined: classes.outlinedButton,
                        contained: classes.containedButton,
                      }}
                      variant={pageSize !== dat.size ? 'outlined' : 'contained'}
                      onClick={() => onButtonClick(dat.size)}
                    >
                      {dat.label}
                    </Button>
                  )
                })}
              </Stack>
            </Grid>
          </Grid>
          <Grid lg="6">
            <Button
              classes={{
                root: classes.button,
                outlined: classes.outlinedButton,
                contained: classes.containedButton,
              }}
              variant={'contained'}
              onClick={() => showWorkflowFlyout()}
            >
              {'Show Workflow'}
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
