import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { intersectionBy, toUpper } from 'lodash'
import { isDeepEmpty } from '../../helpers/filter'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import {
  adminADGroup,
  producerADGroup,
  retouchingLeadADGroup,
  retouchingQAADGroup,
} from '../../constants/common'

const WorkflowFlyoutContent = (props = {}) => {
  const {
    reusedSelection = [],
    imageWorkflow = {},
    handleImageStatusChange = () => {},
    MenuProps = {},
    imageStatusList = [],
    setImageWorkflow = () => {},
    assigneeInputValue = '',
    setAssigneeInputValue = () => {},
    qaAssigneeInputValue = '',
    setQaAssigneeInputValue = () => {},
    qaAsigneeList = [],
    isLoading = false,
    filteredReusedSelection = [],
    changeWorkflowStatus = () => {},
    unapprovedImages,
    tabWiseSelection,
    sendToPipeline = () => {},
    classes = {},
    asigneeList = [],
  } = props || {}

  const [curImageWorkflow, setCurImageWorkflow] = useState({})

  const { image_status = '', assignee = '', qa_assignee = '' } = imageWorkflow
  const auth = useAuth()
  const { session: { userInfo: { memberOf = [] } = {} } = {} } = auth

  const getAsigneeList = (list) => {
    return list?.reduce((acc, user) => {
      const { display_name } = user
      return [...acc, { label: display_name, ...user }]
    }, [])
  }

  useEffect(() => {
    if (imageWorkflow) {
      setCurImageWorkflow(imageWorkflow)
    }
  }, [imageWorkflow])

  const reusedWarning = (size = 9) => {
    return reusedSelection?.length ? (
      <Grid item xs={12} lg={size} md={size}>
        {filteredReusedSelection?.length ? (
          <Alert severity="warning">
            Any changes made to the Multi TCIN image will be applied to all
            related reused images.
          </Alert>
        ) : (
          <Alert severity="warning">
            Please select some original images to do operations.
          </Alert>
        )}
      </Grid>
    ) : (
      <></>
    )
  }

  return (
    <>
      <Divider className={classes.drawerHeaderDivider} />
      {reusedWarning()}
      <Grid container className={classes.workflowDrawerContainer}>
        <Grid item xs={9} lg={9} md={9}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="image-status-label">
              Change Image Status To
            </InputLabel>
            <Select
              labelId="image-status-label"
              value={image_status}
              onChange={(event) => {
                handleImageStatusChange(event)
              }}
              input={<OutlinedInput label="Change Image Status To" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(selected || []).map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {(imageStatusList || [])
                ?.filter((data) => data !== 'Sent to Pipeline')
                ?.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {intersectionBy(
        memberOf,
        [producerADGroup, retouchingLeadADGroup, adminADGroup],
        toUpper
      )?.length > 0 ? (
        <Grid container className={classes.workflowDrawerContainer}>
          <Grid item xs={9} lg={9} md={9}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                value={assignee}
                onChange={(event, newValue) => {
                  setImageWorkflow((prev) => ({
                    ...prev,
                    assignee: newValue ? newValue : '',
                  }))
                }}
                getOptionLabel={(option) => option?.label || ''}
                inputValue={assigneeInputValue}
                onInputChange={(event, newInputValue) => {
                  setAssigneeInputValue(newInputValue)
                }}
                id="assignee-list"
                options={[
                  {
                    label: 'Remove Assignee',
                    display_name: 'Remove Assignee',
                  },
                  ...getAsigneeList(asigneeList),
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Change Assignee To"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {intersectionBy(
        memberOf,
        [retouchingLeadADGroup, adminADGroup, retouchingQAADGroup],
        toUpper
      )?.length > 0 ? (
        <Grid container className={classes.workflowDrawerContainer}>
          <Grid item xs={9} lg={9} md={9}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                value={qa_assignee}
                onChange={(event, newValue) => {
                  setImageWorkflow((prev) => ({
                    ...prev,
                    qa_assignee: newValue ? newValue : '',
                  }))
                }}
                getOptionLabel={(option) => option?.label || ''}
                inputValue={qaAssigneeInputValue}
                onInputChange={(event, newInputValue) => {
                  setQaAssigneeInputValue(newInputValue)
                }}
                id="assignee-list"
                options={[
                  {
                    label: 'Remove QA Assignee',
                    display_name: 'Remove QA Assignee',
                  },
                  ...getAsigneeList(qaAsigneeList),
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Change QA Assignee To"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid container>
        <Grid item xs={9} lg={9} md={9}>
          <Stack direction="row" className={classes.mwButtonGroup} spacing={2}>
            <Button
              variant="outlined"
              className={classes.stausButton}
              onClick={() => {
                setImageWorkflow((prev) => ({
                  ...prev,
                  image_status: '',
                  assignee: '',
                  qa_assignee: '',
                }))
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={
                isLoading ||
                isDeepEmpty(imageWorkflow) ||
                !filteredReusedSelection?.length
              }
              onClick={changeWorkflowStatus}
              className={`${classes.stausButton} ${classes.primaryButton}`}
            >
              {isLoading ? <CircularProgress size={14} /> : 'Apply'}
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {imageStatusList.includes('Sent to Pipeline') ? (
        <>
          {' '}
          <Divider />
          <Grid container direction="column">
            <Grid>
              <Typography classes={{ root: classes.dialogMessage1 }}>
                {unapprovedImages
                  ? `${tabWiseSelection?.length} images selected, ${unapprovedImages} not approved`
                  : `${tabWiseSelection.length} approved images selected `}
              </Typography>
              <Typography classes={{ root: classes.dialogMessage2 }}>
                Send to pipeline cannot be undone, images should be approved for
                publishing to Target.com
              </Typography>
            </Grid>
            <Grid
              style={{
                margin: '24px 0',
              }}
            >
              <Button
                variant="contained"
                disabled={
                  unapprovedImages ||
                  isLoading ||
                  !filteredReusedSelection?.length
                }
                onClick={sendToPipeline}
                className={`${classes.primaryButton} ${classes.pipeLineButton}`}
              >
                {isLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  'SEND TO PIPELINE'
                )}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default WorkflowFlyoutContent
