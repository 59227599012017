/* eslint-disable multiline-ternary */
import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { groupBy, pickBy, get } from 'lodash'
import { formatDate, toSafeData, prefixSampleId } from './mapper'
import defaultThumbnail from '../../images/defaultThumbnail.jpg'
import {
  openZoomedImage,
  setSelected,
  setDataFiltered,
} from '../../store/TcinInfo/actionCreator'
import { itemType } from './constants'
import ExpandDown from '../../images/expand_arrow_down_24dp.svg'
import ExpandDownOutline from '../../images/expand_arrow_down_outline_24dp.svg'
import ExpandRight from '../../images/expand_arrow_right_24dp.svg'
import ExpandCircle from '../../images/expand_circle_24dp.svg'
import ImageNA from '../../images/ImageNA.svg'
import { MultiTcinReusedFlyout } from '../../components/MultiTcinReusedFlyout'

const useStyles = makeStyles((theme) => ({
  tableBody: {
    position: 'sticky',
    backgroundColor: 'white',
    padding: '8px 16px',
    verticalAlign: 'top',
    textAlign: 'left',
  },
  tableLeftFix: {
    zIndex: 1,
    left: 74,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  tableLeft2Fix: {
    zIndex: 1,
    left: 207,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  checkboxLeftFix: {
    zIndex: 1,
    left: 75,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  tableRightFix: {
    zIndex: 1,
    right: 0,
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
}))

const useStylesIndent = makeStyles((theme) => ({
  indentation: {
    paddingLeft: (props) => 24 * props.level,
  },
  borderRow: {
    borderTop: (props) =>
      props.level === 0 ? '2px solid #B6B6B6' : '2px solid #EDEDED',
    borderBottom: '1px solid #EDEDED',
  },
  // background: {
  //   backgroundColor: (props) =>
  //     props.level === 0 ? '#B6B6B6' : '#EDEDED',
  //   borderTopStyle: (props) => props.level === 0 && 'inset',
  // },
}))

function DataRow(props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    index,
    rowData,
    rowsPerPage,
    colMap,
    page,
    nestedData,
    layout,
    cellElement,
    handleDialogOpen,
  } = props
  const classesIndent = useStylesIndent(rowData)

  const selected = useSelector((state) => state.tcinPhotoInfoReducer.selected)

  const isSelected = (row) => selected.indexOf(row) !== -1

  const onTableRowClick = (event, row) => {
    const selectedRow = selected.indexOf(row)
    let newSelected = []

    if (selectedRow === -1) {
      newSelected = newSelected.concat(selected, row)
    } else if (selectedRow === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedRow === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedRow > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedRow),
        selected.slice(selectedRow + 1)
      )
    }
    dispatch(setSelected(newSelected))
  }

  let row = rowData.node.info
  const isItemSelected = isSelected(rowData.node)
  const labelId = `table-checkbox-${page * rowsPerPage + index}`

  const icon = (node) => {
    let isAllChildrenPresent = node.childrenData.length === node.children.length
    // let isAllParentPresent = node.parents.length === node.parentsData.length
    if (node.childrenData.length === 0) {
      return <ExpandCircle title="stand-alone" style={{ marginRight: 4 }} />
    } else if (isAllChildrenPresent) {
      return <ExpandDown title="expand-down" style={{ marginRight: 4 }} />
    } else if (node.children.length === 0) {
      return <ExpandRight title="expand-right" style={{ marginRight: 4 }} />
    } else {
      return (
        <ExpandDownOutline
          title="expand-downoutline"
          style={{ marginRight: 4 }}
        />
      )
    }
  }

  const sourceImageObject = colMap.reduce((acc, cur) => {
    if (cur.groupId === 'alt_source_image' || cur.colId === 'source_image') {
      acc[cur.groupId || cur.parentId] = cur.selected
    }
    return acc
  }, {})

  return (
    <React.Fragment>
      <TableRow
        hover
        // onClick={(event) => onTableRowClick(event, row)}
        id={`${rowData.node.tcin}-${rowData.level}`}
        // className={classesIndent.borderRow}
        key={page * rowsPerPage + index}
        selected={isItemSelected}
      >
        <TableCell
          rowSpan={
            layout === 'nested' && row.image_information !== null
              ? row.image_information.alt?.length + 1
              : undefined
          }
          // padding="checkbox"
          style={{ left: 0, paddingTop: 0 }}
          className={clsx(
            classes.tableBody,
            classes.checkboxLeftFix,
            classesIndent.borderRow
          )}
        >
          <Checkbox
            onClick={(event) => onTableRowClick(event, rowData.node)}
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        {colMap
          .filter(
            (o) =>
              o.colId !== undefined &&
              (layout === 'nested' ? o.parentId === undefined : true)
          )
          .map((x, i) => {
            return (
              x.selected && (
                <TableCell
                  key={i}
                  // width={'10%'}
                  rowSpan={
                    layout === 'nested'
                      ? x.layout === 'nested'
                        ? x.groupId === 'source_image' &&
                          !sourceImageObject.alt_source_image
                          ? row.image_information.alt?.length + 1
                          : 1
                        : row.image_information !== null
                          ? row.image_information.alt?.length + 1
                          : 1
                      : undefined
                  }
                  className={clsx(
                    // classesIndent.background,
                    classes.tableBody,
                    classesIndent.borderRow,
                    i === 0 && classes.tableLeftFix,
                    // i === 1 && classes.tableLeft2Fix,
                    i === 0 && classesIndent.indentation
                  )}
                >
                  {i === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {icon(rowData.node)}
                      {cellElement(row, x, page * rowsPerPage + index)}
                    </div>
                  ) : (
                    cellElement(row, x, page * rowsPerPage + index)
                  )}
                </TableCell>
              )
            )
          })}
        <TableCell
          rowSpan={
            layout === 'nested' && row.image_information !== null
              ? row.image_information.alt?.length + 1
              : undefined
          }
          className={clsx(
            // classesIndent.background,
            classesIndent.borderRow,
            classes.tableBody,
            classes.tableRightFix
          )}
        >
          <IconButton
            aria-label="more-info"
            onClick={(e) =>
              handleDialogOpen(e, page * rowsPerPage + index, 'photo')
            }
          >
            <MoreHorizIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {layout === 'nested' &&
        row.image_information !== null &&
        nestedData(row.image_information.alt?.length, row)}
      {/* <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
  <CircularProgress color="inherit" />
</Backdrop> */}
    </React.Fragment>
  )
}

export default function DataTableBody(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [list, setList] = React.useState([])

  const isFiltersApplied = useSelector(
    (state) =>
      Object.keys(
        pickBy(
          state.tcinPhotoInfoReducer.filters,
          (f) => f.userSelected.length > 0
        )
      ).length > 0
  )

  const filteredTCINS = useSelector(
    (state) => state.tcinPhotoInfoReducer.filteredTCINS
  )
  const dataFiltered = useSelector(
    (state) => state.tcinPhotoInfoReducer.dataFiltered
  )
  const treeList = useSelector((state) => state.tcinPhotoInfoReducer.treeList)

  const {
    rowsPerPage,
    colMap,
    page,
    layout,
    handleDialogOpen,
    handleChangePage,
    setImageIndex,
  } = props

  const nestedData = (count, data) => {
    let nestedRows = []
    let altColMap = groupBy(
      colMap.filter((e) => e.layout && e.parentId),
      'id'
    )
    for (let i = 0; i < count; i++) {
      nestedRows.push(
        <TableRow key={'alt_' + i}>
          {altColMap[i] &&
            altColMap[i].map((e, index) => {
              return (
                e.selected && (
                  <TableCell key={i + index} className={classes.tableBody}>
                    {cellElement(data, e)}
                  </TableCell>
                )
              )
            })}
        </TableRow>
      )
    }
    return nestedRows
  }

  const cellElement = (data, colData, index) => {
    switch (colData.dataType) {
      case 'date':
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatDate(toSafeData(data, colData.data))}
          </span>
        )
      case 'merge': {
        let name = colData.data.split('_').slice(0, -1).join('_')
        return (
          toSafeData(data, colData.data, '') +
          ' - ' +
          toSafeData(data, name + '_name', '')
        )
      }
      case 'image_count':
        var main_count =
          toSafeData(data, colData.data + '.main.file_name', null) === null
            ? 0
            : 1
        var alt_count = toSafeData(
          data,
          colData.data + '.alt_images_count',
          null
        )
        var count = main_count + alt_count
        return count === null ? '-' : count
      case 'image': {
        const metaData = toSafeData(data, colData.metaData, null)
        const tcin = toSafeData(data, colData.fileName, '-')
        const imgUrl = toSafeData(data, colData.data[0], null)
        const isPickupImage =
          (metaData?.photo_studio || '')?.toLowerCase() === 'pickup'
        return (
          <div
            style={{
              position: 'relative',
              height: '72px',
              width: '72px',
            }}
          >
            {imgUrl ? (
              <img
                onClick={() => {
                  colData.colName.toLowerCase().includes('final') &&
                    setImageIndex(index)
                  toSafeData(data, colData.data[1], null)
                    ? dispatch(
                        openZoomedImage(
                          data.tcin + ' - ' + colData.colName,
                          toSafeData(data, colData.data[1], ' - ')
                        )
                      )
                    : dispatch(
                        openZoomedImage(
                          data.tcin + ' - ' + colData.colName,
                          toSafeData(data, colData.data[0], ' - ')
                        )
                      )
                }}
                style={{ height: 72, cursor: 'pointer' }}
                src={toSafeData(data, colData.data[0], ' - ')}
                alt="-"
                onError={(e) => {
                  e.target.src = defaultThumbnail
                }}
              />
            ) : (
              <ImageNA
                title="Image Not Available"
                style={{ width: '72px', height: '72px' }}
              />
            )}
            {metaData?.multitcin || isPickupImage ? (
              <MultiTcinReusedFlyout
                styles={{
                  position: 'absolute',
                  top: '52px',
                  right: '0',
                  background: '#666666',
                  color: '#ffffff',
                  width: '72px',
                  height: '20px',
                  borderRadius: 0,
                }}
                isPickupImage={isPickupImage}
                tcin={tcin}
                subjectCodes={get(metaData, 'subject_codes')}
                originalImage={get(metaData, 'original_image')}
              />
            ) : (
              <></>
            )}
          </div>
        )
      }
      case 'array': {
        let parent = colData.data.split('[0]')[0]
        let val = toSafeData(data, parent, null)
        return val !== null && val.length > 0 ? (
          <React.Fragment>
            <span>
              {colData.colId === 'sample_ids' || colData.order === 15
                ? prefixSampleId(toSafeData(data, colData.data, ' - '))
                : toSafeData(data, colData.data, ' - ')}
            </span>
            {val.length - 1 > 0 && (
              <Button
                color="primary"
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '0.750rem',
                  textAlign: 'left',
                }}
                onClick={(e) =>
                  props.handleDialogOpen(e, index, colData.category)
                }
              >
                {val.length - 1} More
              </Button>
            )}
          </React.Fragment>
        ) : (
          ' - '
        )
      }
      case 'project_array': {
        let val = toSafeData(data, colData.data, [])
        let projectList = ''
        for (let index = 0; index < val.length; index++) {
          if (index === 0) {
            projectList = projectList + val[index].project_name
          } else {
            projectList = projectList + ', ' + val[index].project_name
          }
        }
        return val !== null && val.length > 0 ? (
          <React.Fragment>
            <span>{projectList}</span>
          </React.Fragment>
        ) : (
          ' - '
        )
      }
      case 'image_array': {
        var list = toSafeData(data, colData.data, [])
        return list !== null && list.length > 0 ? (
          <div style={{ display: 'inline-flex' }}>
            {list.map((url, i) => (
              <img
                key={i}
                onClick={() =>
                  dispatch(
                    openZoomedImage(data.tcin + ' - ' + colData.colName, url)
                  )
                }
                style={{ height: 72, cursor: 'pointer' }}
                src={url}
                alt="-"
                onError={(e) => {
                  e.target.src = defaultThumbnail
                }}
              />
            ))}
          </div>
        ) : (
          <ImageNA title="Image Not Available" style={{ width: '72px' }} />
        )
      }
      default:
        return colData.groupId === 'relationship_type'
          ? itemType[toSafeData(data, colData.data, ' - ')]
          : toSafeData(data, colData.data, ' - ')
    }
  }

  const updateDataFiltered = (list) => {
    props.setProgressExpand(false)
    dispatch(setDataFiltered(list))
  }

  React.useEffect(() => {
    if (isFiltersApplied) {
      let data = treeList.filter((o) => {
        return filteredTCINS.includes(o.node.tcin)
      })
      props.setProgressExpand(false)
      dispatch(setDataFiltered(data))
    } else {
      updateDataFiltered(treeList)
    }
    handleChangePage(0)
    dispatch(setSelected([]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTCINS, treeList])

  return (
    <TableBody>
      {(rowsPerPage > 0
        ? dataFiltered.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : dataFiltered
      ).map((rowData, index) => {
        return (
          <DataRow
            key={index}
            index={index}
            rowData={rowData}
            rowsPerPage={rowsPerPage}
            colMap={colMap}
            page={page}
            nestedData={nestedData}
            layout={layout}
            cellElement={cellElement}
            handleDialogOpen={handleDialogOpen}
          />
        )
      })}
    </TableBody>
  )
}
