import React, { useEffect, startTransition } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Typography, Button, IconButton, Chip } from '@material-ui/core'
import { formatDate } from '../../helpers/dateHelper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Drawer from '@material-ui/core/Drawer'
import { setBreadcrumbs } from '../../store/Layout/actionCreator'
import { useParams, useLocation } from 'react-router-dom'
import { orderBy } from 'lodash'
import EditProject from './EditProject'
import CustomTable from '../../components/CustomTable'
import CustomTabs from '../../components/CustomTabs'
import {
  customizeColumns,
  tcinHeaders,
  generateDefaultColumns,
  projectTabLabels as projectTabLabelsList,
} from './constants'
import TableToolbar from './TableToolbar'
import TabData from './TabData'
import EditIcon from '@material-ui/icons/Edit'
import cloneDeep from 'lodash/cloneDeep'
import apiConfig from '../../apiConfig'
import {
  getProjectInfo,
  getProjectPackagingInfo,
  getCustomColumnsList,
  updateCustomColumnsList,
  archiveProjects,
} from '../../store/Dashboard/actionCreator'
import { toSafeData } from '../../containers/TcinInfo/mapper'
import { withAuth } from '@praxis/component-auth'
import { producerADGroup } from '../../constants/common'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    padding: '16px 24px',
    alignItems: 'center',
    gap: 16,
  },
  buttons: {
    height: '36px',
    minWidth: '110px',
  },
  editButton: {
    color: '#FFFFFF',
    backgroundColor: '#366CD9',
    '&:hover': {
      backgroundColor: '#1976d2',
    },
  },
  tagsButton: {
    padding: '0 !important',
    color: '#366CD9',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    '&:hover': {
      outline: 'none',
      background: 'none !important',
    },
  },
  ownerDetails: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
  },
  itemName: {
    color: '#666666',
  },
  itemDetails: {
    color: '#333333',
    fontWeight: '500',
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '23px',
    flex: '1',
    color: '#333333',
    margin: '0',
  },
  closeIcon: {
    padding: '0 !important',
    marginRight: '-9px !important',
  },
  attrDiv: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  attrHead: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: '14.06px',
  },
  attrValue: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '18.75px',
  },
  drawerHeaderDivider: {
    marginTop: '24px',
    width: `calc(100% + 24px)`,
    marginLeft: '0px',
    '&:before': {
      content: "''",
      width: '24px',
      height: '1px',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.12)',
      marginLeft: '-24px',
    },
  },
  drawerContainer: {
    padding: '40px 0',
  },
  imgInfo: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#EDEDED',
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
  drawer: {
    width: '80%',
    maxWidth: '1200px',
    padding: 24,
  },
  tabDrawer: {
    minWidth: 1100,
    width: '75%',
    overflowY: 'unset',
  },
}))

const ProjectDetail = React.memo((props) => {
  let { project_id: urlProjectId = '' } = useParams()
  const { state: locationState = {}, search: locationSearch = '' } =
    useLocation()
  const { totalTcin = '' } = locationState || {}
  const dispatch = useDispatch()
  const { pathname: curPagePathName = '' } = props
  const urlParams = new URLSearchParams(locationSearch)
  const packagingFlag = urlParams.get('packaging')
  const projectTabLabels = packagingFlag
    ? projectTabLabelsList
    : projectTabLabelsList?.filter((obj) => obj?.value !== 'packaging')
  const projectDataReducer = useSelector(
    (state) => state.dashboardReducer.projectDataReducer
  )
  const {
    tcinInfo,
    projectName,
    projectId,
    projectLoading,
    isArchived,
    loginId,
    projectUsers,
    customColumnsList,
  } = useSelector(
    ({
      dashboardReducer: {
        projectDataReducer: {
          tcin_info = [],
          project_name = '',
          project_id = '',
          archived: isArchived = true,
          created_by: { login_id = '' } = {},
          users: projectUsers = [],
        } = {},
        projectLoading = {},
        customColumnsList = [],
      },
    }) => {
      return {
        tcinInfo: tcin_info,
        projectName: project_name,
        projectId: project_id,
        projectLoading,
        isArchived,
        loginId: login_id,
        projectUsers,
        customColumnsList,
      }
    },
    shallowEqual
  )

  const classes = useStyles()
  const [projectData, setProjectData] = React.useState({})
  const [columns, setColumns] = React.useState([])
  const [tabData, setTabData] = React.useState(false)
  const [tabIndex, setTabIndex] = React.useState({
    category: 'item',
    rowIndex: 0,
  })
  const [tabContent, setTabContent] = React.useState([])
  const [userCustomizedColumns, setUserCustomizedColumns] = React.useState()
  const [viewType, setViewType] = React.useState('Userbased')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(totalTcin || 50)
  const [openDrawer, setOpenDrawer] = React.useState('')
  const [totalCount, setTotalCount] = React.useState(0)
  const [isEditDisabled, setIsEditDisabled] = React.useState(true)
  const [currentTabValue, setCurrentTabValue] = React.useState('default')

  const { accessToken = '', userId = '', memberOf: userADGroups = [] } = props

  /**
   * Call the getProjectInfo API to get the project details
   * Call the getUserCustomColumns API to get the user's customized columns
   */
  useEffect(() => {
    getProjectInfoCall()
    if (userId) {
      dispatch(getCustomColumnsList({ userId, accessToken }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPagePathName, currentTabValue])

  /**
   * Set the project data in the state
   * Set the columns for the table
   * Set the page number
   * Set the total count of the tcins
   * Set the isEditDisabled flag based on the user permissions
   * Set the project tabs content
   * Set the breadcrumbs
   */
  useEffect(() => {
    if (projectDataReducer && Object.entries(projectDataReducer).length > 0) {
      const { customPath = '', pathname = '' } = props
      const columnList = tcinHeaders(
        toSafeData(projectDataReducer, 'max_alt_count', 0)
      )
      const curTabColList = columnList?.filter(
        (obj) => obj.tabs.indexOf(currentTabValue) !== -1
      )
      const pagePathName = customPath?.replace(
        ':project_name',
        projectName ?? ''
      )
      dispatch(setBreadcrumbs(pagePathName, pathname))
      setProjectData(projectDataReducer)
      setColumns(curTabColList)
      setPage(toSafeData(projectDataReducer, 'tcin_pagination_details.page', 0))
      setTotalCount(
        toSafeData(projectDataReducer, 'tcin_pagination_details.total_count', 0)
      )
      setIsEditDisabled(
        !(
          !isArchived &&
          userADGroups.includes(producerADGroup.toUpperCase()) &&
          (userId?.toLowerCase() === loginId?.toLowerCase() ||
            projectUsers
              ?.map((user) => user?.login_id?.toLowerCase())
              .indexOf(userId?.toLowerCase()) > -1)
        )
      )
      setTabContent(projectDataReducer?.tcin_info || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDataReducer])

  useEffect(() => {
    if (customColumnsList?.length > 0 && currentTabValue !== 'packaging') {
      applyUserView(customColumnsList || [])
    } else {
      handleNoUserView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customColumnsList])

  const getProjectInfoCall = (page = 0, numberOfRows = rowsPerPage) => {
    startTransition(() => {
      setRowsPerPage(numberOfRows)
      if (currentTabValue === 'packaging') {
        callGetProjectPackagingInfo(page, numberOfRows)
      } else {
        callGetProjectInfo(page, numberOfRows)
      }
    })
  }

  /**
   * Call the getProjectInfo API to get the project details
   */
  const callGetProjectInfo = (page = 0, numberOfRows = rowsPerPage) => {
    dispatch(
      getProjectInfo({
        page,
        numberOfRows: numberOfRows,
        projectId: urlProjectId,
        accessToken,
        lanId: userId,
      })
    )
  }

  /**
   * Call the getProjectPackagingInfo API to get the project packaging details
   */
  const callGetProjectPackagingInfo = (
    page = 0,
    numberOfRows = rowsPerPage
  ) => {
    dispatch(
      getProjectPackagingInfo({
        page,
        numberOfRows,
        projectId: urlProjectId,
        accessToken,
        lanId: userId,
      })
    )
  }

  const applyUserView = (userColumnList = []) => {
    if (currentTabValue === 'packaging') {
      userColumnList = [
        ...userColumnList,
        'packaging_img',
        'packaging_assets_count',
      ]
    }
    let userCustomizedColumns = { ...(customizeColumns || {}) }
    // Set the selected property to false for each column
    Object.keys(userCustomizedColumns).forEach((column) => {
      userCustomizedColumns[column].selected = false
    })
    // Set the selected property to true for user selected columns
    userColumnList?.forEach((userColumn) => {
      if (userCustomizedColumns[userColumn]) {
        userCustomizedColumns[userColumn].selected = true
      } else {
        userCustomizedColumns[userColumn] = { selected: true }
      }
    })
    setUserCustomizedColumns({ ...userCustomizedColumns })
  }

  const handleNoUserView = () => {
    setUserCustomizedColumns(generateDefaultColumns({ currentTabValue }))
  }

  const updateUserView = (newUserView) => {
    const columnList = Array.from(getNewColumns(newUserView))
    dispatch(updateCustomColumnsList({ userId, accessToken, columnList }))
  }

  const getNewColumns = (userView) => {
    const columnList = new Set()
    for (const [key, value] of Object.entries(userView)) {
      value.selected && columnList.add(key)
    }
    return columnList
  }

  const renderDrawer = (drawer) => {
    switch (drawer) {
      case 'Edit Project':
        return (
          <>
            <Divider className={classes.drawerHeaderDivider} />
            <EditProject
              projectData={projectData}
              id={urlProjectId}
              closeDialog={handleEditClose}
              isEditDisabled={isEditDisabled}
            />
          </>
        )
      case 'Project Info':
        return (
          <>
            <Divider className={classes.drawerHeaderDivider} />
            <Grid container className={classes.drawerContainer}>
              <Grid
                wrap={'nowrap'}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Typography variant="body1" className={classes.ownerDetails}>
                  {`${projectData.project_name} created by ${
                    projectData.created_by && projectData.created_by.first_name
                  } ${
                    projectData.created_by && projectData.created_by.last_name
                  }  on ${formatDate(projectData.created_date)}`}
                </Typography>
                <div>
                  {userADGroups?.includes(producerADGroup.toUpperCase()) &
                  !apiConfig.isPol ? (
                    <React.Fragment>
                      {userId?.toLowerCase() ===
                      projectData.created_by?.login_id?.toLowerCase() ? (
                        <Button
                          className={classes.buttons}
                          variant="outlined"
                          onClick={() =>
                            makeProjectsArchive(!projectData.archived)
                          }
                          startIcon={<DeleteOutlineIcon />}
                          style={{ marginRight: '24px' }}
                          disabled={
                            userId?.toLowerCase() !==
                            projectData.created_by?.login_id?.toLowerCase()
                          }
                        >
                          {projectData.archived ? 'Undo Archive' : 'Archive'}
                        </Button>
                      ) : (
                        <></>
                      )}

                      {!isEditDisabled && (
                        <Button
                          variant="contained"
                          className={`${classes.buttons} ${classes.editButton}`}
                          onClick={() => setOpenDrawer('Edit Project')}
                          disabled={isEditDisabled}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      )}
                    </React.Fragment>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Project Name:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.project_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    PID:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.sap_pid || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Project Type:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.project_type || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Estimated #shots per TCIN:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.shot_count || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Estimated Completion Date:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.due_date
                      ? formatDate(projectData?.due_date)
                      : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Asset Due Date:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemDetails}`}
                  >
                    {projectData?.asset_due_date
                      ? formatDate(projectData?.asset_due_date)
                      : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Owners:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <div className={classes.ownerDetails}>
                    {projectData?.users
                      ? projectData?.users.map((option) => {
                          return (
                            <Chip
                              style={{ marginRight: '8px' }}
                              label={option.display_name}
                            />
                          )
                        })
                      : 'N/A'}
                  </div>
                </Grid>
              </Grid>
              <Grid
                wrap={'nowrap'}
                style={{ marginTop: '40px' }}
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Grid item lg={4}>
                  <Typography
                    variant="body1"
                    className={`${classes.ownerDetails} ${classes.itemName}`}
                  >
                    Followers:
                  </Typography>
                </Grid>
                <Grid item lg={8}>
                  <div className={classes.ownerDetails}>
                    {projectData?.viewers
                      ? projectData?.viewers.map((option) => {
                          return (
                            <Chip
                              style={{ marginRight: '8px' }}
                              label={option.display_name}
                            />
                          )
                        })
                      : 'N/A'}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
        )

      default:
        break
    }
  }

  const makeProjectsArchive = (makeArchive) => {
    const params = {
      project_ids: [urlProjectId],
      archive: makeArchive,
    }
    dispatch(
      archiveProjects({ accessToken, params, callBack: getProjectInfoCall })
    ) //TODO: Implement this & test
  }

  const handleEditClose = (isSaved) => {
    if (isSaved) {
      getProjectInfoCall()
    }
    setOpenDrawer('')
  }

  const handleCloseTabData = () => {
    setTabData(false)
    if (currentTabValue === 'packaging') {
      getProjectInfoCall(page, rowsPerPage)
    }
  }
  const handleOpenTabData = (e, index, category) => {
    setTabData(true)
    setTabIndex((state) => ({ ...state, category: category, rowIndex: index }))
  }

  const onOrderChange = (data, orderByName, order) => {
    setTabContent(orderBy(data, orderByName, order))
  }

  const renderProjectDetailsTable = () => {
    return (
      <CustomTable
        tableName={'projects'}
        uniqueIdName={'tcin'}
        isLoading={projectLoading}
        page={page}
        rowsPerPage={rowsPerPage}
        totalCount={totalCount}
        columns={columns}
        checkBox
        customizeColumns={
          viewType === 'Userbased'
            ? userCustomizedColumns || []
            : generateDefaultColumns({ currentTabValue })
        }
        userColumns={userCustomizedColumns || []}
        isArchived={
          projectData.archived ||
          userId?.toLowerCase() !==
            projectData.created_by?.login_id?.toLowerCase()
        }
        rows={tcinInfo}
        moreInfoHandler={handleOpenTabData}
        onRowOrderChange={onOrderChange}
        Toolbar={
          <TableToolbar
            isRemoveDisabled={isEditDisabled}
            setTabContent={setTabContent}
            getProjectInfo={getProjectInfoCall}
            isEnableCustomColumns={currentTabValue !== 'packaging'}
          />
        }
        getProjectInfo={getProjectInfoCall}
        projectId={projectId}
        isProducer={userADGroups?.includes(producerADGroup.toUpperCase())}
        viewType={viewType}
        setViewType={setViewType}
        updateUserView={updateUserView}
      />
    )
  }

  const renderTabsContent = () => {
    switch (currentTabValue) {
      case 'packaging': {
        return renderProjectDetailsTable()
      }
      default:
        return renderProjectDetailsTable()
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container alignItems="center" className={classes.imgInfo}>
          <div className={classes.attrDiv}>
            <Typography variant="subtitle1" className={classes.attrHead}>
              #TCINs
            </Typography>
            <Typography variant="button" className={classes.attrValue}>
              {(projectData.project_stats || {}).tcin_count}
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.attrDiv}>
            <Typography variant="subtitle1" className={classes.attrHead}>
              Estimated #Images
            </Typography>
            <Typography variant="button" className={classes.attrValue}>
              {(projectData.project_stats || {}).estimated_images}
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.attrDiv}>
            <Typography variant="subtitle1" className={classes.attrHead}>
              Shoot Complete
            </Typography>
            <Typography variant="button" className={classes.attrValue}>
              {(projectData.project_stats || {}).shoot_complete}
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.attrDiv}>
            <Typography variant="subtitle1" className={classes.attrHead}>
              In Imaging
            </Typography>
            <Typography variant="button" className={classes.attrValue}>
              {(projectData.project_stats || {}).imaging}
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.attrDiv}>
            <Typography variant="subtitle1" className={classes.attrHead}>
              Sent to Pipeline
            </Typography>
            <Typography variant="button" className={classes.attrValue}>
              {(projectData.project_stats || {}).pipeline}
            </Typography>
          </div>
        </Grid>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            variant="text"
            className={classes.tagsButton}
            onClick={() => {
              setOpenDrawer('Project Info')
            }}
          >
            View Project Info
          </Button>
        </div>

        <Drawer
          classes={{
            paper: classes.drawer,
          }}
          anchor="right"
          open={openDrawer.length}
          onClose={() =>
            openDrawer === 'Edit Project'
              ? handleEditClose()
              : setOpenDrawer('')
          }
        >
          <div
            style={{
              height: '100%',
            }}
          >
            <Grid
              wrap={'nowrap'}
              container
              justify={'space-between'}
              alignItems={'center'}
            >
              <h4 className={classes.drawerHeader}>{openDrawer}</h4>
              <IconButton
                aria-label="close"
                onClick={() => setOpenDrawer('')}
                className={classes.closeIcon}
              >
                <CloseIcon style={{ fontSize: 24 }} />
              </IconButton>{' '}
            </Grid>
            {renderDrawer(openDrawer)}
          </div>
        </Drawer>
      </div>
      <CustomTabs
        tabLabels={projectTabLabels}
        renderTabsContent={renderTabsContent}
        setCurrentTabValue={setCurrentTabValue}
        defaultTab={currentTabValue}
        showTabsPanel={false}
      />
      <Drawer
        classes={{
          paper: classes.tabDrawer,
        }}
        anchor="right"
        open={tabData}
        onClose={handleCloseTabData}
      >
        <TabData
          closeDialog={handleCloseTabData}
          tabIndex={tabIndex}
          tcinData={tabContent}
          parentTab={currentTabValue}
        />
      </Drawer>
    </div>
  )
})

const mapAuthToProps = (auth) => {
  return {
    accessToken: auth.session?.userInfo?.accessToken,
    userId: auth.session?.userInfo?.lanId,
    memberOf: auth.session?.userInfo?.memberOf,
  }
}

export default withAuth(mapAuthToProps)(React.memo(ProjectDetail))
