import axios from 'axios'
import {
  GET_ALL_PROJECTS,
  SET_LOADING,
  IS_INCLUDE_ARCHIVED,
  IS_MY_PROJECTS,
  SET_PROJECT_DATA,
  SET_TAB_CONTENT,
  SET_PROJECT_SEARCH_TEXT,
  SET_PROJECT_LOADING,
  SET_CUSTOM_COLUMNS_LIST,
} from './actionTypes'
import apiConfig, { appEnv } from '../../apiConfig'
import * as projectApi from '../../services/projectService'

const { projectsApi, isPol = false } = apiConfig

function getAllProjects(data) {
  return {
    type: GET_ALL_PROJECTS,
    payload: data,
  }
}

export const getAllProjectsAPI =
  (accessToken = '', lanId = '', company = '') =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))

    const {
      dashboardReducer: {
        isIncludedArchived = false,
        isMyProjects = false,
      } = {},
      authReducer: { lanId: userId = '' } = {},
    } = getState()

    const url = `${projectsApi}`
    axios
      .get(url, {
        params: {
          key: apiConfig.apiKey,
          archived: isIncludedArchived,
          is_user_projects: isMyProjects,
          company: isPol
            ? company.indexOf(',') > -1
              ? company.substring(0, company.indexOf(','))
              : company
            : null,
        },
        headers: {
          Authorization: accessToken,
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId || lanId }),
        },
      })
      .then((response) => {
        const result = response.data.map((rObj) => {
          const { first_name, last_name } = rObj?.created_by || {}
          return {
            ...rObj,
            user_name: [first_name, last_name].filter((obj) => obj).join(' '),
          }
        })
        dispatch(getAllProjects(result))
        dispatch(setLoading(false))
      })
      .catch((error) => {
        //console.log(error)
      })
  }

export const setProjectsToArchive = (ids) => async (dispatch, getState) => {
  const url = `${projectsApi}/archive`
  const { authReducer: { accessToken = '' } = {} } = getState()
  const body = {
    project_ids: ids,
  }
  axios
    .post(url, body, {
      params: {
        key: apiConfig.apiKey,
      },
      headers: { Authorization: accessToken },
    })
    .then((response) => {
      dispatch(getAllProjectsAPI())
    })
    .catch((error) => {
      //console.log(error)
    })
}

export const getProjectInfo =
  ({ page = 0, numberOfRows, projectId = '', accessToken = '', lanId = '' }) =>
  async (dispatch, getState) => {
    setProjectLoading(true)
    return projectApi
      .fetchProjectImages({ page, numberOfRows, projectId, accessToken, lanId })
      .then((res = {}) => {
        const { data = {} } = res
        dispatch(setProjectInfo(data))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setProjectLoading(false)
      })
  }
export const callRepurposeAHImages =
  ({ assetList = {}, accessToken = '', lanId = '', callBack = () => {} }) =>
  async (dispatch, getState) => {
    setProjectLoading(true)
    return projectApi
      .repurposeAHImages({
        params: { repurpose_files: assetList },
        accessToken,
        lanId,
      })
      .then((res = {}) => {
        const { data = {} } = res
        callBack(data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setProjectLoading(false)
      })
  }
export const getProjectPackagingInfo =
  ({ page = 0, numberOfRows, projectId = '', accessToken = '', lanId = '' }) =>
  async (dispatch, getState) => {
    setProjectLoading(true)
    return projectApi
      .fetchProjectPackagingImages({
        page,
        numberOfRows,
        projectId,
        accessToken,
        lanId,
      })
      .then((res = {}) => {
        const { data = {} } = res
        dispatch(setProjectInfo(data))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setProjectLoading(false)
      })
  }

export const getCustomColumnsList =
  ({ userId = '', accessToken = '' }) =>
  async (dispatch, getState) => {
    setProjectLoading(true)
    return projectApi
      .fetchCustomColumnsList({ userId, accessToken })
      .then((res = {}) => {
        const { data: { column_list = [] } = {} } = res
        dispatch(setCustomColumns(column_list))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setProjectLoading(false)
      })
  }

export const updateCustomColumnsList =
  ({ userId = '', accessToken = '', columnList = [] }) =>
  async (dispatch, getState) => {
    setProjectLoading(true)
    return projectApi
      .updateCustomColumnsList({ userId, accessToken, columnList })
      .then((res = {}) => {
        const { data: { project_columns: { column_list = [] } = {} } = {} } =
          res
        dispatch(setCustomColumns(column_list))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setProjectLoading(false)
      })
  }

export const archiveProjects =
  ({ accessToken = '', params = {}, callBack = () => {} }) =>
  async (dispatch, getState) => {
    setProjectLoading(true)
    return projectApi
      .makeProjectsArchive({ accessToken, params })
      .then((res = {}) => {
        callBack()
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setProjectLoading(false)
      })
  }

export const setIsMyProjects = (isMyProj) => {
  return {
    type: IS_MY_PROJECTS,
    payload: isMyProj,
  }
}

export const setIsIncludeArchived = (isIncArch) => {
  return {
    type: IS_INCLUDE_ARCHIVED,
    payload: isIncArch,
  }
}

const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading,
  }
}

export const setProjectLoading = (loading) => {
  return {
    type: SET_PROJECT_LOADING,
    payload: loading,
  }
}

export const setProjectInfo = (data) => {
  return {
    type: SET_PROJECT_DATA,
    payload: data,
  }
}

export const setCustomColumns = (data) => {
  return {
    type: SET_CUSTOM_COLUMNS_LIST,
    payload: data,
  }
}

export const setProjectSearchText = (query) => {
  return {
    type: SET_PROJECT_SEARCH_TEXT,
    payload: query,
  }
}

export const setTabContentInfo = (data) => {
  return {
    type: SET_TAB_CONTENT,
    payload: data,
  }
}
