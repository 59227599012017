import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CustomPaginationActions from '../../components/CustomTable/CustomPaginationActions'
import { tabLabels as tabLabelsList } from './constants'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import { toSafeData } from '../TcinInfo/mapper'

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    alignItems: 'flex-start',
  },
  appBarHeader: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    // width: '40%',
    width: 'inherit',
    //alignItems: 'center',
    flex: 'auto',
    marginInlineEnd: 'auto',
  },
  indicator: {
    backgroundColor: '#497ADD',
  },
  paginationInfo: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    marginRight: 56,
    '& > :first-child': {
      marginRight: 20,
    },
  },
  headerinfo: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ overflow: index === 'packaging assets' ? 'unset' : 'scroll' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export default function TabData(props) {
  const classes = useStyles()
  const [rowIndex, setRowIndex] = React.useState(0)
  const [tab, setTab] = React.useState('photo')
  const [data, setData] = React.useState({})
  const parentTab = props?.parentTab || 'default'
  const tabLabels =
    parentTab !== 'packaging'
      ? tabLabelsList?.filter((obj) => obj.label !== 'packaging assets')
      : tabLabelsList

  React.useEffect(() => {
    setRowIndex(props.tabIndex.rowIndex)
    setTab(props.tabIndex.category)
    setData(props.tcinData[props.tabIndex.rowIndex])
  }, [props.tcinData, props.tabIndex])

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const handleItemChange = (event, index) => {
    setRowIndex(index)
    setData(props.tcinData[index])
  }

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        color="default"
        classes={{
          colorDefault: classes.appBar,
        }}
      >
        <div className={classes.appBarHeader}>
          <div className={classes.headerinfo}>
            <Typography style={{ marginLeft: 18 }} variant="h6">
              TCIN {toSafeData(data, 'tcin', '-')}
            </Typography>
            <IconButton
              onClick={props.closeDialog}
              style={{
                marginRight: 16,
                position: 'relative',
                alignSelf: 'flex-end',
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.paginationInfo}>
            <Typography variant="body1">
              Showing TCIN {rowIndex + 1} of {props?.tcinData?.length}
            </Typography>
            <CustomPaginationActions
              count={props.tcinData.length}
              page={rowIndex}
              rowsPerPage={1}
              onChangePage={handleItemChange}
              noMargin
            />
          </div>
        </div>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="tcin-sub-info"
          centered
          textColor="primary"
          classes={{
            indicator: classes.indicator,
          }}
        >
          {tabLabels.map((tabData, index) => (
            <Tab key={index} value={tabData.label} label={tabData.label} />
          ))}
        </Tabs>
      </AppBar>
      {tabLabels.map((tabData, index) => (
        <TabPanel key={index} value={tab} index={tabData.label}>
          <tabData.component data={data} />
        </TabPanel>
      ))}
    </React.Fragment>
  )
}
