/* eslint-disable multiline-ternary */
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import Stack from '@mui/material/Stack'
import apiConfig from '../../apiConfig'
import { toSafeData, formatDate } from './mapper'
import {
  Table,
  Select,
  FormControl,
  InputLabel,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Container,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
  TablePagination,
  TextField,
  Checkbox,
  Drawer,
  Badge,
  Divider,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import GetAppIcon from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'
import { isEmpty, trim, cloneDeep } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CSVLink } from 'react-csv'
import columnMap, { allStudiosList } from './constants'
import TuneIcon from '@mui/icons-material/Tune'
import { formatCSVData } from './mapper'
import PaginationActions from './PaginationActions'
import defaultThumbnail from '../../images/defaultThumbnail.jpg'
import {
  initUpload,
  uploadStartCallInit,
  uploadStartCallFail,
  uploadStartCallSuccess,
  chunkInit,
  chunkSuccess,
  chunkFail,
  chunkEndCallInit,
  chunkEndCallSuccess,
  chunkEndCallFail,
  uploadJobStatusInit,
  uploadJobStatusSuccess,
  uploadJobStatusFail,
  toggleUploadManager,
} from '../../store/uploads/actionCreator'
import { appEnv } from '../../apiConfig'
import { UPLOAD_FILE_TARGET_NAME } from '../../constants/uploads'
import SearchIcon from '@material-ui/icons/Search'
import { compact } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setBreadcrumbs } from '../../store/Layout/actionCreator'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorOutlineTwoToneIcon from '@material-ui/icons/ErrorOutlineTwoTone'
import { withStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { purple } from '@material-ui/core/colors'
import Skeleton from '@material-ui/lab/Skeleton'
import { fileValidationTexts, imageDownloadHost } from '../../constants/common'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { withAuth } from '@praxis/component-auth'
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import { isSafari, isDeepEmpty } from '../../helpers/filter'
import { formatDateTime } from '../../helpers/dateHelper'
import { parseISO } from 'date-fns/esm'
import { downloadPreviewImage } from '../../helpers/imageDownloadHelper'
import { showNotification } from '../../store/Notification/actionCreator'
import UploadManger from '../UploadManager'
import { getChunkNumbers } from '../Upload/helper'
import EditMultiTcinForm from '../../components/EditMutiTcinForm'
import { MultiTcinReusedFlyout } from '../../components/MultiTcinReusedFlyout'
import { MultiTcinFlyout } from '../Workflow/MutiTcinFlyout'
import { setFilesForDownloads } from '../../store/Downloads/actionCreator'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'

const { getIngestedImagesApi } = apiConfig
const CURR_PROJECT = 'PHOTOBRIDGE'
const CURR_PROJECT_UUID = 'PHOTOBRIDGE_INGESTION_UUID'
const CURR_PROJECT_UOID = 'PHOTOBRIDGE_INGESTION_UOID'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    '&:hover': {
      backgroundColor: purple[400],
    },
  },
}))(Button)

const modalStyles = makeStyles((theme) => ({
  root: {
    maxheight: 300,
    flexGrow: 1,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
    margin: 0,
    padding: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgStyle: { width: '400px', maxHeight: '100%' },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  multiTcinDrawerContent: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: '24px',
  },
  actionsDrawer: {
    width: 'auto',
    backgroundColor: '#3f51b5',
    padding: '8px 26px',
    alignItems: 'flex-start',
    left: '64px',
  },
  appBarSpacer: { minHeight: 8 },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  tablePaper: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 200,
  },
  totalCount: {
    marginTop: 24,
    marginBottom: 8,
    marginLeft: 24,
    display: 'flex',
    alignItems: 'center',
    gap: '48px',
  },
  tableToolbarAction: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
    justifyContent: 'space-between',
    marginTop: 16,
  },
  container: {
    padding: '4px',
    maxWidth: 'inherit',
    marginTop: isSafari() ? '21px' : '0px',
  },
  inputProps: {
    height: 40,
    padding: '0 8px',
  },
  filter: {
    margin: '10px 20px 5px 25px',
    width: 180,
    flexDirection: 'row',
  },
  clearButton: {
    fontSize: '14px',
    color: '#366CD9',
    padding: 0,
    height: '24px',
    width: '65px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  stausButton: {
    height: '40px',
    width: '120px',
    fontSize: '14px',
    fontWeight: '500',
  },
  primaryButton: {
    backgroundColor: '#366CD9',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
    '&:focus': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#666666',
    margin: 0,
    display: 'flex',
  },
  closeIcon: {
    padding: 0,
  },
  filtersContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '47px',
    paddingTop: 24,
  },
  badgeRoot: {
    display: 'flex',
    alignItems: 'end',
  },
  badgeDetails: {
    top: '50px !important',
  },
  drawer: {
    width: '80%',
    maxWidth: '1200px',
  },
  drawerHeaderDivider: {
    marginBottom: '20px',
    width: `calc(100% + 24px)`,
    marginLeft: '0px',
    '&:before': {
      content: "''",
      width: '34px',
      height: '1px',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.12)',
      marginLeft: '-24px',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 24,
    padding: '24px',
  },
  header: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tcinTitles: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  tcinNames: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
  },
  actionButtons: {
    color: '#366CD9',
    borderColor: '#366CD9',
    height: '32px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  drawerHeaderDividerTCIN: {
    marginBottom: '0 !important',
    width: '100% !important',
  },
  drawerContainer: {
    padding: '40px 0',
  },
  searchFilter: {
    width: 360,
  },
  inputWidth: {
    width: 423,
  },
  parent: {
    display: 'flex',
    columnGap: '0.275rem',
  },
  inputLabel: {
    fontSize: '14px !important',
    lineHeight: 'unset !important',
    transform: 'translate(14px, 12px) scale(1) !important',
  },
  inputRoot: {
    width: '100%',
  },
  labelShrink: {
    transform: 'translate(14px, -8px) scale(0.75) !important',
  },
  searchContainer: {
    position: 'relative',
    height: 'auto',
    padding: 0,
    margin: '0 0 16px 24px',
    width: 'auto !important',
  },
  footerButton: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    textTransform: 'capitalize !important',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    padding: '6px 8px',
  },
  searchButton: {
    border: '1px solid #C4C4C4 !important',
    borderRadius: '4px !important',
    padding: '10px !important',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  ingestionDrawer: {
    width: '40%',
    maxWidth: '1000px',
    padding: 24,
    height: 'calc(100% - 48px)',
  },
  filtersDrawer: {
    '& >div': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  child: {
    display: 'inline-block',
  },
  checkbox: {
    padding: 8,
    paddingTop: 0,
    verticalAlign: 'top',
    left: 0,
    zIndex: 5,
    position: 'sticky',
  },
}))

const Loader = () => {
  return <Skeleton height={32} variant="text" animation="wave" />
}

const ImageDialog = ({ open, src, handleClose, title = '' }) => {
  const classes = modalStyles()
  const rootRef = React.useRef(null)
  const DialogTitle = withStyles(classes)((props) => {
    const { children, onClose } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  return (
    <div className={classes.root} ref={rootRef}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          <img
            src={src}
            className={classes.imgStyle}
            alt="full"
            style={{ height: 564, width: 564, cursor: 'zoom-in' }}
            onClick={() => {
              window.open(src)
            }}
            onError={(e) => {
              e.target.src = defaultThumbnail
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

const initialState = {
  ingested_dates: {
    end_date: moment().format('YYYY-MM-DD'),
    start_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
  },
  shot_dates: {
    end_date: '',
    start_date: '',
  },
  studios: 'Winter Street',
  photographers: [],
  tbays: [],
}

const Ingestion = (props) => {
  const { company = '', accessToken = '', lanId = '' } = props
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(setBreadcrumbs(props.customPath, pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])
  const [search, setSearch] = React.useState(
    localStorage.getItem('ingestion_search_term')
      ? localStorage.getItem('ingestion_search_term')
      : ''
  )
  const classes = useStyles()
  const [ingestData, setIngestData] = React.useState([])
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [tbayFilterDropDown, setTbayFilterDropDown] = React.useState(false)
  const [phtFilterDropDown, setPhtFilterDropDown] = React.useState(false)

  const [tbays, setTbays] = React.useState([])
  const [photographers, setPhotographers] = React.useState([])
  const [ingestionFilters, setIngestionFilters] = React.useState(
    localStorage.getItem('ingestion_filters')
      ? JSON.parse(localStorage.getItem('ingestion_filters'))
      : initialState
  )
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(100)
  const [totalCount, setTotalCount] = React.useState(0)
  const [rows, setRows] = React.useState([])

  const [selected, setSelected] = React.useState([])
  const [dropZoneActive, setDropZoneActive] = React.useState(false)
  const [canUploadFiles, setCanUploadFiles] = React.useState([])
  const [droppedFiles, setDroppedFiles] = React.useState({})
  const [selectedBackup, setAllSelectedBackup] = React.useState([])
  const [csvData, setCsvData] = React.useState({
    headers: [],
    data: false,
  })
  const csvBtn = React.useRef()
  const [loading, setLoading] = React.useState(true)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('dateIngested')
  const [enlargeImage, setEnlargeImage] = React.useState({
    open: false,
    src: '',
    title: '',
  })

  const uploadFiles = React.useRef([])
  let uploadCount = React.useRef(0)
  let lastFile = React.useRef(0)
  let partetag = React.useRef({})

  let uploadSuccessFiles = React.useRef({})
  let uploadFilesProgress = React.useRef([])

  const uploadsInProgress = useSelector((state) => {
    const {
      uploadsReducer: {
        tcins: { PHOTOBRIDGE_INGESTION_UUID: { uploadingFiles } = {} } = {},
      } = {},
    } = state
    return uploadingFiles || {}
  })

  const successFiles = useSelector((state) => {
    const {
      uploadsReducer: {
        tcins: { PHOTOBRIDGE_INGESTION_UUID: { successFiles } = {} } = {},
      } = {},
    } = state
    return successFiles || {}
  })

  const usePrevious = (value) => {
    const ref = React.useRef()
    React.useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevSearch = usePrevious(search)

  React.useEffect(() => {
    if (prevSearch && prevSearch !== search && search === '') {
      onSearch('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const failedFiles = useSelector((state) => {
    const {
      uploadsReducer: {
        tcins: { PHOTOBRIDGE_INGESTION_UUID: { failedFiles } = {} } = {},
      } = {},
    } = state
    return failedFiles || {}
  })

  const {
    ingested_dates: {
      end_date: ingestion_end_date = '',
      start_date: ingestion_start_date = '',
    } = {},
    shot_dates: {
      end_date: shot_end_date = '',
      start_date: shot_start_date = '',
    } = {},
  } = ingestionFilters

  const onDragEnter = () => {
    setDropZoneActive(true)
  }

  const onDragLeave = () => {
    setDropZoneActive(false)
  }

  const onDrop = async (files) => {
    setDropZoneActive(false)
    const filesTobeValidated = files.reduce((obj, item) => {
      if (uploadsInProgress[item.name]) {
        if (!obj['fileNameWithStatus']) {
          obj['fileNameWithStatus'] = {
            [item.name]: 'Upload is already in progress',
          }
        } else {
          obj['fileNameWithStatus'][item.name] = 'Upload is already in progress'
        }
      } else {
        if (!obj['fileNames'] && !uploadsInProgress[item.name]) {
          obj['fileNames'] = [item.name]
        } else {
          obj['fileNames'].push(item.name)
        }
        if (!obj['fileNameWithStatus']) {
          obj['fileNameWithStatus'] = {
            [item.name]: fileValidationTexts.validating,
          }
        } else {
          obj['fileNameWithStatus'][item.name] = fileValidationTexts.validating
        }
      }

      return obj
    }, {})
    const { fileNames = [], fileNameWithStatus = {} } = filesTobeValidated
    setDroppedFiles({ ...droppedFiles, ...fileNameWithStatus })
    if (fileNames.length) {
      axios
        .post(
          `${apiConfig.uploads.validatingUrl}`,
          { file_names: fileNames },
          {
            params: {
              key: apiConfig.apiKey,
            },
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((resp) => {
          const {
            data: {
              result: {
                error_files_list = [],
                existing_images_list = [],
                new_images_list = [],
                reused_image_list = [],
              } = {},
            } = {},
          } = resp
          Object.keys(fileNameWithStatus).forEach((key, i) => {
            if (fileNameWithStatus[key] !== 'Upload is already in progress') {
              let err = error_files_list.find((elem) => elem.file_name === key)
              const isNew = new_images_list.find((elem) => elem === key)
              const isReused = reused_image_list.find((elem) => elem === key)

              if (err) {
                fileNameWithStatus[key] = err.reason
              } else if (isNew) {
                fileNameWithStatus[key] = fileValidationTexts.new
              } else if (isReused) {
                fileNameWithStatus[key] = fileValidationTexts.reusedImage
              } else {
                fileNameWithStatus[key] = fileValidationTexts.valid
              }
            }
          })
          setDroppedFiles({ ...droppedFiles, ...fileNameWithStatus })
          if (existing_images_list.length || new_images_list.length) {
            const canUpload = files.filter(
              (item) =>
                [...existing_images_list, ...new_images_list].includes(
                  item.name
                ) && !canUploadFiles.some((dat) => dat.name === item.name)
            )
            let mergePrevCanUpload = cloneDeep(canUploadFiles).concat(canUpload)
            setCanUploadFiles(mergePrevCanUpload)
          }
        })
        .catch((err) => {
          Object.keys(fileNameWithStatus).forEach((key, i) => {
            if (fileNameWithStatus[key] !== 'Upload is already in progress') {
              fileNameWithStatus[key] = fileValidationTexts.error
            }
          })

          setDroppedFiles({ ...droppedFiles, ...fileNameWithStatus })
        })
    }
  }

  const uploadProgressCallBack = (
    uploadProgressStatus = '',
    totalChunks = Number(0),
    completedChunks = Number(0),
    fileName = ''
  ) => {
    let files = cloneDeep(uploadFiles.current)
    let arrayObj = []
    const newDeleteList = files.filter(
      (obj) => obj.name !== fileName && obj.uploadStatus !== 'success'
    )
    arrayObj = [
      ...newDeleteList,
      {
        uploadStatus: uploadProgressStatus,
        totalChunks: totalChunks,
        completedChunks: completedChunks,
        name: fileName,
      },
    ]
    if (arrayObj) {
      uploadFilesProgress.current = [
        ...uploadFilesProgress.current,
        ...arrayObj,
      ]
      // uploadFiles.current = arrayObj
    }
  }

  const upload = (files) => {
    const newFiles = cloneDeep(files)

    let projectDetails = {
      project_name: CURR_PROJECT,
      project_uuid: CURR_PROJECT_UUID,
    }
    let filesObj = {}
    let chunkSize = 5 * 1024 * 1024
    let shotTypeUploads = {}
    newFiles.forEach(function (singleFile) {
      const totalNumberOfChunks = Math.ceil(singleFile.size / chunkSize)
      const singleFileObj = {
        completedChunks: 0,
        chunkSize: chunkSize,
        totalNumberOfChunks: totalNumberOfChunks,
        uploadStatus: 'Not Started',
        project_name: CURR_PROJECT,
        project_uuid: CURR_PROJECT_UUID,
        uoi_id: CURR_PROJECT_UOID,
        currentChunk: 0,
        remainingChunks: getChunkNumbers(totalNumberOfChunks),
      }
      const newObj = Object.assign(singleFile, singleFileObj)
      shotTypeUploads = {
        name: newObj.name,
        completedChunks: newObj.completedChunks,
        totalNumberOfChunks: newObj.totalNumberOfChunks,
        uploadStatus: newObj.uploadStatus,
      }
      filesObj[newObj.name] = Object.assign({}, shotTypeUploads)
    })
    dispatch(initUpload(filesObj, projectDetails))
    setDroppedFiles({})
    setCanUploadFiles([])
    uploadFiles.current = [...uploadFiles.current, ...newFiles]
    uploadManagerInit(newFiles)
    uploadStartCall()
  }

  const uploadManagerInit = (files = []) => {
    files.forEach(function (singleFile) {
      dispatch(uploadStartCallInit(singleFile.project_uuid, singleFile.name))
    })
  }

  const uploadStartCall = () => {
    let totalCall = uploadCount.current
    for (let i = lastFile.current; i < uploadFiles.current.length; i++) {
      totalCall = totalCall + uploadFiles.current[i].totalNumberOfChunks
      startLoop(uploadFiles.current[i])
      lastFile.current = lastFile.current + 1
      if (totalCall > 10) {
        break
      }
    }
  }

  const startLoop = (file) => {
    uploadCount.current = uploadCount.current + 1
    let data = {
      content_length: file.size,
      content_type: file.type,
      file_name: file.name,
      studio: 'WinterSt',
      targets: [UPLOAD_FILE_TARGET_NAME],
    }
    axios
      .post(`${apiConfig.uploads['ingestionUploadsUrl']}/start`, data, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        dispatch(uploadStartCallSuccess(file.project_uuid, file.name))
        file.uploadId = response.data.upload_id
        uploadCount.current = uploadCount.current - 1
        if (uploadCount.current <= 10) {
          startChunking(file, 0)
        } else {
          lastFile.current = lastFile.current - 1
        }
      })
      .catch((e) => {
        uploadProgressCallBack(
          'ERROR',
          file.totalNumberOfChunks,
          file.completedChunks,
          file.name
        )
        uploadCount.current = uploadCount.current - 1
        dispatch(uploadStartCallFail(file.project_uuid, file.name))
      })
  }

  const startChunking = (file = {}, chunkNumber) => {
    const remainingChunks = file.remainingChunks
    if (remainingChunks.includes(chunkNumber)) {
      for (
        let requestedChunk = chunkNumber;
        requestedChunk < remainingChunks.length;
        requestedChunk++
      ) {
        if (
          uploadCount.current <= 10 &&
          file.completedChunks < file.totalNumberOfChunks
        ) {
          uploadCount.current = uploadCount.current + 1
          file.currentChunk = requestedChunk
          delete file.remainingChunks[requestedChunk]
          sendChunk(file)
        } else {
          break
        }
      }
    } else if (remainingChunks.length > 0) {
      file.currentChunk = remainingChunks[0]
      startChunking(file, file.currentChunk)
    }
  }

  const sendChunk = (file) => {
    dispatch(chunkInit(file.project_uuid, file.name))

    let chunkedFormData = new window.FormData()
    chunkedFormData.append(
      'chunkUploadRequest',
      new window.Blob(
        [
          JSON.stringify({
            upload_id: file.uploadId,
            file_name: file.name,
            studio: 'WinterSt',
            chunk_number: file.currentChunk,
            total_number_of_chunks: file.totalNumberOfChunks,
            chunk_size: file.chunkSize,
            total_file_size: file.size,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    chunkedFormData.append(
      'file',
      file.slice(
        file.currentChunk * file.chunkSize,
        (file.currentChunk + 1) * file.chunkSize
      )
    )

    axios
      .post(`${apiConfig.uploads['ingestionUploadsUrl']}`, chunkedFormData, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        file.completedChunks = file.completedChunks + 1
        uploadCount.current = uploadCount.current - 1
        dispatch(
          chunkSuccess(
            file.project_uuid,
            file.name,
            file.completedChunks,
            file.totalNumberOfChunks
          )
        )
        const { parte_tag, upload_chunk_id } = response.data
        partetag.current[upload_chunk_id]
          ? (partetag.current[upload_chunk_id] = [
              ...partetag.current[upload_chunk_id],
              parte_tag,
            ])
          : (partetag.current[upload_chunk_id] = [parte_tag])
        if (file.completedChunks === file.totalNumberOfChunks) {
          sendChunkEndCall(file)
        } else {
          startChunking(file, Number(file.currentChunk) + 1)
        }
      })
      .catch((error) => {
        file.completedChunks = file.completedChunks + 1
        uploadCount.current = uploadCount.current - 1
        dispatch(chunkFail(file.project_uuid, file.name))
        uploadProgressCallBack(
          'ERROR',
          file.totalNumberOfChunks,
          file.completedChunks,
          file.name
        )
      })
  }

  const sendChunkEndCall = (file) => {
    dispatch(chunkEndCallInit(file.project_uuid, file.name))
    let params = {
      job_id: file.uploadId,
      vault_upload_status: '',
      original_asset_name: file.name,
      project_uuid: file.project_uuid,
      project_name: file.project_name,
      content_signature: '',
      asset_content_type: '',
      asset_size: file.size,
      metadata: '',
      sync_folder_id: file.uoi_id,
      version: file.version,
      asset_master_id: file.asset_master_id,
      asset_master_uoi_id: file.asset_master_uoi_id,
      creative_asset_status: file.creative_asset_status || 'WIP',
      content_type: 'image',
    }
    let url = `${apiConfig.uploads['ingestionUploadsUrl']}/end`
    let req = {
      upload_id: file.uploadId,
      file_name: file.name,
      studio: 'WinterSt',
      parte_tags: partetag.current[file.uploadId],
      file_size: file.size,
    }
    uploadCount.current = uploadCount.current + 1
    axios
      .post(url, req, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': lanId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        dispatch(chunkEndCallSuccess(file.project_uuid, file.name))
        const { data = {} } = response
        const {
          render_url = '',
          message = 'Image is submitted for ingestion',
          file_name = file.name,
        } = data
        // uploadProgressCallBack(
        //   job_status,
        //   file.totalNumberOfChunks,
        //   file.completedChunks,
        //   file.name
        // )
        params = Object.assign(params, {
          render_url: render_url,
          vault_upload_status: message,
          job_id: file.uploadId,
        })
        uploadCount.current = uploadCount.current - 1
        if (uploadCount.current <= 10) {
          updateUploadStatus(file, params, true)
        }
        dispatch(uploadJobStatusSuccess(file.project_uuid, file_name, message))
      })
      .catch((e) => {
        uploadCount.current = uploadCount.current - 1
        dispatch(chunkEndCallFail(file.project_uuid, file.name))
        params = Object.assign(params, {
          vault_upload_status: 'ERROR',
          render_url: '',
          job_id: file.uploadId,
        })
        if (uploadCount.current <= 10) {
          updateUploadStatus(file, params, false)
        }
        uploadProgressCallBack(
          'ERROR',
          file.totalNumberOfChunks,
          file.completedChunks,
          file.name
        )
      })
  }
  const updateUploadStatus = (file = {}, params = {}, isEndCallSuccess) => {
    uploadCount.current = uploadCount.current + 1

    if (isEndCallSuccess) {
      // dispatch(
      //   uploadJobStatusSuccess(
      //     file.project_uuid,
      //     file.name,
      //     params.vault_upload_status
      //   )
      // )
      uploadCount.current = uploadCount.current - 1
    } else {
      uploadCount.current = uploadCount.current - 1
      dispatch(uploadJobStatusFail(file.project_uuid, file.name))
    }
    if (uploadCount.current <= 10) {
      uploadStartCall()
    }
  }

  const ingestionStartDate = parseISO(ingestion_start_date)
  const ingestionEndDate = parseISO(ingestion_end_date)

  const shotStartDate = parseISO(shot_start_date)
  const shotEndDate = parseISO(shot_end_date)

  const onChangeSearch = (e) => {
    let tcinsList = e.target.value ? e.target.value.split(/[^\d\w-.]+/g) : []
    setSearch(tcinsList.join(',').trim())
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(
      Number(event.target.value.toString().replace(/[^0-9.]+/g, ''))
    )
    setPage(0)
  }
  React.useEffect(() => {
    if (
      !isEmpty(csvData.data) &&
      csvBtn.current !== null &&
      csvBtn.current.link !== null
    ) {
      setTimeout(() => {
        csvBtn.current !== null && csvBtn.current.link.click()
        setCsvData({ ...csvData, data: false })
      })
    }
  }, [csvData])

  React.useEffect(() => {
    getFilteredData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, page, rowsPerPage, order, orderBy])

  const onSelectAllClick = () => {
    if (selected.length !== rows.length) {
      setSelected(rows)
      setAllSelectedBackup(rows)
    } else {
      setSelected([])
      setAllSelectedBackup([])
    }
  }

  const onSearch = (searchText) => {
    setIngestionFilters({
      ingested_dates: {
        end_date: '',
        start_date: '',
      },
      shot_dates: {
        end_date: '',
        start_date: '',
      },
      photographers: [],
      tbays: [],
      studios: 'All',
    })
    getFilteredData(
      {
        ingested_dates: {
          end_date: '',
          start_date: '',
        },
        shot_dates: {
          end_date: '',
          start_date: '',
        },
        photographers: [],
        tbays: [],
        studios: 'All',
      },
      searchText
    )
    localStorage.setItem('ingestion_search_term', searchText)
  }

  const errorFileNotification = (fileName) => {
    dispatch(
      showNotification(
        true,
        `Error while downloading ${fileName}`,
        'error',
        1000
      )
    )
  }

  const handleJpegDownload = () => {
    var downloadUrls = selected.filter((s) => s)
    for (let index = 0; index < downloadUrls.length; index++) {
      if (downloadUrls[index]?.image_url) {
        if (downloadUrls[index]?.zoom_img_url.includes('gfc.target.com')) {
          downloadPreviewImage(downloadUrls[index]?.zoom_img_url)
        } else {
          const { pathname = '' } = new URL(downloadUrls[index]?.zoom_img_url)
          downloadPreviewImage(`${imageDownloadHost}${pathname}`)
        }
      }
    }
  }

  const onSelectRow = (fileID) => {
    const selectedIndex = selected.map((i) => i.photo_id).indexOf(fileID)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = selected.concat(
        ingestData.filter((i) => i.photo_id === fileID)
      )
      if (search) {
        let newSelectedBackup = selectedBackup.concat(
          ingestData.filter((i) => i.photo_id === fileID)
        )
        setAllSelectedBackup(newSelectedBackup)
      } else {
        setAllSelectedBackup(newSelected)
      }

      setSelected(newSelected)
    } else if (selectedIndex >= 0) {
      newSelected = selected.filter((i) => i.photo_id !== fileID)
      if (search) {
        let newSelectedBackup = selectedBackup.filter(
          (i) => i.photo_id !== fileID
        )
        setAllSelectedBackup(newSelectedBackup)
      } else {
        setAllSelectedBackup(newSelected)
      }
      setSelected(newSelected)
    }
  }

  const onClickDownload = (event, value) => {
    closeDownload()
    let csvHeaders = []
    if (event.currentTarget.dataset.item === 'all') {
      csvHeaders = columnMap
        .filter((o) => {
          return (
            o.colId !== undefined &&
            o.dataType !== undefined &&
            o.dataType !== 'image'
          )
        })
        .map((obj) => {
          return {
            key: obj.colId,
            label: obj.colName,
          }
        })
    } else {
      csvHeaders = columnMap
        .filter((o) => {
          return (
            o.selected &&
            o.colId !== undefined &&
            o.dataType !== undefined &&
            o.dataType !== 'image' &&
            o.dataType !== 'image_array'
          )
        })
        .map((obj) => {
          return {
            key: obj.colId,
            label: obj.colName,
          }
        })
    }
    setCsvData({
      headers: csvHeaders,
      data: formatCSVData(selected, 'csv', 'UploadInfo'),
    })
  }

  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState(null)
  const closeDownload = () => {
    setDownloadAnchorEl(null)
  }

  const cellStyle = { verticalAlign: 'top', padding: 8 }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Success':
        return <CheckCircleOutlineIcon style={{ color: 'forestgreen' }} />
      case '-':
        return <ErrorOutlineTwoToneIcon style={{ color: 'orange' }} />
      default:
        return <ReportProblemOutlinedIcon style={{ color: 'red' }} />
    }
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function descendingComparator(a, b, orderBy) {
    let compareA = a[orderBy] ? a[orderBy].toUpperCase() : ''
    let compareB = b[orderBy] ? b[orderBy].toUpperCase() : ''
    if (orderBy === 'file_size') {
      compareA = Number(compareA.replace(/[^0-9.]+/g, ''))
      compareB = Number(compareB.replace(/[^0-9.]+/g, ''))
    }
    if (compareB < compareA) {
      return -1
    }
    if (compareB > compareA) {
      return 1
    }
    return 0
  }

  const handleDateChange = (field, name, date) => {
    date.setHours(0, 0, 0, 0)
    if (field === 'shot_dates') {
      const filters = {
        ...cloneDeep(ingestionFilters),
        [field]: {
          ...ingestionFilters[field],
          [name]: formatDateTime(date, 'YYYY-MM-DD'),
          ...(name === 'start_date' && {
            end_date: formatDateTime(date, 'YYYY-MM-DD'),
          }),
        },
      }
      setIngestionFilters(filters)
    } else {
      const filters = {
        ...cloneDeep(ingestionFilters),
        [field]: {
          ...ingestionFilters[field],
          [name]: formatDateTime(date, 'YYYY-MM-DD'),
        },
      }
      setIngestionFilters(filters)
      getFilteredData(filters)
    }
  }

  const handleFilterChange = (name, data) => {
    const filters = {
      ...cloneDeep(ingestionFilters),
      [name]: data,
    }
    if (name === 'studios') {
      getFilteredData(filters)
    }
    setIngestionFilters(filters)
  }

  const getMoreFiltersCount = () => {
    const moreFilter = cloneDeep(ingestionFilters)
    delete moreFilter.studios
    delete moreFilter.ingested_dates
    let count = 0
    Object.keys(moreFilter).forEach((dat, key) => {
      if (
        typeof moreFilter[dat] === 'object' &&
        !Array.isArray(moreFilter[dat]) &&
        moreFilter[dat] !== null
      ) {
        !isDeepEmpty(moreFilter[dat]) && count++
      } else {
        Object.values(moreFilter[dat]).length && count++
      }
    })
    return count
  }

  const getFilteredData = (
    inputFilters = ingestionFilters,
    searchText = search
  ) => {
    localStorage.setItem('ingestion_filters', JSON.stringify(inputFilters))
    const studioList = apiConfig.isPol
      ? company.indexOf(',') > -1
        ? [company.substring(0, company.indexOf(','))]
        : [company]
      : inputFilters?.studios === 'All'
        ? allStudiosList
        : [inputFilters?.studios]
    setSelected([])
    setTotalCount(0)
    let payload
    if (searchText) {
      payload = {
        filters: {
          ...inputFilters,
          studios: studioList,
        },
        file_names: [...new Set(compact(searchText.split(/, |,/)))],
      }
    } else {
      payload = {
        filters: {
          ...inputFilters,
          studios: studioList,
        },
        ...(search.length && {
          file_names: [...new Set(compact(search.split(/, |,/)))],
        }),
      }
    }
    setLoading(true)
    let companyName = apiConfig.isPol ? company : inputFilters?.studios
    companyName &&
      axios
        .post(
          `${getIngestedImagesApi}?page=${page}&size=${rowsPerPage}&sort_by=${orderBy}&sort_order=${order}`,
          {
            ...payload,
          },
          {
            params: {
              key: apiConfig.apiKey,
            },
          }
        )
        .then((res) => {
          setIngestData(res.data.image_details)
          setTotalCount(res.data.total_count)
          setRows(res.data.image_details)
          setTbays(res?.data?.tbays)
          setPhotographers(res?.data?.photographers)
          !search.length && setSearch('')
          setSelected([])
          setLoading(false)
          setOpenDrawer(false)
        })
        .catch((e) => {
          console.error(e)
        })
  }

  const renderTableData = (columnMapInstance) => {
    const {
      groupId = '',
      custColName = '',
      data = '',
      sortName = '',
    } = columnMapInstance
    return (
      <TableCell
        key={groupId}
        align="left"
        sortDirection="desc"
        style={cellStyle}
      >
        <TableSortLabel
          active={orderBy === data}
          direction={orderBy === data ? order : 'asc'}
          onClick={() => handleRequestSort(sortName)}
        >
          {custColName}
        </TableSortLabel>
      </TableCell>
    )
  }

  const renderTextColumn = (columnMapInstance = {}, i = 0) => {
    const { dataField = '', data = '' } = columnMapInstance
    return (
      <TableCell
        style={
          dataField === 'bit_depth' || dataField === 'compression'
            ? {
                verticalAlign: 'top',
                padding: 8,
                fontWeight: 800,
                color:
                  toSafeData(i, 'compression', '-') === 'LZW' ||
                  parseInt(trim(toSafeData(i, dataField, '-'))) === 8
                    ? 'green'
                    : 'red',
              }
            : cellStyle
        }
      >
        {data === 'tcin'
          ? toSafeData(i, dataField, '-').replace('.tif', '').split('_').shift()
          : toSafeData(i, dataField, '-')}
      </TableCell>
    )
  }

  const renderTCINIcon = (columnMapInstance = {}, i = 0) => {
    const {
      subject_codes = '',
      file_name = '',
      is_multitcin = false,
      is_original = false,
      original_image = '',
      studio = '',
    } = i
    const tcin = file_name && file_name.split('.')[0]
    const subjectCodesArray = subject_codes
      ?.split(/(?:,|;)+/)
      .filter(
        (dat) =>
          dat.length &&
          !dat.toLowerCase().includes('_p') &&
          !dat.includes('-') &&
          dat !== tcin.toString()
      )
    return (
      <TableCell style={cellStyle}>
        {is_multitcin ? (
          <EditMultiTcinForm
            isOriginal={is_original}
            originalImage={original_image}
            subjectCodesArray={subjectCodesArray}
            id={tcin}
          />
        ) : !is_multitcin && subject_codes?.length > 0 ? (
          <MultiTcinFlyout
            subjectCodesArray={subjectCodesArray}
            subjectCodes={subject_codes}
            studio={studio}
            tcin={tcin}
          />
        ) : (
          <span>-</span>
        )}
      </TableCell>
    )
  }

  const renderImageColumn = (columnMapInstance = {}, i = 0) => {
    const isPickupImage = (i?.studio || '').toLowerCase() === 'pickup'
    return (
      <TableCell style={{ position: 'relative', ...cellStyle }}>
        <img
          style={{
            height: 72,
            cursor: 'pointer',
          }}
          src={toSafeData(i, 'thumbnail_url', '-')}
          alt="-"
          onClick={() => {
            const imgUrl = toSafeData(i, 'zoom_img_url', ' - ')
            const fileName = `Image File - ${toSafeData(i, 'file_name', '-')}`
            if (imgUrl !== ' - ') {
              setEnlargeImage({
                open: true,
                src: imgUrl,
                title: fileName,
              })
            }
          }}
          onError={(e) => {
            e.target.src = defaultThumbnail
          }}
        />
        {i.is_multitcin || isPickupImage ? (
          <MultiTcinReusedFlyout
            styles={{
              position: 'absolute',
              top: '60px',
              right: 0,
              left: '8px',
              background: '#666666',
              color: '#ffffff',
              width: '72px',
              height: '20px',
              borderRadius: 0,
            }}
            isPickupImage={isPickupImage}
            tcin={i.file_name}
            subjectCodes={i.subject_codes}
            originalImage={i.original_image}
          />
        ) : (
          <></>
        )}
      </TableCell>
    )
  }

  const renderStatusColumn = (columnMapInstance = {}, i = 0) => {
    const { dataField = '', data = '' } = columnMapInstance
    return (
      <TableCell style={cellStyle}>
        <div className={classes.parent}>
          <div className={classes.child}>
            {getStatusIcon(toSafeData(i, dataField, '-'))}
          </div>
          <div className={classes.child}>{toSafeData(i, dataField, '-')}</div>
        </div>
      </TableCell>
    )
  }

  const renderAltPositionColumn = (columnMapInstance = {}, i = 0) => {
    const { dataField = '', data = '' } = columnMapInstance
    return (
      <TableCell style={cellStyle}>
        {isNaN(toSafeData(i, dataField, '-'))
          ? toSafeData(i, dataField, '-')
          : Number(i.alt_position)}
      </TableCell>
    )
  }

  const renderDateColumn = (columnMapInstance = {}, i = 0) => {
    const { dataField = '', data = '' } = columnMapInstance
    return (
      <TableCell style={cellStyle}>
        {formatDate(toSafeData(i, dataField, '-'))}
      </TableCell>
    )
  }

  return (
    <div className={classes.content}>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid
                wrap={'nowrap'}
                container
                alignItems={'center'}
                spacing={2}
                className={classes.searchContainer}
              >
                <Grid item lg={4} style={{ padding: 0 }}>
                  <TextField
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSearch(e.target.value)
                      }
                    }}
                    // type="search"
                    autoFocus
                    value={search}
                    classes={{ root: classes.inputRoot }}
                    onChange={onChangeSearch}
                    label="Find Images In Ingestion"
                    id="find-image"
                    InputLabelProps={{
                      classes: {
                        outlined: classes.inputLabel,
                        shrink: classes.labelShrink,
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.inputProps,
                      },
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={async () => {
                            setSearch('')
                          }}
                        >
                          {search.length > 0 ? <ClearOutlinedIcon /> : ''}
                        </IconButton>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  lg={2}
                  style={{
                    padding: '0 0 0 8px',
                  }}
                  className={classes.searchIconContainer}
                >
                  <IconButton
                    onClick={() => {
                      onSearch(search)
                    }}
                    aria-label="search"
                    className={classes.searchButton}
                    disabled={!search.length}
                  >
                    <SearchIcon style={{ fontSize: 18 }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider
                style={{ width: 'calc(100% + 32px)', marginLeft: '-16px' }}
              />
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  alignItems: 'start',
                  marginTop: isSafari() ? '21px' : '0px',
                  marginLeft: '24px',
                }}
              >
                <div
                  color="primary"
                  style={{
                    display: 'flex',
                    marginTop: '60px',
                    alignItems: 'center',
                  }}
                >
                  <TuneIcon color="#366CD9" />
                  <span>Filters</span>
                </div>

                {!apiConfig.isPol && (
                  <FormControl
                    variant="outlined"
                    className={classes.filter}
                    style={{ marginTop: '50px' }}
                  >
                    <InputLabel htmlFor="studio-filter">Studio</InputLabel>
                    <Select
                      native
                      value={ingestionFilters?.studios}
                      onChange={(e) => {
                        handleFilterChange('studios', e.target.value)
                      }}
                      label="Studio"
                      inputProps={{
                        name: 'studio',
                        id: 'studio-filter',
                      }}
                    >
                      <option value={'All'}>All</option>
                      <option value={'Winter Street'}>Winter Street</option>
                      <option value={'Royalston'}>Royalston</option>
                      <option value={'Periscope'}>Periscope</option>
                      <option value={'Schawk'}>Schawk</option>
                      <option value={'Splashlight'}>Splashlight</option>
                      <option value={'IndustrialColor'}>
                        Industrial Color
                      </option>
                      <option value={'Pickup'}>Pickup</option>
                    </Select>
                  </FormControl>
                )}
                <FormControl
                  variant="outlined"
                  className={classes.filter}
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: '13px',
                    }}
                  >
                    Photo Source / Ingested Date
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div
                        container
                        direction="row"
                        justify="flex-start"
                        style={{
                          gap: 32,
                          display: 'flex',
                          width: '380px',
                          marginRight: '-40px',
                          marginTop: '10px',
                        }}
                      >
                        <KeyboardDatePicker
                          disabled={loading}
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          disableFuture
                          value={
                            ingestion_start_date ? ingestionStartDate : null
                          }
                          format="yyyy-MM-dd"
                          margin="normal"
                          label={'From'}
                          onChange={(date) =>
                            handleDateChange(
                              'ingested_dates',
                              'start_date',
                              date
                            )
                          }
                        />
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          disableFuture
                          minDate={
                            ingestion_start_date
                              ? ingestionStartDate
                              : undefined
                          }
                          disabled={
                            loading ||
                            !ingestion_start_date ||
                            !ingestion_start_date.length > 0
                          }
                          value={ingestion_end_date ? ingestionEndDate : null}
                          format="yyyy-MM-dd"
                          margin="normal"
                          label={'To'}
                          onChange={(date) =>
                            handleDateChange('ingested_dates', 'end_date', date)
                          }
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                </FormControl>
                <Stack
                  style={{
                    display: 'flex',
                    marginLeft: '238px',
                    marginTop: '10px',
                  }}
                  direction="row"
                  spacing={1}
                >
                  <Button
                    startIcon={<TuneIcon color="#366CD9" />}
                    disabled={loading}
                    onClick={() => {
                      setIngestionFilters(initialState)
                      getFilteredData(initialState)
                    }}
                    style={{
                      marginTop: '50px',
                      marginRight: '20px',
                      marginLeft: '-20px',
                      color: '#366CD9',
                      borderColor: '#366CD9',
                      height: '36px',
                    }}
                    variant="outlined"
                  >
                    Clear
                  </Button>
                  <Badge
                    classes={{
                      root: classes.badgeRoot,
                      badge: classes.badgeDetails,
                    }}
                    badgeContent={getMoreFiltersCount()}
                    color="primary"
                  >
                    <Button
                      startIcon={<TuneIcon color="#366CD9" />}
                      disabled={loading}
                      onClick={() => {
                        setOpenDrawer(true)
                      }}
                      variant="outlined"
                      style={{
                        height: '36px',
                        color: '#366CD9',
                        borderColor: '#366CD9',
                      }}
                    >
                      More
                    </Button>
                  </Badge>
                </Stack>
              </div>
              <Divider
                style={{ width: 'calc(100% + 32px)', marginLeft: '-16px' }}
              />

              <div className={classes.tableToolbarAction}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={4} xs={12}>
                    {totalCount > 0 ? (
                      <Typography style={{ fontWeight: 500 }}>
                        Showing {rows.length} images of {totalCount} images
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>

                  {totalCount > 0 && (
                    <Grid item lg={8} xs={12}>
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[
                          100,
                          500,
                          { label: 'All', value: totalCount },
                        ]}
                        colSpan={3}
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={PaginationActions}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.tablePaper}>
              <React.Fragment>
                {enlargeImage.open && (
                  <ImageDialog
                    open={enlargeImage.open}
                    src={enlargeImage.src}
                    title={enlargeImage.title}
                    handleClose={() =>
                      setEnlargeImage({
                        open: false,
                        src: '',
                        title: '',
                      })
                    }
                  />
                )}
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {rows.length > 0 && (
                          <TableCell className={classes.checkbox}>
                            <Checkbox
                              checked={
                                selected.length > 0 &&
                                selected.length === rows.length
                              }
                              onChange={onSelectAllClick}
                              inputProps={{ 'aria-label': 'select all tcins' }}
                            />
                          </TableCell>
                        )}
                        {columnMap.map((columnMapInstance) => {
                          return renderTableData(columnMapInstance)
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!loading ? (
                        rows.length > 0 ? (
                          (
                            stableSort(rows, getComparator(order, orderBy)) ||
                            []
                          ).map((i) => (
                            <TableRow>
                              <TableCell
                                style={{
                                  left: 0,
                                  zIndex: 4,
                                  paddingTop: 8,
                                  position: 'sticky',
                                  backgroundColor: '#FFFFFF',
                                  verticalAlign: 'top',
                                  textAlign: 'left',
                                }}
                                className={classes.checkbox}
                              >
                                <Checkbox
                                  checked={
                                    selected.filter(
                                      (s) => s.photo_id === i.photo_id
                                    ).length > 0
                                  }
                                  onChange={() => onSelectRow(i.photo_id)}
                                  inputProps={{
                                    'aria-label': 'select all tcins',
                                  }}
                                />
                              </TableCell>
                              {columnMap.map((columnMapInstance) => {
                                const { custColType = '', dataField = '' } =
                                  columnMapInstance
                                switch (custColType) {
                                  case 'image':
                                    return renderImageColumn(
                                      columnMapInstance,
                                      i
                                    )
                                  case 'string':
                                    return renderTextColumn(
                                      columnMapInstance,
                                      i
                                    )
                                  case 'status':
                                    return renderStatusColumn(
                                      columnMapInstance,
                                      i
                                    )
                                  case 'alt_position':
                                    return renderAltPositionColumn(
                                      columnMapInstance,
                                      i
                                    )
                                  case 'date':
                                    return renderDateColumn(
                                      columnMapInstance,
                                      i
                                    )
                                  case 'chip':
                                    return renderTCINIcon(columnMapInstance, i)

                                  default:
                                    return <TableCell />
                                }
                              })}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={24} align="center">
                              No Images shot
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        [...Array(columnMap.length)].map((k, i) => (
                          <TableRow>
                            <TableCell style={cellStyle}>
                              <Loader />
                            </TableCell>
                            {[...Array(columnMap.length)].map((cell) => {
                              return (
                                <TableCell style={cellStyle}>
                                  <Loader />
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Drawer
        classes={{ paper: classes.actionsDrawer }}
        anchor="bottom"
        open={selected.length > 0}
        variant="persistent"
      >
        <div>
          <span className={classes.footerButton}>
            {`${selected.length} selected`}
          </span>
          <ColorButton
            onClick={() => {
              const filesToDownload = selected.reduce((acc, item) => {
                const id = item.file_name ? item.file_name.split('.')[0] : ''
                acc[id] = {
                  fileName: item.file_name,
                  url: item.image_url,
                  status: 'Not Started',
                  timeStamp: Date.now(),
                }
                return acc
              }, {})
              dispatch(setFilesForDownloads(filesToDownload))
            }}
            startIcon={<GetAppIcon />}
          >
            Download TIF
          </ColorButton>
          <ColorButton onClick={handleJpegDownload} startIcon={<GetAppIcon />}>
            Download JPEG
          </ColorButton>
          <ColorButton
            startIcon={<GetAppIcon />}
            onClick={onClickDownload}
            data-item="all"
          >
            Data
          </ColorButton>
          {csvData.data ? (
            <CSVLink
              ref={csvBtn}
              asyncOnClick
              filename={'ImageUploads.csv'}
              data={csvData.data}
              headers={csvData.headers}
            />
          ) : undefined}
        </div>
      </Drawer>
      <Drawer
        classes={{
          paper:
            openDrawer === 'More Filters'
              ? `${classes.filtersDrawer} ${classes.ingestionDrawer}`
              : classes.ingestionDrawer,
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            wrap={'nowrap'}
            style={{ marginBottom: '24px' }}
            container
            justify={'space-between'}
            alignItems={'center'}
          >
            <h4 className={classes.drawerHeader}>
              {' '}
              <TuneIcon color="#366CD9" />
              More Filters
            </h4>
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenDrawer(false)
              }}
              className={classes.closeIcon}
            >
              <CloseIcon style={{ fontSize: 24 }} />
            </IconButton>{' '}
          </Grid>
          <Divider className={classes.drawerHeaderDivider} />
          <div className={classes.filtersContent}>
            <div>
              <Autocomplete
                disabled={loading}
                limitTags={2}
                onChange={(e, newValue) =>
                  handleFilterChange('tbays', newValue)
                }
                multiple
                disableCloseOnSelect
                open={tbayFilterDropDown}
                onOpen={() => {
                  setTbayFilterDropDown(!tbayFilterDropDown)
                }}
                onClose={() => {
                  setTbayFilterDropDown(!tbayFilterDropDown)
                }}
                options={tbays}
                getOptionLabel={(option) => option}
                value={ingestionFilters.tbays}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon size="small" />}
                      checkedIcon={<CheckBoxIcon size="small" />}
                      style={{ marginRight: 8 }}
                      checked={
                        ingestionFilters &&
                        ingestionFilters.tbays?.indexOf(option) > -1
                      }
                      sx={{
                        '&.Mui-checked': {
                          color: '#CC0000',
                        },
                      }}
                    />
                    {option}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Tbays" />
                )}
              />
            </div>
            <div>
              <Autocomplete
                disabled={loading}
                limitTags={2}
                onChange={(e, newValue) =>
                  handleFilterChange('photographers', newValue)
                }
                multiple
                disableCloseOnSelect
                open={phtFilterDropDown}
                onOpen={() => {
                  setPhtFilterDropDown(!phtFilterDropDown)
                }}
                onClose={() => {
                  setPhtFilterDropDown(!phtFilterDropDown)
                }}
                options={photographers}
                getOptionLabel={(option) => option}
                value={ingestionFilters.photographers}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon size="small" />}
                      checkedIcon={<CheckBoxIcon size="small" />}
                      style={{ marginRight: 8 }}
                      checked={
                        ingestionFilters &&
                        ingestionFilters.photographers?.indexOf(option) > -1
                      }
                      sx={{
                        '&.Mui-checked': {
                          color: '#CC0000',
                        },
                      }}
                    />
                    {option}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Photographers"
                  />
                )}
              />
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: '13px',
              }}
            >
              Photo Shot Date
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div
                  container
                  direction="row"
                  justify="flex-start"
                  style={{
                    gap: 32,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <KeyboardDatePicker
                    disabled={loading}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    value={shot_start_date ? shotStartDate : null}
                    disableFuture
                    format="yyyy-MM-dd"
                    margin="normal"
                    label={'From'}
                    onChange={(date) =>
                      handleDateChange('shot_dates', 'start_date', date)
                    }
                  />

                  <KeyboardDatePicker
                    disableFuture
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    value={shot_end_date ? shotEndDate : null}
                    minDate={shot_start_date ? shotStartDate : undefined}
                    disabled={
                      loading || !shot_start_date || !shot_start_date.length > 0
                    }
                    format="yyyy-MM-dd"
                    margin="normal"
                    label={'To'}
                    onChange={(date) =>
                      handleDateChange('shot_dates', 'end_date', date)
                    }
                  />
                  <IconButton
                    className={classes.clearButton}
                    onClick={() => {
                      handleFilterChange('shot_dates', {
                        end_date: '',
                        start_date: '',
                      })
                    }}
                  >
                    <CancelIcon /> Clear
                  </IconButton>
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <Grid>
            <Stack direction="row" className={classes.buttonGroup} spacing={2}>
              <Button
                disabled={loading}
                className={classes.stausButton}
                variant="outlined"
                onClick={() => setOpenDrawer(false)}
              >
                Cancel
              </Button>
              <Button
                className={`${classes.stausButton} ${classes.primaryButton}`}
                variant="contained"
                disabled={
                  loading ||
                  phtFilterDropDown ||
                  tbayFilterDropDown ||
                  isDeepEmpty(ingestionFilters)
                }
                onClick={() => {
                  setIngestionFilters({
                    ...ingestionFilters,
                  })
                  getFilteredData({
                    ...ingestionFilters,
                  })
                }}
              >
                Apply
              </Button>
            </Stack>
          </Grid>
        </div>
      </Drawer>
      <UploadManger
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        isDropzoneActive={dropZoneActive}
        checkIsUploadEnable={canUploadFiles.length}
        successFiles={successFiles}
        failedFiles={failedFiles}
        successTable={
          <UploadManger.UploadSuccessTable successFiles={successFiles} />
        }
        failedTable={
          <UploadManger.UploadFailedTable failedFiles={failedFiles} />
        }
        uploadProgressTable={
          <UploadManger.UploadProgressTable
            uploadsInProgress={uploadsInProgress}
          />
        }
        uploadsInProgress={uploadsInProgress}
        droppedFilesIngestion={
          <UploadManger.DroppedFilesTableIngestion
            droppedFiles={droppedFiles}
            onExistingDelete={(row) => {
              const updatedFiles = cloneDeep(droppedFiles)
              delete updatedFiles[row]
              const canUpload = canUploadFiles.filter(
                (item) => item.name !== row
              )
              setCanUploadFiles(canUpload)
              setDroppedFiles(updatedFiles)
            }}
          />
        }
        onDiscard={() => {
          setDroppedFiles([])
          setCanUploadFiles([])
        }}
        onUpload={() => upload([...canUploadFiles])}
      />
    </div>
  )
}

const mapAuthToProps = (auth) => {
  return {
    company: auth.session?.userInfo?.company,
    accessToken: auth.session?.userInfo?.accessToken,
    memberOf: auth.session?.userInfo?.memberOf,
    lanId: auth.session?.userInfo?.lanId,
  }
}

export default withAuth(mapAuthToProps)(Ingestion)
