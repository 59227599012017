import { Table, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Grid from '@material-ui/core/Grid'
import { formatDate, toSafeData } from './mapper'
import { useDispatch } from 'react-redux'
import { openZoomedImage } from '../../store/TcinInfo/actionCreator'
import defaultThumbnail from '../../images/defaultThumbnail.jpg'
import { isArrayLike, get } from 'lodash'
import ImageNA from '../../images/ImageNA.svg'
import { Drawer, Button, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { purple } from '@material-ui/core/colors'
import GetAppIcon from '@material-ui/icons/GetApp'
import { imageDownloadHost } from '../../constants/common'
import { downloadPreviewImage } from '../../helpers/imageDownloadHelper'
import { unionBy, isEmpty } from 'lodash'
import { showNotification } from '../../store/Notification/actionCreator'
import { MultiTcinReusedFlyout } from '../../components/MultiTcinReusedFlyout'
import { setFilesForDownloads } from '../../store/Downloads/actionCreator'

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    '&:hover': {
      backgroundColor: purple[400],
    },
  },
}))(Button)

const useStyles = makeStyles((theme) => ({
  actionsDrawer: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: '#3f51b5',
    padding: '8px 16px 8px 16px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row !important',
  },
  header: {
    margin: 20,
    verticalAlign: 'top',
    textAlign: 'left',
  },
  tableBody: {
    verticalAlign: 'top',
    textAlign: 'left',
  },
  root: {
    width: '100%',
  },
  table: {
    width: '98%',
    borderTop: 'ridge',
    borderTopWidth: 'thin',
    borderBottom: 'ridge',
    borderBottomWidth: 'thin',
  },
  container: {
    height: 'calc(100vh - 220px)',
    maxHeight: 'fit-content',
  },
  noImage: {
    height: '72px',
    width: '72px',
  },
  noPhotoData: {
    align: 'center',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
    color: 'gray',
    fontSize: '25px',
  },
  gridRoot: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  closeIcon: {
    color: '#ffffff',
    padding: '0 !important',
    marginRight: '-9px !important',
  },
}))

export default function PhotoData(props) {
  const classes = useStyles()
  const { data } = props
  const dispatch = useDispatch()
  const [selection, setSelection] = useState([])
  const imageRender = (data, index, path, tcin, title, metadata) => {
    const previewImageUrl = toSafeData(data, path[0], '-')
    const zoomImageUrl = toSafeData(data, path[1], '-')
    const isPickupImage =
      (metadata?.photo_studio || '')?.toLowerCase() === 'pickup'
    return (
      <div
        style={{
          position: 'relative',
          height: '72px',
          width: '72px',
        }}
      >
        {previewImageUrl === '-' ? (
          <ImageNA title="Image Not Available" className={classes.noImage} />
        ) : (
          <img
            onError={(e) => {
              e.target.src = defaultThumbnail
            }}
            src={previewImageUrl}
            style={{ height: 72, cursor: 'pointer' }}
            onClick={() =>
              dispatch(
                openZoomedImage(
                  tcin +
                    ' - ' +
                    (title +
                      (index === -1 ? ' Main' : `Alt Image ${[index + 1]}`)),
                  zoomImageUrl !== '-' ? zoomImageUrl : previewImageUrl
                )
              )
            }
            alt="-"
          />
        )}
        {metadata?.multitcin || isPickupImage ? (
          <MultiTcinReusedFlyout
            styles={{
              position: 'absolute',
              top: '52px',
              right: '0',
              background: '#666666',
              color: '#ffffff',
              width: '72px',
              height: '20px',
              borderRadius: 0,
            }}
            isPickupImage={isPickupImage}
            tcin={`${tcin}.tif`}
            subjectCodes={get(metadata, 'subject_codes')}
            originalImage={get(metadata, 'original_image')}
          />
        ) : (
          <></>
        )}
      </div>
    )
  }

  const handleSelect = (isChecked, row) => {
    let newSelected = selection || []
    if (isChecked) {
      newSelected.push(row)
    } else {
      const selectedRow = newSelected.findIndex(
        (e) => e.file_name === row.file_name
      )
      newSelected.splice(selectedRow, 1)
    }
    setSelection([...newSelected])
  }

  useEffect(() => {
    setSelection([])
  }, [data])

  const errorFileNotification = (fileName) => {
    dispatch(
      showNotification(
        true,
        `Error while downloading ${fileName}`,
        'error',
        1000
      )
    )
  }

  const handleDownload = () => {
    const filesToDownload = selection.reduce((acc, item) => {
      const id = item.file_name ? item.file_name.split('.')[0] : ''
      acc[id] = {
        fileName: item.file_name,
        url: item?.metadata?.image_url || '',
        status: 'Not Started',
        timeStamp: Date.now(),
      }
      return acc
    }, {})
    dispatch(setFilesForDownloads(filesToDownload))
  }

  const handleDownloadJpeg = () => {
    for (let index = 0; index < selection.length; index++) {
      if (selection[index].metadata) {
        const { pathname = '' } = new URL(
          selection[index].metadata.zoom_image_url
        )
        downloadPreviewImage(`${imageDownloadHost}${pathname}`)
      }
    }
  }

  const selectAll = (event) => {
    let newSelected = []
    if (event.target.checked) {
      const filteredMainData =
        data?.image_information?.main?.retouch?.retouch_status ===
        'Accepted by Pipeline'
          ? data.image_information.main
          : {}
      const filteredAltData = data.image_information.alt?.filter(
        (item) => item?.retouch?.retouch_status === 'Accepted by Pipeline'
      )
      if (
        !isEmpty(filteredMainData) ||
        (filteredAltData && filteredAltData.length)
      ) {
        newSelected = unionBy(
          [...selection, filteredMainData, ...filteredAltData],
          'file_name'
        )
      }
      setSelection(newSelected)
    } else {
      setSelection([])
    }
  }

  const countOfSourceImages = () => {
    return (
      (toSafeData(data, 'image_information.main.file_name', null) === null
        ? 0
        : 1) + toSafeData(data, 'image_information.alt_images_count', null)
    )
  }

  const countOfRows = () => {
    return (
      (toSafeData(data, 'image_information.main', null) === null ? 0 : 1) +
      (toSafeData(data, 'image_information.alt', null)
        ? data.image_information.alt.length
        : 0)
    )
  }

  const isMainChecked =
    selection.findIndex(
      (e) =>
        e['file_name'] ===
        toSafeData(data, 'image_information.main.file_name', '')
    ) >= 0
  const isMainDisabled = !(
    data?.image_information?.main?.retouch?.retouch_status ===
    'Accepted by Pipeline'
  )

  return (
    <React.Fragment>
      <Grid
        wrap="nowrap"
        direction="row"
        container
        justify="flex-start"
        alignItems="center"
        style={{ margin: '16px' }}
        spacing={2}
      >
        <Grid item xs sm={2}>
          <Typography variant="subtitle2">Final Main</Typography>
        </Grid>
        <Grid item xs sm={10}>
          <Typography variant="subtitle2">Final Alt</Typography>
        </Grid>
      </Grid>
      <Grid
        wrap="nowrap"
        direction="row"
        container
        justify="flex-start"
        alignItems="center"
        style={{ margin: '16px' }}
        spacing={2}
      >
        <Grid item xs sm={2}>
          {imageRender(
            data,
            -1,
            ['item_info.images.main'],
            data.tcin,
            'Final '
          )}
        </Grid>
        <Grid item xs sm={10}>
          {toSafeData(data, 'item_info.images.alt', null) ? (
            isArrayLike(data.item_info.images.alt) ? (
              data.item_info.images.alt?.length > 0 ? (
                <Grid container direction="row" justify="flex-start">
                  {data.item_info.images.alt?.map((row, index) =>
                    row ? (
                      <Grid item>
                        <img
                          onClick={() =>
                            dispatch(
                              openZoomedImage(data.tcin + ' - Final Alt', row)
                            )
                          }
                          style={{
                            height: 60,
                            cursor: 'pointer',
                            margin: '0 5px',
                          }}
                          src={row}
                          alt="-"
                        />
                      </Grid>
                    ) : (
                      <Grid item>
                        <ImageNA
                          title="Image Not Available"
                          className={classes.noImage}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              ) : (
                <Grid item>
                  <ImageNA
                    title="Image Not Available"
                    className={classes.noImage}
                  />
                </Grid>
              )
            ) : (
              <Grid item>
                <ImageNA
                  title="Image Not Available"
                  className={classes.noImage}
                />
              </Grid>
            )
          ) : (
            <Grid item>
              <ImageNA
                title="Image Not Available"
                className={classes.noImage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Typography
        style={{ paddingLeft: 16, paddingTop: 16, paddingBottom: 16 }}
        component="div"
      >
        {countOfSourceImages()} source images
      </Typography>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="simple table"
          align="center"
        >
          <TableHead className={classes.header}>
            <TableRow className={classes.header}>
              <TableCell className={classes.header}>
                <Checkbox
                  onChange={selectAll}
                  checked={
                    selection.length === countOfRows() && countOfRows() > 0
                  }
                  indeterminate={
                    selection.length > 0 && selection.length !== countOfRows()
                  }
                />
              </TableCell>
              <TableCell className={classes.header}>
                Source Main / Alt
              </TableCell>
              <TableCell className={classes.header} align="left">
                TBay
              </TableCell>
              <TableCell className={classes.header} align="left">
                Photographer
              </TableCell>
              <TableCell className={classes.header} align="left">
                Producer
              </TableCell>
              <TableCell className={classes.header} align="left">
                Photo Studio
              </TableCell>
              <TableCell className={classes.header} align="left">
                Photo Shot
              </TableCell>
              <TableCell className={classes.header} align="left">
                Sent To Photobridge
              </TableCell>
              <TableCell className={classes.header} align="left">
                Image Status
              </TableCell>
              <TableCell className={classes.header} align="left">
                Sent To Pipeline
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.header}>
            {toSafeData(data, 'image_information.main', null) ? (
              <TableRow>
                <TableCell className={classes.tableBody}>
                  <Checkbox
                    onChange={(event) => {
                      handleSelect(
                        !isMainChecked,
                        toSafeData(data, 'image_information.main', {})
                      )
                    }}
                    checked={isMainChecked}
                    disabled={isMainDisabled}
                  />
                </TableCell>
                <TableCell className={classes.tableBody}>
                  {imageRender(
                    data,
                    -1,
                    [
                      'image_information.main.metadata.preview_image_url',
                      'image_information.main.metadata.zoom_image_url',
                    ],
                    data.tcin,
                    'Source Image -',
                    data?.image_information?.main?.metadata
                  )}
                </TableCell>
                <TableCell className={classes.tableBody}>
                  {toSafeData(
                    data,
                    'image_information.main.metadata.tbay',
                    '-'
                  )}
                </TableCell>
                <TableCell className={classes.tableBody}>
                  {toSafeData(
                    data,
                    'image_information.main.metadata.photographer',
                    '-'
                  )}
                </TableCell>
                <TableCell className={classes.tableBody}>
                  {toSafeData(
                    data,
                    'image_information.main.metadata.producer',
                    '-'
                  )}
                </TableCell>
                <TableCell className={classes.tableBody}>
                  {toSafeData(
                    data,
                    'image_information.main.metadata.photo_studio',
                    '-'
                  )}
                </TableCell>
                <TableCell className={classes.tableBody}>
                  {formatDate(
                    toSafeData(
                      data,
                      'image_information.main.metadata.image_created_dt',
                      '-'
                    )
                  )}
                </TableCell>
                <TableCell className={classes.tableBody}>
                  {formatDate(
                    toSafeData(
                      data,
                      'image_information.main.metadata.date_ingested',
                      '-'
                    )
                  )}
                </TableCell>
                <TableCell className={classes.tableBody} align="left">
                  {toSafeData(
                    data,
                    'image_information.main.retouch.retouch_status',
                    '-'
                  )}
                </TableCell>
                <TableCell className={classes.tableBody}>
                  {formatDate(
                    toSafeData(
                      data,
                      'image_information.main.retouch.sent_to_pipeline',
                      '-'
                    )
                  )}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {toSafeData(data, 'image_information.alt', []).length > 0 ? (
              data?.image_information?.alt?.map((row, index) => {
                const isChecked =
                  selection.findIndex(
                    (e) => e['file_name'] === row['file_name']
                  ) >= 0
                const isDisabled = !(
                  row?.retouch?.retouch_status === 'Accepted by Pipeline'
                )
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.tableBody}>
                      <Checkbox
                        onChange={(event) => {
                          handleSelect(!isChecked, row)
                        }}
                        checked={isChecked}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableBody}
                      component="th"
                      scope="row"
                    >
                      {imageRender(
                        row,
                        index,
                        [
                          'metadata.preview_image_url',
                          'metadata.zoom_image_url',
                        ],
                        row.file_name.split('.')[0],
                        'Source - ',
                        row.metadata
                      )}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {toSafeData(row, 'metadata.tbay', '-')}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {toSafeData(row, 'metadata.photographer', '-')}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {toSafeData(row, 'metadata.producer', '-')}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {toSafeData(row, 'metadata.photo_studio', '-')}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {formatDate(
                        toSafeData(row, 'metadata.image_created_dt', '-')
                      )}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {formatDate(
                        toSafeData(row, 'metadata.date_ingested', '-')
                      )}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {toSafeData(row, 'retouch.retouch_status', '-')}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {formatDate(
                        toSafeData(row, 'retouch.sent_to_pipeline', '-')
                      )}
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer
        classes={{ paper: classes.actionsDrawer }}
        anchor="bottom"
        open={selection.length > 0}
        variant="persistent"
      >
        <div>
          <ColorButton onClick={handleDownload} startIcon={<GetAppIcon />}>
            Download TIF
          </ColorButton>
          <ColorButton onClick={handleDownloadJpeg} startIcon={<GetAppIcon />}>
            Download JPEG
          </ColorButton>
        </div>
        <IconButton
          aria-label="clear"
          onClick={() => {
            setSelection([])
          }}
        >
          <CancelRoundedIcon className={classes.closeIcon} />
        </IconButton>
      </Drawer>
    </React.Fragment>
  )
}
