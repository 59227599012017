import { get, join, map, flatMap, compact, isNil, toString } from 'lodash'
import moment from 'moment-timezone'

export const formatDate = (dateString, format = 'MMM D, YYYY') => {
  return dateString ? moment.utc(dateString).format(format) : '-'
}

const formatCsvDate = (dateTimeStamp) => {
  return new Date(dateTimeStamp).getTime() > 0
    ? new Date(dateTimeStamp).toLocaleDateString('en-US')
    : '-'
}

export const toSafeData = (data, path, defaultValue) => {
  let a = get(data, path, defaultValue)
  return isNil(a) ? defaultValue : a
}

export const prefixSampleId = (value) => {
  return new RegExp(/^[0-9]*$/).test(value)
    ? toString(value).length === 7
      ? 'WH' + value
      : 'PG' + value
    : value
}

export const formatCSVData = (list, alt_count, format = '', tableName = '') => {
  return list.map((itemData) => {
    let item = tableName === 'tcinInfo' ? itemData.info : itemData
    let result = {
      tcin: toSafeData(item, 'tcin', '-'),
      added_date: formatCsvDate(toSafeData(item, 'added_date', '-')),
      dpci: toSafeData(item, 'dpci', '-'),
      item_description: toSafeData(item, 'item_info.product_description', '-'),
      brands: toSafeData(item, 'item_info.brands', '-'),
      launch_date_time: formatCsvDate(
        toSafeData(item, 'item_info.launch_date_time', '-')
      ),
      parent_tcins: toSafeData(item, 'item_info.parent_tcins', '-'),
      item_state: toSafeData(item, 'item_info.item_state', '-'),
      relationship_type: toSafeData(item, 'item_info.relationship', '-'),
      class_data:
        toSafeData(item, 'item_info.class_id', '') +
        ' - ' +
        toSafeData(item, 'item_info.class_name', ''),
      department_data:
        toSafeData(item, 'item_info.department_id', '') +
        ' - ' +
        toSafeData(item, 'item_info.department_name', ''),
      barcode: toSafeData(item, 'item_info.barcode_number', '-'),
      vendor_id: toSafeData(item, 'item_info.vendor_id', '-'),
      manufacturer_style: toSafeData(item, 'item_info.manufacturer_style', '-'),
      size: toSafeData(item, 'item_info.size', '-'),
      color: toSafeData(item, 'item_info.color', '-'),
      sample_ids: formatData(
        item.sample_info.map((sample) =>
          prefixSampleId(toSafeData(sample, 'pegasus_id', '-'))
        ),
        'csv'
      ),
      sample_status: formatData(
        item.sample_info.map((sample) =>
          toSafeData(sample, 'sample_status', '-')
        ),
        'csv'
      ),
      sample_ship_location: formatData(
        item.sample_info.map((sample) =>
          toSafeData(sample, 'ship_to_loc', '-')
        ),
        'csv'
      ),
      sample_received_date: formatData(
        item.sample_info.map((sample) =>
          formatCsvDate(toSafeData(sample, 'received_dt', '-'))
        ),
        'csv'
      ),
      sample_projects: formatData(
        item.sample_info.map((sample) => toSafeData(sample, 'projects', '-')),
        'csv'
      ),
      sample_current_location: formatData(
        item.sample_info.map((sample) => toSafeData(sample, 'location', '-')),
        'csv'
      ),
      no_of_shots: toSafeData(item, 'image_information.alt_images_count', '-'),
      file_name: toSafeData(item, 'image_information.main.file_name', '-'),
      photo_studio: toSafeData(
        item,
        'image_information.main.metadata.photo_studio',
        '-'
      ),
      photo_shot: formatCsvDate(
        toSafeData(
          item,
          'image_information.main.metadata.image_created_dt',
          '-'
        )
      ),
      photo_tbay: toSafeData(item, 'image_information.main.metadata.tbay', '-'),
      photo_source: formatCsvDate(
        toSafeData(item, 'image_information.main.metadata.date_ingested', '-')
      ),
      photographer: toSafeData(
        item,
        'image_information.main.metadata.photographer',
        '-'
      ),
      photo_retouch_status: toSafeData(
        item,
        'image_information.main.retouch.retouch_status',
        '-'
      ),
      photo_pipeline: formatCsvDate(
        toSafeData(item, 'image_information.main.retouch.sent_to_pipeline', '-')
      ),
    }
    for (var i = 0; i < alt_count; i++) {
      result = {
        ...result,
        [`file_name_${i + 1}`]: toSafeData(
          item.image_information,
          `alt[${i}].file_name`,
          '-'
        ),
        [`photo_studio_${i + 1}`]: toSafeData(
          item.image_information,
          `alt[${i}].metadata.photo_studio`,
          '-'
        ),
        [`photo_shot_${i + 1}`]: formatCsvDate(
          toSafeData(
            item.image_information,
            `alt[${i}].metadata.image_created_dt`,
            '-'
          )
        ),
        [`photo_tbay_${i + 1}`]: toSafeData(
          item.image_information,
          `alt[${i}].metadata.tbay`,
          '-'
        ),
        [`photo_source_${i + 1}`]: formatCsvDate(
          toSafeData(
            item.image_information,
            `alt[${i}].metadata.date_ingested`,
            '-'
          )
        ),
        [`photographer_${i + 1}`]: toSafeData(
          item.image_information,
          `alt[${i}].metadata.photographer`,
          '-'
        ),
        [`photo_retouch_status_${i + 1}`]: toSafeData(
          item.image_information,
          `alt[${i}].retouch.retouch_status`,
          '-'
        ),
        [`photo_pipeline_${i + 1}`]: formatCsvDate(
          toSafeData(
            item.image_information,
            `alt[${i}].retouch.sent_to_pipeline`,
            '-'
          )
        ),
      }
    }
    return result
  })
}

const formatData = (item, format) => {
  return format === 'csv' ? join(item, ', ') : item
}
